<template>
  <div id="con03"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  // name: "",
  data() {
    return {};
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    dateData: {
      type: Array,
    },
  },
  watch: {
    value: {
      handler() {
        this.tu();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("con03");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        backgroundColor: "#1c2431", //地图背景色
        color: ["#fcc550", "#4fd7fd"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["平均功率(W)"],
          textStyle: {
            fontSize: "12",
            color: "#fff",
          },
          top: 10,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateData,
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        // 因结构不同 vue版echarts请查看https://blog.csdn.net/qq_42221334/article/details/96315748
        series: [
          {
            name: "平均功率(W)",
            smooth: true,
            data: this.value,
            type: "line",
            areaStyle: {},
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#con03 {
  width: 80rem;
  height: 370px;
}
</style>