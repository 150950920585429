<template>
  <div>
    <!-- <input
        type="range"
        id="size-slider"
        min="50"
        max="200"
        value="100"
        @input="onInput"
      /> -->
      
    <div style="display: flex; height: 4vh;">
      <div>
        <el-upload
              class="upload-demo"
              ref="upload"
              accept=".xls,.xlsx"
              :action="UploadUrl()"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :auto-upload="false"
              :file-list="fileList"
            >
              <el-button
                slot="trigger"
                type="primary"
                style="margin-left: 2vw"
                >选择文件</el-button
              >
            </el-upload>
      </div>
      <div style="margin-left: 2vw;">
        <el-button
              class="filter-item"
              type="primary"
              icon="el-icon-download"
              @click="downloadExcel"

              >下载模板</el-button>
              
        <el-button @click="handleUploadFile" style="margin-right:2vw; margin-left: 2vw;"
        
          >上传</el-button
        >
        <el-button @click="cancel">取消</el-button>
        <el-button @click="onSure" style="margin-left: 20px" v-if="onShow"
          >确定上传</el-button
        >
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <!-- <p style="margin-left: 10px; padding-top: 10px; color: #fff">设备层级:</p> -->

      <div class="slider">
        <!-- 显示选择的数值容器 -->
        <!-- <span class="selected"> 50 </span> -->
        <!-- 滑动选择器 -->
        <!-- <input
          type="range"
          id="slider-input"
          min="0"
          max="100"
          value="50"
          @input="onInput"
        /> -->
      </div>
    </div>
    <Table
      :row-class-name="rowClassName"
      :columns="columns1"
      :data="data1"
      id="table"
    ></Table>

    <div class="content">
      <div class="content-title">
        <!-- <span>批量发卡</span> -->
      </div>
    </div>
    <!-- <el-upload
      class="upload-demo"
      list-type="text"
      accept=".xls,.xlsx"
      :action="UploadUrl()"
      :limit="1"
      :on-exceed="handleExceed"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :auto-upload="false"
      :file-list="fileList"
    >
      <el-button type="success" icon="el-icon-upload2">选择文件</el-button>
      <div class="el-upload__tip" slot="tip">只能上传xlsx、xls文件</div>
    </el-upload> -->
    <!-- <p>Custom column styles:</p>
      <Table :columns="columns2" :data="data1"></Table>
      <p>Custom arbitrary cell styles:</p>
      <Table :columns="columns1" :data="data2"></Table> -->
  </div>
</template>
  <script>
import axios from "axios";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      fileList: [],
      onShow: false,
      columns1: [
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Age",
          key: "age",
        },
        {
          title: "Address",
          key: "address",
        },
      ],
      columns2: [
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Age",
          key: "age",
          className: "demo-table-info-column",
        },
        {
          title: "Address",
          key: "address",
        },
      ],
      data1: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
          date: "2016-10-03",
        },
        {
          name: "Jim Green",
          age: 24,
          address: "London No. 1 Lake Park",
          date: "2016-10-01",
        },
        {
          name: "Joe Black",
          age: 30,
          address: "Sydney No. 1 Lake Park",
          date: "2016-10-02",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
      ],
      data2: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jim Green",
          age: 25,
          address: "London No. 1 Lake Park",
          cellClassName: {
            age: "demo-table-info-cell-age",
            address: "demo-table-info-cell-address",
          },
        },
        {
          name: "Joe Black",
          age: 30,
          address: "Sydney No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          cellClassName: {
            name: "demo-table-info-cell-name",
          },
        },
      ],
    };
  },
  methods: {
    async onSure() {
      console.log("this.fileList", this.fileList);
      // 如果上传的文件列表是空的，给出提示
      if (this.fileList.length === 0) {
        return this.$message.warning("请先选择要上传的文件");
      }
      let file = this.fileList[0];
      console.log(file, "file");
      // 获取到文件的后缀以便于判断文件类型
      if (file.name.indexOf("xlsx") == -1 || file.name.indexOf("xls") == -1) {
        this.$message({
          message: "请选择xlsx、xls格式文件",
          type: "warning",
        });
        return;
      }
      // 把文件转成FormData格式上传
      let dataFile = new FormData();
      dataFile.append("file", this.fileList[0]);
      try {
        // 使用 axios 发送文件上传请求
        const res = await axios.post(
          "http://mqtt-1.hualaizhike.com:5000//upload-excel",
          dataFile
        );
        console.log(res);

        // 处理上传成功的逻辑
        this.$message.success("文件上传成功");

        // 清空文件列表
        this.fileList = [];
      } catch (error) {
        // 处理上传失败的逻辑
        console.error("文件上传失败", error);
        this.$message.error("文件上传失败");
      }
    },
    handleUploadFile() {
      this.onShow = true;
      let file = this.fileList[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          // 获取第一个工作表的数据
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          // 将数据转换成 JSON 格式
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          // 在这里处理 jsonData，可以输出到控制台或显示在页面上
          console.log(jsonData);
        };
        reader.readAsArrayBuffer(file);
      }
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    handleChange(file, fileList) {
      this.fileList.push(file.raw);
    },
    // 文件超出个数限制时的钩子
    handleExceed(file, fileList) {
      return this.$message.warning("一次只能上传一个文件");
    },
    //因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
    UploadUrl() {
      return "";
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    cancel() {
      this.onShow = false;
      this.$refs.upload.clearFiles(); //清除上传文件对象
      this.fileList = []; //清空选择的文件列表
      this.$emit("cancel");
    },
    rowClassName(row, index) {
      if (index === 1) {
        return "demo-table-info-row";
      } else if (index === 3) {
        return "demo-table-error-row";
      }
      return "";
    },
    onInput() {
      const sliderEl = document.querySelector("#slider-input");
      // 获取数值显示容器实例
      const selectedEl = document.querySelector(".selected");
      // 监听滑动事件
      selectedEl.innerHTML = sliderEl.value;
      // const sizeSlider = document.getElementById("size-slider");
      const resizableBox = document.querySelector("tr");
      const resizableBox2 = document.querySelectorAll("td");
      // console.log( typeof resizableBox);
      const newSize = sliderEl.value / 5 + 6 + "px";
      // console.log(newSize);
      resizableBox2.forEach((x) => (x.style.fontSize = newSize));
      //   console.log(newSize);
      resizableBox.style.fontSize = newSize;
      //   resizableBox2.style.fontSize = newSize;
    },
    async downloadExcel() {
      let a = document.createElement("a");
      a.href = "http://mqtt-1.hualaizhike.com:5000/download-excel";
      // a.download = "批量发卡模板.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      // await axios('http://mqtt-1.hualaizhike.com:5000/download-excel')
    },
  },
  // 取消

  mounted() {
    const resizableBox = document.querySelector("tr");
    const resizableBox2 = document.querySelectorAll("td");
    resizableBox2.forEach((x) => (x.style.fontSize = "16px"));
    //   console.log(newSize);
    resizableBox.style.fontSize = "16px";
  },
};
</script>
  <style scoped lang="less">
@import "iview/dist/styles/iview.css";

.ivu-table .demo-table-info-row td {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-error-row td {
  /* background-color: #ff6600; */
  color: #fff;
}
.ivu-table td.demo-table-info-column {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-name {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-age {
  /* background-color: #ff6600; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-address {
  /* background-color: #187; */
  color: #fff;
}
/* #size-slider {
    width: 200px;
    margin-bottom: 20px;
    -webkit-appearance: none;
   
  
  
  } */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, "PingFang SC", "Microsoft Yahei", sans-serif;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1e272e;
}

/* 选择器容器 */
.slider {
  width: 200px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-right: 50px;
  /* margin-left: 50px; */
}

input[type="range"] {
  /* 禁用浏览器默认外观 */
  -webkit-appearance: none;
  background: linear-gradient(75deg, #24496f 0%, #0797eb 100%);
  border-radius: 4px;
  width: 100%;
  height: 12px;
  outline: none;
  box-shadow: 0 0 6px rgb(28, 32, 148);
}

/* 滑动选择器上的滑动按钮 */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333f4d;
  transition: 0.3s;
}

/* 当滑动选择器上的滑动按钮滑动时 */
input[type="range"]:active::-webkit-slider-thumb {
  background-color: #333f4d;
  /* box-shadow: 0 0 0 6px rgba(155, 73, 146, 0.4); */
}

/* 显示数值的容器 */
.selected {
  margin-right: 16px;
  background-color: #333f4d;
  width: 80px;
  line-height: 40px;
  text-align: center;
  color: white;
  border-radius: 4px;
  position: relative;
}
.selected::after {
  content: "";
  display: block;
  border-top: 8px solid transparent;
  border-left: 8px solid #333f4d;
  border-bottom: 8px solid transparent;
  position: absolute;
  top: calc(50% - 8px);
  right: -6px;
}
#table {
  border: 2px solid rgb(231, 240, 243);
  box-shadow: 5px 5px 0 6px rgba(243, 236, 242, 0.4);
  border-radius: 10px;
  transform: scale(0.9);
}
// #table:hover {
//   box-shadow: 25px 25px 0 6px rgba(243, 236, 242, 0.4);
//   transform: scale(1);
// }

.shebei {
  font-size: 1.5em;
  /* 设置文字大小 */
  background: linear-gradient(90deg, #c2bbba, #f3f3f1);
  /* 添加渐变背景 */
  -webkit-background-clip: text;
  /* 使文字只显示在背景区域内 */
  -webkit-text-fill-color: transparent;
  /* 隐藏文字自身的颜色 */
  animation: neonGlow 10s infinite alternate;
  /* 添加动画效果 */
}

/* 创建一个文本的闪烁效果 */
@keyframes neonGlow {
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fcf7f6,
      0 0 70px #fff, 0 0 80px #fff, 0 0 100px #d7f3e4, 0 0 150px #e9f0e9;
    font-size: 2em;
  }
}
.content-foot {
  // margin-top: 6vh;
  display: flex;
  flex-direction: row-reverse;
}
.content-body {
  height: 16vh;
}
</style>