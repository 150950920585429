<template>
    <div>
      <div class="headerleft">
        <el-tabs type="border-card">
          <!-- <el-tab-pane label="按站点">用户管理</el-tab-pane> -->
          <!-- <el-tab-pane label="按逆变器"> -->
            <div>
              <!-- <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
              >
                <el-form-item>
                  <el-input
                    v-model="formInline.user"
                    placeholder="搜索"
                    icon="el-icon-search"
                  ></el-input>
                </el-form-item>
  
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
              </el-form> -->
            </div>
            <div>
              <el-tree
                :data="data"
                show-checkbox
                node-key="id"
                :default-expanded-keys="[2, 3]"
                :default-checked-keys="[5, 10]"
                :props="defaultProps"
              >
              </el-tree>
            </div>
          
        </el-tabs>
      </div>
  
      <div class="headerright">
        <div>
          <el-tabs
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
            id="tab02"
          >
            <el-tab-pane label="日" name="first">
              <div id="day">
                <CenterTable></CenterTable>
              </div>
            </el-tab-pane>
            <el-tab-pane label="月" name="second">
              <div class="mouth">
                <MiddleTable></MiddleTable>
              </div>
            </el-tab-pane>
            <el-tab-pane label="年" name="third">
              <div class="year">
                <RightTable></RightTable>
              </div>
            </el-tab-pane>
  
            <el-form
              :inline="true"
              style="position: fixed; top: -1px; left: 600px; height: 20px"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-form-item label="日期:" required>
                <el-form-item prop="date1">
                  <el-date-picker
                    type="date"
                    size="mini"
                    class="xzrq"
                    placeholder="选择日期"
                    v-model="formInline.date1"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="info"
                  @click="onSubmit"
                  icon="el-icon-search"
                  size="mini"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  type="info"
                  @click="onSubmit"
                  icon="el-icon-upload2"
                  size="mini"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </el-tabs>
          <!-- ++++++++++++++++++++++++++++++++++分割线++++++++++++++++++++++++ -->
  
          <!-- ++++++++++++++++++++++++++++++++++分割线++++++++++++++++++++++++++ -->
        </div>
        <div class="bottom">
          <!-- <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="date" label="日期" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table> -->
  
          <div>
            <table>
              <thead>
                <tr>
                  <th>Column 1</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
                  <th>Column 2</th>
  
                  <th>Column 15</th>
                </tr>
              </thead>
              <tbody>
                <!-- Rows -->
                <!-- Row 1 -->
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1sdfas</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <!-- ... Add data for all 15 columns ... -->
                  <td>Row 1, Col 15</td>
                </tr>
                <tr>
                  <!-- <td>Row 1, Col 1fs</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                  <td>Row 1, Col 1</td>
                  <td>Row 1, Col 2</td>
                 
                  <td>Row 1, Col 15</td>
                  -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import CenterTable from "./components/dui.vue";
  import MiddleTable from "./components/dui01.vue";
  import RightTable from "./components/dui02.vue";
  
  // import BottomTable from "../views/bottomTable.vue";
  export default {
    components: {
      CenterTable,
      MiddleTable,
      RightTable,
      // BottomTable,
    },
    data() {
      return {
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        tableData: [
          {
            date: "2016-05-02",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1518 弄",
          },
          {
            date: "2016-05-04",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1517 弄",
          },
          {
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            date: "2016-05-03",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1516 弄",
          },
        ],
  
        formInline: {
          user: "",
          region: "",
        },
  
        data: [
          {
            id: 1,
            label: "安徽",
            children: [
              {
                id: 4,
                label: "工程测试",
                children: [
                  {
                    id: 9,
                    label: "三级 1-1-1",
                  },
                  {
                    id: 10,
                    label: "三级 1-1-2",
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: "工程测试",
            children: [
              {
                id: 5,
                label: "二级 2-1",
              },
              {
                id: 6,
                label: "二级 2-2",
              },
            ],
          },
          {
            id: 3,
            label: "光伏示例站点",
            children: [
              {
                id: 7,
                label: "英特尔300kWp车棚光伏",
                children: [
                  {
                    id: 10,
                    label: "1#井网柜",
                    children: [
                      {
                        id: 2,
                        label: "1#NBQ",
                      },
                      {
                        id: 2,
                        label: "2#NBQ",
                      },
                      {
                        id: 2,
                        label: "3#NBQ",
                      },
                      {
                        id: 2,
                        label: "4#NBQ",
                      },
                    ],
                  },
                  {
                    id: 5,
                    label: "2#井网柜",
                    children: [
                      {
                        id: 2,
                        label: "5#NBQ",
                      },
                      {
                        id: 2,
                        label: "6#NBQ",
                      },
                      {
                        id: 2,
                        label: "7#NBQ",
                      },
                      {
                        id: 2,
                        label: "8#NBQ",
                      },
                    ],
                  },
                ],
              },
              {
                id: 8,
                label: "二级 3-2",
              },
            ],
          },
          {
            id: 3,
            label: "河南",
            children: [
              {
                id: 7,
                label: "二级 3-1",
              },
              {
                id: 8,
                label: "二级 3-2",
              },
            ],
          },
          {
            id: 3,
            label: "微网示例站点",
            children: [
              {
                id: 7,
                label: "二级 3-1",
              },
              {
                id: 8,
                label: "二级 3-2",
              },
            ],
          },
          {
            id: 3,
            label: "研发测试",
            children: [
              {
                id: 7,
                label: "二级 3-1",
              },
              {
                id: 8,
                label: "二级 3-2",
              },
            ],
          },
          {
            id: 3,
            label: "综合示例站点",
            children: [
              {
                id: 7,
                label: "二级 3-1",
              },
              {
                id: 8,
                label: "二级 3-2",
              },
            ],
          },
        ],
        defaultProps: {
          children: "children",
          label: "label",
        },
      };
    },
    methods: {
      // formatter(row, column) {
      //   return row.address;
      // },
    },
  };
  </script>
  <style lang="scss" scoped>
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    color: #fff;
    border-radius: 10px;
  }
  .el-input__inner {
    background-color: #1b2232;
  }
  th,
  td {
    border: 1px solid #394553;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #283848;
  }
  
  tr {
    background-color: #222e3c;
  }
  
  tr:nth-child(even) {
    background-color: #222e3c;
  }
  
  // fenjiexian8888888888888888888888888888
  
  #day,
  .day,
  .mouth,
  .year {
    width: 100%;
    height: 450px;
    background-color: #fff;
  }
  .xzrq::v-deep input {
    background-color: #222e3c;
  }
  .demo-form-inline {
    position: absolute;
    top: 0px;
  }
  .bottom {
    width: 100%;
    height: 400px;
    background-color: #222e3c;
    position: absolute;
    bottom: 0;
  }
  .bottom el-table {
    // height: 200px;
  }
  .headerright > div #tab02 {
    width: 100%;
    height: 40px;
    background-color: #1b2232;
    position: fixed;
    color: #fff;
    border-color: #1b2232;
    top: 0;
    border: none;
  }
  .el-tabs__item {
    color: #fff;
  }
  #tab02::v-deep div div div div {
    color: #fff;
  }
  #tab02::v-deep div div div div div {
    color: #fff;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #3d4550;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }
  .xzrq {
    background-color: #222e3c;
  }
  .headerright {
    width: 80%;
    display: inline-block;
    height: 890px;
    background-color: #3d4550;
  }
  .el-tree {
    color: #fff;
    background-color: #222e3c;
  }
  .headerleft {
    width: 20%;
    display: inline-block;
    height: 890px;
    background-color: #222e3c;
  }
  
  .headerleft ::v-deep .el-tabs > div {
    background-color: #222e3c;
  }
  .el-tabs__content::v-deep {
    background-color: #222e3c;
  }
  #pane-0 {
    color: #fff;
  }
  .el-tabs__nav-scroll {
    background-color: #2d3947;
  }
  .el-tabs__content {
    color: #fff;
  }
  .headerleft ::v-deep .el-tabs__content {
    height: 890px;
    border-color: #222e3c;
  }
  // .headerleft ::v-deep .el-tabs__content::v-deep .el-tab-pane::v-deep div::v-deep form::v-deep .el-form-item::v-deep .el-form-item__content{
  
  // }
  
  .el-tabs {
    border: none;
  }
  .headerleft ::v-deep .el-tabs .el-input {
    background-color: #3c4856;
    border: none;
  }
  .el-input__inner {
    background-color: #3c4856;
    border: none;
  }
  </style>