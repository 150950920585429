<template>
  <div style="width: 89vw;height: 85vh;">
    <iframe src="https://dataroom.hualaizhike.com/dataroom/index.html" frameborder="0" style="width: 100%;height: 100%;"></iframe>

  </div>
</template>

<script>
export default {


}
</script>

<style>

</style>
