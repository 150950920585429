import axios from 'axios';

  export function  handleClick(vm,command) {
    if (command === 'cancel') {
        //清除token信息
        document.cookie = 'token01=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'username01=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        localStorage.removeItem('permissions');
        //跳转到登录页面
        vm.$router.push('/login')
        vm.deletMenu()
    }
    if (command === 'revise') {
      vm.dialogVisible = true
    }
    // if (command === 'chain') {
    //   vm.$i18n.locale = vm.$i18n.locale === 'en-US' ? 'zh-CN' : 'en-US';
    // }
    // if (command === 'english') {
    //   vm.$i18n.locale = vm.$i18n.locale === 'en-US' ? 'zh-CN' : 'en-US';
    // }
  }
  export function next(vm) {
    vm.$refs.passwordForm.validate((valid) => {
      if (valid) {
        vm.active += 1;
      }
    });
  }
  
  
  export function cancel(vm) {
    vm.dialogVisible = false;
    vm.active = 0;
    vm.passwordData.oldPassword = "";
    vm.passwordData.newPassword = "";
  }
  
  
  export function changePassword(vm) {
    vm.hhh = 1
    vm.$refs.passwordForm.validate((valid) => {
      if (valid) {
        axios.post('http://localhost:3001/api/changePassword', {
          username: vm.username,
          password: vm.passwordData.oldPassword,
          newPassword: vm.passwordData.newPassword,
        })
          .then((response) => {
            // 修改成功后，将 active 设置为 3，并显示成功消息
            vm.active = 3;
            vm.showDialog = true;
          })
          .catch((error) => {
            console.error("修改密码失败：", error.message);
          });
      }
    });
  }
  
  export function closeDialog(vm) {
    vm.dialogVisible = false;
    vm.active = 0;
    vm.passwordData.oldPassword = "";
    vm.passwordData.newPassword = "";
  }

  export function  handleLogout(vm) {
        //清除token信息
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'username01=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'permissions=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        //跳转到登录页面
        vm.$router.push('/login')
  }