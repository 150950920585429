<template>
  <div
    style="
      display: flex;
      width: 100vw;
      height: 100vh;
      background-color: #222e3c;
      overflow-y: hidden;
    "
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div style="padding-left: 5vw; margin-top: 2vh">
      <el-tree
        :data="data"
        node-key="id"
        default-expand-all
        @node-drag-start="handleDragStart"
        @node-drag-enter="handleDragEnter"
        @node-drag-leave="handleDragLeave"
        @node-drag-over="handleDragOver"
        @node-drag-end="handleDragEnd"
        @node-drop="handleDrop"
        draggable
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        style="color: gray; width: 20vw; height: 90vh; margin-top: 20px"
      >
      </el-tree>
    </div>
    <div
      style="width: 43vw; height: 100vh; margin-top: 30px; margin-left: 15vw"
    >
      <table-left></table-left>
    </div>
  </div>
</template>

<script>
import TableLeft from "./components/TableLeft.vue";
export default {
  components: {
    TableLeft,
  },
  data() {
    return {
      loading:true,
      data: [
        {
          id: 1,
          label: "一级设备 1",
          children: [
            {
              id: 4,
              label: "二级设备 1-1",
              children: [
                {
                  id: 9,
                  label: "三级设备 1-1-1",
                },
                {
                  id: 10,
                  label: "三级设备 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级设备 2",
          children: [
            {
              id: 5,
              label: "二级设备 2-1",
            },
            {
              id: 6,
              label: "二级设备 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级设备 3",
          children: [
            {
              id: 7,
              label: "二级设备 3-1",
            },
            {
              id: 8,
              label: "二级设备 3-2",
              children: [
                {
                  id: 11,
                  label: "三级设备 3-2-1",
                },
                {
                  id: 12,
                  label: "三级设备 3-2-2",
                },
                {
                  id: 13,
                  label: "三级设备 3-2-3",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    handleDragStart(node, ev) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log("tree drop: ", dropNode.label, dropType);
      // Access the dropped data
      // console.log("Dropped Node:", draggingNode.data);
      // console.log("Target Node:", dropNode.data);
      // You can also access the dropType (e.g., 'inner', 'prev', 'next')
      // console.log("Drop Type:", dropType);
      // // You may need to force a re-render of the component to reflect the updated data
      // this.$forceUpdate();
      console.log("Updated Tree Data:", this.data);
      this.data = this.data;
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === "二级设备 3-1") {
        return type !== "inner";
      } else {
        return true;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("三级设备 3-2-2") === -1;
    },
  },
  mounted() {
    setTimeout(()=>{
      this.loading = false
    },500)
    const trees = document.querySelectorAll(".el-tree-node__content");
    trees.forEach((x) => {
      var paddingLeft = parseFloat(
        window.getComputedStyle(x).getPropertyValue("padding-left")
      );
      if (paddingLeft === 0) {
        // x.style.color = 'red';
        x.children[1].style.color = "rgba(255, 255, 255,.6)";
      } else if (paddingLeft === 18) {
        x.children[1].style.color = "#22507a";
      } else if (paddingLeft === 36) {
        x.children[1].style.color = "gray";
      }
    });
  },
};
</script>

<style scoped>
::v-deep .el-tree-node__label {
  font-size: 26px;
  color: #fff;
}

::v-deep .el-tree {
  background-color: #222e3c !important;
}
::v-deep .el-tree-node__content {
  margin-top: 20px;
}
</style>