<template>
  <div id="main3"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {};
  },
  props:['yunxing'],
  watch: {
    yunxing: {
      handler() {
        this.tu();
      },
      immediate: true, // 立即执行一次以初始化图表
    },
  },
  methods: {
    tu() {
      let value = 100; //图上角度数据
      var chartDom = document.getElementById("main3");
      var myChart = echarts.init(chartDom);
      var option = {
        backgroundColor: "#222e3c",
        title: {
          text: "{a|" + value + "}{c|%}",

          fontSize: '10',
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: '2rem',
                color: "#ffffff",
                fontWeight: "bold",
              },
              c: {
                fontSize: '2rem',
                color: "#ffffff",
                fontWeight: "normal",
              },
            },
          },
        },
        series: [
          //外环
          {
            name: "外环",
            type: "pie",
            silent: true,
            clockwise: true,
            radius: ["70%", "75%"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: "#223755",
            },
            data: [0],
          },
          //内环
          {
            name: "内环",
            type: "pie",
            silent: true,
            clockwise: true,
            radius: ["45%", "46%"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: "#414f63",
            },
            data: [0],
          },
          //外环
          {
            name: "",
            type: "pie",
            radius: ["55%", "65%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            label: {
              show: false,
            },
            data: [
              {
                value: value,
                itemStyle: {
                  normal: {
                    //外环发光
                    borderWidth: 0.5,
                    shadowBlur: 20,
                    borderColor: "#4bf3f9",
                    shadowColor: "#9bfeff",
                    color: {
                      // 圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#15caff", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#159bfe", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value: 100 - value,
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#364662",
                  },
                },
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      myChart.setOption(option);

      // 监听窗口大小变化，调整图表大小
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.tu()
  },
};
</script>
<style>
#main3 {
  width: 100%;
  height: 207px;
}
</style>