import Vue from 'vue'
import VueRouter from 'vue-router'
// 主路由
import Main from "../views/Main.vue";
// denglu
import Login from "@/views/Login.vue";
//zhuce
import Register from "@/views/Register.vue";
//shebei
import Shebei from "@/views/shebei/shebei.vue";
import shebeiDetail from '../views/shebei/shebeiDetail.vue'
// import Boxing from "@/views/shebei/Boxing.vue";
// import guanli from "../views/shebei/shebeiguanli.vue";
// import Shebeishow from "@/views/shebei/shebeishow.vue";
// import toushitu from "../views/shebei/toushitu.vue";
// import dangan from "../views/shebei/shebeidangan.vue";
// import shebeisy from "../views/shebei/shebei.vue";
// shouye
import Shouye from "../views/shouye/shouye.vue";
import Home from "../views/shouye/Home.vue";
// tongji
import Tongji from "@/views/tongji/tongji.vue";
// import electricalEnergy from "@/views/tongji/electricalEnergy.vue";
import Tongjisy from "../views/tongji/tongjisy.vue";
import Tongjishow from '../views/tongji/tongjishow.vue'
import monitor from '../views/shebei/monitor.vue'
//碳排放
// import emission from "../views/emission/emission.vue";
// import emissionYOY from "../views/emission/emissionYOY.vue";
// import emissionQOQ from "../views/emission/emissionQOQ.vue";
// import carbonEmissions from "@/views/emission/carbonEmissions.vue";
// import CarbonReport from "../views/emission/CarbonReport.vue";
// import tanliutu from "../views/emission/tanliutu.vue";
// import tanpaifang from "../views/emission/tanpaifang.vue";
//数据分析
// import DataAnalysis from "../views/data analysis/dataanalysis.vue";
// import YOY from "../views/data analysis/YOY.vue";
// import QOQ from "../views/data analysis/QOQ.vue";
// import baobiao from "../views/data analysis/baobiao.vue";
// xunjian
// import Xunjian from "@/views/xunjian/xunjian.vue";
import pingmian from "@/views/xunjian/pingmian.vue";
// import liti from "@/views/xunjian/liti.vue";
//excel
// import createxcel from "../views/excel/createxcel.vue";
// import Excel from "@/views/excel/excel.vue";
// import Export from "@/views/excel/export-excel.vue";
// import Upload from "@/views/excel/upload-excel.vue";
// 系统诊断
import ZhenDuan from '@/views/zhenduan/zhenduan.vue'
import wangluo from '@/views/zhenduan/wangluo.vue'
//TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT
// import Ceshi from '@/views/woceshide/test.vue';
// import Yihao from '@/views/woceshide/chind.vue';
// import jiade from '@/views/woceshide/jiade.vue'
//screen
import largescreen from "../views/largescreen/index.vue";
Vue.use(VueRouter)
//denied
import denied from "../views/denied.vue";
import setting from "../views/shezhi/setting.vue"
import permissions from "../views/shezhi/permissions.vue"
import houtai from "../views/shezhi/houtai.vue"
import role from '../views/shezhi/role.vue'
import user from '../views/shezhi/user.vue'
import Big from '../views/shezhi/big.vue'
import JieGou from '../views/shezhi/jiegou.vue'
import ZhuShou from '../views/shezhi/zhushou.vue'
import management from '../views/shezhi/management.vue'
//展会
// import duanluqi from "../views/duanluqi/duanluqi.vue";
// import dgxb from "../views/duanluqi/dgxb.vue";
// import haiwei from "../views/duanluqi/haiwei.vue";
const routes = [
  //主路由
  {
    path: '/',
    component: Main,
    name: 'Main',
    redirect: '/shouye/home',//重定向
    //子路由
    children: [
      {
        path: 'tongji', name: 'Tongji', component: Tongji,
        children: [
          { path: 'tongjisy', name: 'Tongjisy', component: Tongjisy },//home
          { path: 'tongjishow', name: 'Tongjishow', component: Tongjishow },//home
        ]
      },//统计
      // {
      //   path: 'dataanalysis', name: 'DataAnalysis', component: DataAnalysis,
      //   children: [
      //     { path: 'YOY', name: 'YOY', component: YOY },//同比
      //     { path: 'QOQ', name: 'QOQ', component: QOQ },//环比
      //     { path: 'baobiao', name: 'baobiao', component: baobiao },//月度报表
      //   ]
      // },//数据分析
      // {
      //   path: 'emission', name: 'emission', component: emission,
      //   children: [
      //     { path: 'emissionYOY', name: 'emissionYOY', component: emissionYOY },//碳排同比
      //     { path: 'emissionQOQ', name: 'emissionQOQ', component: emissionQOQ },//碳排环比
      //     { path: 'carbonemissions', name: 'carbonEmissions', component: carbonEmissions },//碳排放界面
      //     { path: 'carbonreport', name: 'CarbonReport', component: CarbonReport },//碳排报表
      //     { path: 'tanliutu', name: 'tanliutu', component: tanliutu },//碳硫图
      //     { path: 'tanpaifang', name: 'tanpaifang', component: tanpaifang },//碳排放
      //   ]
      // },//碳排放
      {
        path: 'xunjian', name: 'Xunjian', component: pingmian,
        // children: [
        //   { path: 'pingmian', name: 'pingmian', component: pingmian },//平面
        //   { path: 'liti', name: 'liti', component: liti },//立体
        // ]
      },//xunjian
      // {
      //   path: 'ceshi', name: 'Ceshi', component: Ceshi,
      //   children: [{
      //     path: 'yihao', name: 'Yihao', component: Yihao
      //   },
      //   {
      //     path: 'jiade', name: 'jiade', component: jiade
      //   }]
      // },//TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT
      {
        path: 'shouye', name: 'Shouye', component: Shouye,
        children: [
          { path: 'home', name: 'Home', component: Home },//首页
        ]
      },//主页
      {
        path: 'shebei', name: 'Shebei', component: Shebei,
        children: [
          ,//设备首页
        ]
      },//设备
      { path: 'shebeiDetail', name: 'shebeiDetail', component: shebeiDetail },
      { path: 'monitor', name: 'monitor', component: monitor },

      // {
      //   path: 'excel', name: 'Excel', component: Excel, children: [
      //     { path: 'export', name: 'Export', component: Export },//导出
      //     { path: 'upload', name: 'Upload', component: Upload },//导入
      //     { path: 'createxcel', name: 'createxcel', component: createxcel },//填写表单
      //   ]
      // },//测试表格


      //展会
      // {
      //   path: 'duanluqi', name: 'duanluqi', component: duanluqi, children: [
      //     { path: 'dgxb', name: 'dgxb', component: dgxb },//导出
      //     { path: 'haiwei', name: 'haiwei', component: haiwei },//导入
      //   ]
      // },
      // 诊断
      {
        path: 'ping', name: 'ping', component: ZhenDuan
      },
      
      {
        path: 'houtai', name: 'houtai', component: houtai,
        children: [
          // {
          //   path: 'setting', name: 'setting', component: setting,
          // },
          {
            path: 'permissions', name: 'permissions', component: permissions,
          },
          {
            path: 'role', name: 'role', component: role,
          },
          {
            path: 'user', name: 'user', component: user,
          },
          {
            path: 'ping', name: 'ping', component: ZhenDuan
          },
          {
            path: 'wangluo', name: 'wangluo', component: wangluo
          },
          {
            path: 'big', name: 'big', component: Big
          },
          {
            path: 'jiegou', name: 'jiegou', component: JieGou
          },
          {
            path: 'zhushou', name: 'zhushou', component: ZhuShou
          },
          {
            path: 'management', name: 'management', component: management
          },
        ]
      }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },//登录
  {
    path: '/Register',
    name: 'Register',
    component: Register
  },//zhuce
  {
    path: '/screen',
    name: 'largescreen',
    component: largescreen
  },//可视化大屏
  {
    path: '/access-denied',
    name: 'denied',
    component: denied
  },//权限错误
]

const router = new VueRouter({
  routes
})
export default router
