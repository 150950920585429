import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import './registerServiceWorker'
import router from './router'
import store from './store'
//引入cookie
import Cookie from "js-cookie"
import ElementUI from 'element-ui'
import iView from 'iview'
/* 引入 iview 样式 */
// import 'iview/dist/styles/iview.css';
import 'element-ui/lib/theme-chalk/index.css'
import './css/custom-element-ui.css'; // 引入自定义样式表
import * as echarts from 'echarts'
import DisableDevtool from 'disable-devtool';
import 'font-awesome/css/font-awesome.min.css'
// DisableDevtool();

fetch('/index.html')
  .then(response => response.text())
  .then(html => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const newVersion = doc.querySelector('meta[name="version"]').getAttribute('content');
    const currentVersion = document.querySelector('meta[name="version"]').getAttribute('content');

    if (newVersion !== currentVersion) {
      window.location.reload();
    }
  });


Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
Vue.use(ElementUI)
import { Loading } from 'element-ui';
Vue.use(Loading);
Vue.use(Loading.directive);


import utils from "./lib/utils";
Vue.use(utils)
// 引入图表
import VCharts from 'v-charts'
import i18n from './api/locale/index'; // 导入 i18n 实例
import OrgTree from 'v-org-tree'
// import 'v-org-tree/dist/v-org-tree.css'


Vue.prototype.$echarts = function (el) {
  return echarts.init(el, null, { renderer: 'svg' })
}
Vue.use(OrgTree)
Vue.use(VCharts)
Vue.use(iView)
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 判断 token 是否存在
  const token = Cookie.get('token01');
  // 判断是否是刷新页面
  const isRefresh = !from.name;
  //   if (!from.name) {
  //   localStorage.setItem('lastVisitedRoute', to.path);
  // }
 
  // token 不存在，说明当前用户未登录，应该跳转至登录页
  if (!token && to.path !== '/login') {
    if (to.name === 'Login' || to.name === 'Register') {
      // 如果要访问的是登录页或注册页，则放行
      next();
    } else {
      // 其他情况重定向到登录页
      next({ name: 'Login' });
    }
  } else if (token) { // token 存在，说明用户已登录
    if (to.path === '/login') { // 如果要访问的是登录页，跳转至首页
      next({ path: '/shouye/home' });
    } else if (isRefresh ) { 
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});


new Vue({
  router,
  store,
  i18n, // 挂载 i18n
  render: h => h(App),
  // created(){
  //   store.commit('addMenu',router)
  // }
}).$mount('#app')
