<template>
  <div
    class="big"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="left" style="margin-left: 50px; margin-top: 40px">
      <pie-chart></pie-chart>
    </div>
    <!-- <div
      class="right"
      style="margin-left: 3vw; margin-right: 25px; margin-top: 60px"
    >
      <raddar-chart></raddar-chart>
    </div> -->
  </div>
</template>
  
  <script>
import RaddarChart from "./components/RaddarChart.vue";
import PieChart from "./components/PieChart.vue";

import { mapState, mapMutations } from "vuex";
import Cookie from "js-cookie";
import * as echarts from "echarts";
import { set } from "vue";

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};

export default {
  components: {
    RaddarChart,
    PieChart,
  },
  data() {
    return {
      loading: true,
      lineChartData: lineChartData.newVisitis,
      username: "",
      data: [1, 2],
    };
  },
  created() {
    this.username = Cookie.get("username01");
    this.updateMenu(this.username);
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    ...mapMutations(["updateMenu"]),
    // handleSetLineChartData(type) {
    //   this.lineChartData = lineChartData[type]
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
// *body {
//   margin: 0;
// }
// .big{
//   display: flex;
//   height: 700px;
// }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

.big {
  display: flex;
  height: 100%; /* 或者使用 min-height: 100%; */
}
</style>
  