<template>
  <div id="day02" style="width: 1540px; height: 450px"></div>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "MiddleTable",
  data() {
    return {};
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("day02");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "逆变器发电量月趋势", // 设置标题内容
          textStyle: {
            color: "#778794", // 设置标题颜色
            fontSize: 18, // 设置标题字体大小
          },
          left: "center",
          // 其他标题样式属性...
        },
        legend: {
          bottom: 0,
          textStyle: {
            color: "#ffffff",
          },
        },
        tooltip: {
          with: 1000,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "1#NBQ",
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: [320, 332, 301, 334, 390, 330, 320],
            itemStyle: {
              color: "chartreuse", // 设置为红色
            },
          },
          {
            name: "2#NBQ",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: "#309ae6", // 设置为红色
            },
          },
          {
            name: "3#NBQ",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: "grey", // 设置为红色
            },
          },
          {
            name: "4#NBQ",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            data: [150, 232, 201, 154, 190, 330, 410],
            itemStyle: {
              color: "#44d5d2", // 设置为红色
            },
          },
          {
            name: "5#NBQ",
            type: "bar",
            data: [862, 1018, 964, 1026, 1679, 1600, 1570],
            itemStyle: {
              // 设置渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3cc4dd" }, // 0% 处的颜色
                { offset: 1, color: "#154e90" }, // 100% 处的颜色
              ]),
            },
            emphasis: {
              focus: "series",
            },
            markLine: {
              lineStyle: {
                type: "dashed",
              },
              data: [[{ type: "min" }, { type: "max" }]],
            },
          },
          {
            name: "6#NBQ",
            type: "bar",
            barWidth: 5,
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            data: [620, 732, 701, 734, 1090, 1130, 1120],
            itemStyle: {
              color: "#efb859", // 设置为红色
            },
          },
          {
            name: "7#NBQ",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 290, 230, 220],
            itemStyle: {
              color: "#4ac58e", // 设置为红色
            },
          },
          {
            name: "8#NBQ",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            data: [60, 72, 71, 74, 190, 130, 110],
            itemStyle: {
              color: "#62a3e5", // 设置为红色
            },
          },
          {
            name: "Others",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            data: [62, 82, 91, 84, 109, 110, 120],
            itemStyle: {
              color: "red", // 设置为红色
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.tu();
  },
};
</script>
<style>
/* #day {
  width: 100% !important;
  height: 450px;
}
#day div {
  width: 800px;
} */
canvas {
  width: 820px;
  color: #309ae6;
}
</style>