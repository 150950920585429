<template>
  <div class="headers">
    <el-dialog title="密码修改成功" :visible.sync="showDialog" width="30%">
      <p>您的密码已成功修改，请重新登录。</p>
      <div style="text-align: right; margin: 1.25vw 0">
        <el-button @click="handleLogout" type="primary">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-steps :active="active" finish-status="success">
        <el-step title="初始密码"></el-step>
        <el-step title="新密码"></el-step>
        <el-step title="修改成功"></el-step>
      </el-steps>
      <el-form
        ref="passwordForm"
        :model="passwordData"
        :rules="passwordRules"
        class="password-form"
      >
        <el-form-item v-if="active === 0" label="初始密码" prop="oldPassword">
          <el-input
            v-model="passwordData.oldPassword"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="active === 1" label="新密码" prop="newPassword">
          <el-input
            v-model="passwordData.newPassword"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-if="active === 0" style="text-align: center; margin-top: 0.75vw">
        <el-button @click="next">下一步</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
      <div v-if="active === 1" style="text-align: center; margin-top: 0.75vw">
        <el-button @click="next">下一步</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
      <div v-if="active === 2" style="text-align: center; margin-top: 0.75vw">
        <el-button @click="changePassword">确认修改</el-button>
        <el-button @click="closeDialog">关闭</el-button>
      </div>
      <div v-if="active === 3" style="text-align: center; margin-top: 0.75vw">
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
    <div class="headers-left">
      <p
        style="
          position: absolute;
          left: -11vw;
          width: auto;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <img
          style="width: 4vw; height: 4vw"
          src="../assets/切图/图形标，可以重新设计(1)(1).png"
          alt=""
        />
        <span
          style="
            color: white;
            display: block;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
          "
          ><i>山东华莱智科综合能源管控系统</i></span
        >
      </p>
      <div style="display: flex; margin-left: 5vw">
        <div class="left"></div>
        <!-- <div class="headers-left-box" :class="asideShow === false ? 'headers-left-active' : ''" @click="handleMenu">
                <i style="color: white; line-height: 1.25vw; height: 1.25vw;" class="el-icon-s-fold "></i>
            </div> -->
        <!-- 面包屑
            <el-breadcrumb style="height: 100%; display: flex; justify-content: start;align-items: center;"
                separator-class="el-icon-arrow-right">
                <router-link
                    style=" display: block; margin-left: .3125vw; height: 80%; margin-right: .125vw; text-decoration: none; color: white;"
                    v-for="item in tags" :key="item.path" :to="{ path: item.path }">{{ item.label
                    }}></router-link>
            </el-breadcrumb> -->

        <el-menu
          default-active="1-4-1"
          class="el-menu-demo hhh"
          mode="horizontal"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item
            style="text-align: center; height: 100%"
            @click="clickMenu(item)"
            v-for="item in parentNodes"
            v-if="item.showdata !== '0'"
            :key="item.name"
            :index="item.name"
          >
            <i :class="`${item.icon}`"></i>
            <span slot="title" style="display: inline-block">{{
              item.label
            }}</span>
          </el-menu-item>
        </el-menu>
        <el-popover
          placement="top"
          width="200"
          trigger="manual"
          v-model="isPopoverVisible"
          style="position: fixed;top: 7%;left: 35%;z-index: 200;"
        >
        <!-- <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="isPopoverVisible = false"
              >x</el-button
            >
          </div> -->
        <el-button size="mini" type="text" @click="onClick(1)"
              >实际布局图</el-button
            ><el-button size="mini" type="text" @click="onClick(2)"
              >干线图</el-button
            >
         
        </el-popover>

        <div class="right"></div>
      </div>
    </div>
    <div class="headers-right">
      <div class="headers-right-left">
        <el-button
          type="primary"
          size="mini"
          @click="onSubmit"
          style="
            position: absolute;
            right: 6.25vw;
            top: 0.9375vw;
            background: rgba(0, 0, 0, 0.301);
            border: 1px rgba(255, 255, 255, 0.301) solid;
          "
          >打开数据可视化大屏</el-button
        >
        <el-tooltip
          class="item"
          effect="dark"
          :content="isFullscreen ? '取消全屏' : '全屏'"
          placement="bottom"
        >
          <i
            style="color: white"
            :class="
              isFullscreen
                ? 'el-icon-full-screen head-screen-news'
                : 'el-icon-rank head-screen'
            "
            @click="buttoncli"
          ></i>
          <reduction
            v-if="isFullscreen"
            class="headers-right-left-screenIcon"
            @click="buttoncli"
          />
          <screen
            v-else
            class="headers-right-left-screenIcon"
            @click="buttoncli"
          />
        </el-tooltip>
        <el-tooltip
          class="item"
          style="margin: 0 0.625vw 0 1.25vw"
          effect="dark"
          content="切换背景"
          placement="bottom"
        >
          <i
            style="color: white"
            class="el-icon-picture-outline-round"
            @click="theme = true"
          ></i>
        </el-tooltip>
        <!-- <el-dropdown @command="handleClick"
                    style="display: flex;justify-content: space-between;align-items: center;width: 4.25vw; color: white;">
                    <span class="el-dropdown-link" style="; color: white; ">
                        <p style="cursor:pointer">{{$t(this.username) }}</p>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="chain">简体中文</el-dropdown-item>
                        <el-dropdown-item command="english">英文</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
      </div>
      <div class="headers-right-right">
        <div class="block"><i class="el-icon-s-custom"></i></div>
        <el-dropdown
          @command="handleClick"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 4.25vw;
            color: white;
          "
        >
          <span class="el-dropdown-link" style="width: 6.25vw; color: white">
            <span style="cursor: pointer">{{ this.username }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="revise">修改密码</el-dropdown-item>
            <el-dropdown-item command="cancel">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="theme"
      direction="rtl"
      size="500px"
      class="theme"
    >
      <div class="theme-title">背景切换</div>
      <div class="theme-title-box">
        <div
          class="theme-title-box-list"
          v-for="(item, index) in themeColor"
          :key="index"
          @click="targetThemeIndex(index)"
          :class="themeIndex === index ? 'theme-active' : ''"
          :style="{
            background: 'url(' + item.url + ') no-repeat center center',
            'background-size': '100% 100%',
          }"
        ></div>
      </div>
    </el-drawer>
  </div>
</template>


<script>
import {
  handleClick,
  next,
  cancel,
  changePassword,
  closeDialog,
  handleLogout,
} from "../menthod/component/header";
import { mapState, mapMutations } from "vuex";
//引入cookie
import Cookie from "js-cookie";
// 全屏组件
import screenfull from "screenfull";
import screen from "../assets/icon/screen.svg"; //全屏
import reduction from "../assets/icon/reduction.svg"; //还原
export default {
  props: {
    themeColor: {
      type: Array,
      default: true,
    },
    themeIndex: {
      type: Number,
      default: true,
    },
  },
  data() {
    return {
      isPopoverVisible: false,
      parentnodes: [],
      isFullscreen: false,
      setTime: null, //定时器
      theme: false, // 主题 开关
      showDialog: false,
      dialogVisible: false,
      active: 0,
      passwordData: {
        oldPassword: "",
        newPassword: "",
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: "请输入初始密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, message: "新密码至少为 6 位字符", trigger: "blur" },
        ],
      },
      username: "",
    };
  },
  created() {
    this.username = Cookie.get("username01");
    
    // const storedPermissions = localStorage.getItem('permissions');
    // const permissionsmenu = []; // Initialize an empty menu

    // if (storedPermissions) {
    //     const parsedPermissions = JSON.parse(storedPermissions);

    //     parsedPermissions.forEach(item => {
    //         const menuItem = {
    //             id: item.id,
    //             path: item.path,
    //             name: item.name,
    //             label: item.label,
    //             icon: item.icon,
    //             url: item.url,
    //             showdata: item.showdata,
    //             children: item.children
    //         };
    //         permissionsmenu.push(menuItem);
    //     });
    //     this.updateMenu(permissionsmenu)
    // } else {
    //     console.log('No stored permissions found.');
    // }
  },
  computed: {
    //获取store中的state
    ...mapState({
      tags: (state) => state.tabsList,
    }),
    asideShow() {
      return this.$store.state.asideShow;
    },
    ...mapState(["menu"]),
    //没有子菜单
    parentNodes() {
      return this.menu.map((item) => {
        if (!item.children || item.children.length === 0) {
          return item;
        } else {
          // 如果有子节点，将子节点置为空
          return {
            path: item.path,
            name: item.name,
            label: item.label,
            icon: item.icon,
            url: item.url,
            show: item.showdata,
          };
        }
      });
    },
  },
  methods: {
    onClick(e){
      if(e == 1){
        this.$router.push('/xunjian');
        this.isPopoverVisible = false
      }else{
        this.isPopoverVisible = false

        this.$router.push('/');
      }
    },
    ...mapMutations(["deletMenu"]),
    // ...mapMutations(['updateMenu']),
    ...mapMutations(["selectparentmenu"]),
    clickMenu(item) {
      if (this.$route.path !== item.path && this.$route.path !== "/") {
        const twopath = item.path;
        this.isPopoverVisible = false;
        if (twopath == "/tongji") {
          if (this.$route.path != "/tongji/tongjisy") {
            this.$router.push(twopath + "/tongjisy");
          }
        this.isPopoverVisible = false;

        } else if (twopath == "/shouye") {
          if (this.$route.path != "/shouye/home") {
            this.$router.push(twopath + "/home");
          }
        this.isPopoverVisible = false;

        } else if (twopath == "/shebei") {
          if (this.$route.path != "/shebei") {
            this.$router.push(twopath);
          }
        this.isPopoverVisible = false;

        } else if (item.label == "巡检页") {
          this.isPopoverVisible = true;
        } else {
          this.$router.push(twopath);
        this.isPopoverVisible = false;

        }
      }
      this.selectparentmenu(item.path);
    },
    onSubmit() {
      this.$router.push("/screen");
      this.enterFullscreen();
    },
    enterFullscreen() {
      const element = document.documentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // Internet Explorer and Edge
        element.msRequestFullscreen();
      }
    },
    // 全屏
    buttoncli() {
      // if (!screenfull.enabled) { // 如果不允许进入全屏，发出不允许提示
      //     this.$message({
      //         message: '不支持全屏',
      //         type: 'warning'
      //     })
      //     return false
      // }
      screenfull.toggle();
      this.checkFull();
    },
    // 监控屏幕变化
    checkFull() {
      var isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      // to fix : false || undefined == undefined
      // 是否全屏判断
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.$message({
          message: "进入全屏",
          type: "success",
        });
      } else {
        this.$message({
          message: "退出全屏",
          type: "warning",
        });
      }

      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    // 选取配色方案
    targetThemeIndex(index) {
      this.theme = false;
      this.$emit("targetThemeIndex", index);
    },
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    next() {
      next(this);
    },
    cancel() {
      cancel(this);
    },
    changePassword() {
      changePassword(this);
    },
    closeDialog() {
      closeDialog(this);
    },
    handleLogout() {
      handleLogout(this);
    },
    handleClose(done) {
      this.$confirm("确认关闭？").then(() => {
        done();
        this.active = 0;
      });
    },
    //弹窗关闭
    handleClose(done) {
      // 在关闭对话框前，重置步骤为初始步骤
      this.active = 0;
      done(); // 关闭对话框
    },
    cancel2() {
      this.handleClose();
    },
    handleClick(command) {
      handleClick(this, command);
    },
  },
  mounted() {
    // window.addEventListener('resize', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.headers {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #3e5368;
  z-index: 1000;
  // background: url(../assets/header.png) no-repeat;
  // background-size:  cover;
  // background-position-x: 65%;
}

.headers-left {
  // background: url(../assets/lef.png) no-repeat;
  background-size: 5% 100%;
  position: relative;
  margin-left: 11.85vw;
  display: flex;
  justify-content: flex-start;
}

.el-icon-s-fold {
  width: 1.25vw;
  height: 100%;
  line-height: 3.125vw;
  font-size: 1.25vw;
  cursor: pointer;
}

.el-breadcrumb {
  line-height: 3.125vw;
}

.headers-right {
  /* width: 200px; */
  height: 100%;
  /* background: orchid; */
  display: flex;

  justify-content: space-around;
}

.headers-right-left {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.375vw;
  cursor: pointer;
  position: relative;
  margin-left: 17rem;
}

.headers-right-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #666666;
  margin-left: 2rem;
  margin-right: 2rem;
}

.block {
  margin-right: 0.9375vw;
}

.el-dropdown-link {
  cursor: pointer;
}

.focusing {
  border: none !important;
}

/* 主题 */
.theme {
  height: 100vh;
  // overflow: hidden;
}

.el-drawer__body {
  height: 100vh !important;
  // overflow: hidden;
}

.theme-title {
  width: 100%;
  height: 3.125vw;
  line-height: 3.125vw;
  font-size: 1vw;
  font-weight: bold;
  padding: 0 0.9375vw;
  border-bottom: 0.0625vw solid #dcdfe6;
}

.theme-title-box {
  padding: 0.625vw;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: calc(100vh - 3.1875vw);
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow: "-moz-scrollbars-none";
  scrollbar-width: none;
  /*  火狐   */
  background: #f5f7f9;
}

.theme-title-box::-webkit-scrollbar {
  display: none;
  /*  Chrome  */
  width: 0 !important;
  /*  Chrome  */
}

.theme-title-box-list {
  width: 11.25vw;
  height: 9.375vw;
  display: flex;
  padding: 0.625vw;
  box-sizing: border-box;
  background: #999999;
  border-radius: 0.3125vw;
  cursor: pointer;
  margin-bottom: 0.625vw;
  border: 0.3125vw solid transparent;
}

.theme-active {
  border: 0.3125vw solid #05da89;
}

.head {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10.625vw;
  height: 100%;

  .title {
    width: 90%;
    height: 80%;
    text-align: center;
    line-height: 2.8125vw;
    color: white;
    background: url(../assets/切图/top.png) no-repeat;
    background-size: 100%;
  }

  .right {
    width: 10%;
    height: 2.8125vw;
    line-height: 2.8125vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      border: 0 !important;
    }
  }
}

.el-dropdown-menu {
  background: rgb(214, 219, 219) !important;
}
.menu {
  background-color: #333f4d;
  width: 35vw;
  // border-radius: 100px 100px 2px 2px / 350% 350% 0 0;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  text-align: center;
}
::v-deep .el-menu-item i {
  font-size: 0.8rem;
  margin-right: 0.3rem;
  width: 1rem;
  line-height: 3.5rem !important;
}
::v-deep .el-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-menu-item:nth-child(1) {
  // width: 7vw;
  width: 4.5rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu-item:nth-child(2) {
  // width: 7vw;
  width: 8.4rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu-item:nth-child(3) {
  // width: 7vw;
  width: 4.5rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu-item:nth-child(4) {
  // width: 7vw;
  width: 6rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu-item:nth-child(5) {
  // width: 7vw;
  width: 6.6rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu-item:nth-child(6) {
  // width: 7vw;
  width: 6.6rem !important;
  height: 3.5rem !important;
  line-height: 3.5rem !important;
  font-size: 0.8rem !important;
}
::v-deep .el-menu--horizontal {
  width: 36.6rem;
  background-color: #333f4d !important;
}
.left {
  background: url(../assets/header-left.png) no-repeat;
  background-size: 100% 100%;
  width: 4vw;
  height: 100%;
  margin-right: -0.06rem;
}
.right {
  background: url(../assets/header-right.png) no-repeat;
  background-size: 100% 100%;
  width: 4vw;
  height: 100%;
}
::v-deep .el-button--mini {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}
// @media screen and (max-width: 720px) {
//   .menu{
//     line-height: 50px!important
//   }
// }
// @media screen and (max-width: 1800px){
//   .el-menu-item{
//     display: none;
//   }
// }
::v-deep .el-popover {
  background-color: #333;
  border: none;
  // color: #fff !important;
}
</style>