<template>
  <div class="home">
    <el-container style="height: 100%; border: 1px solid #545c64">
      <el-aside width="200px" style="background-color: #545c64">
        <el-menu
          class="el-menu-vertical-demo"
          @select="handleMenuSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-user-solid"></i>
              <span>设置权限</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">角色管理</el-menu-item>
              <el-menu-item index="1-2">用户管理</el-menu-item>
              <el-menu-item index="1-3">权限管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">设备层级</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="1">
            <i class="el-icon-document"></i>
            <span slot="title">用户管理</span>
          </el-menu-item> -->
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-link"></i>
              <span>页面诊断</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="2-1">能源图</el-menu-item> -->
              <el-menu-item index="2-2">网络连接测试</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="4">
            <i class="el-icon-document"></i>
            <span slot="title">大屏生成器</span>
          </el-menu-item> -->
          <el-menu-item index="5">
            <i class="el-icon-document"></i>
            <span slot="title">项目结构</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="el-icon-s-help"></i>
            <span slot="title">智能助手</span>
          </el-menu-item>
          <el-menu-item index="7">
            <i class="el-icon-setting"></i>
            <span slot="title">设备管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- 子路由出口 -->
        <router-view style="height: 100vh"></router-view>
      </el-main>
    </el-container>
  </div>
</template>
    
  <script>
import router from "@/router";
// @ is an alias to /src
import CommonAside from "../../components/CommonAside.vue";
import { getTheme, setTheme } from "../../store/utils/theme";
let Theme = parseInt(getTheme()) || 0;
let themeColor = [
  {
    url: require("../../assets/images/8063091afaf4b3ae33a28c36411f61b.png"),
  },
  {
    url: require("../../assets/images/0d3c88008f7f1cc3d6ece7859e41bef.png"),
  },
  {
    url: require("../../assets/images/432c6d7e2c1085c231fdc49ea02904d.jpg"),
  },
  {
    url: require("../../assets/images/039bf0f36e01b8454face05f2a1ab36.jpg"),
  },
  {
    url: require("../../assets/images/4be99c6583d7063b2524f3a056bde8a.jpg"),
  },
  {
    url: require("../../assets/images/700fab26c3c0a56e8a82ae4da2dc9cf.png"),
  },
  {
    url: require("../../assets/images/7036151f453547a502f32f13ee85f6a.jpg"),
  },
  {
    url: require("../../assets/images/f262cbfec56adc5842e64e6f19f6f31.jpg"),
  },
];
export default {
  name: "Main",
  data() {
    return {
      themeIndex: Theme, //当前配色方案
      themeColor: themeColor, //配色方案
    };
  },
  components: {
    CommonAside,
  },
  methods: {
    // 配色方案 子元素自定义事件
    targetThemeIndex(index) {
      this.themeIndex = index;
      setTheme(index);
    },
    handleMenuSelect(index) {
      if (index === "1-1" && this.$route.path !== `/houtai/role`) {
        this.$router.push("/houtai/role");
      } else if (index === "1-2" && this.$route.path !== "/houtai/user") {
        this.$router.push("/houtai/user");
      }else if (index === "1-3" && this.$route.path !== "/houtai/permissions") {
        this.$router.push("/houtai/permissions");
      }else if (index === "2-1" && this.$route.path !== `/houtai/ping`) {
        this.$router.push("/houtai/ping");
      }else if (index === "2-2" && this.$route.path !== `/houtai/wangluo`) {
        this.$router.push("/houtai/wangluo");
      }else if(index === '3' && this.$route.path !== '/houtai/ping' ){
        this.$router.push("/houtai/ping");
      }else if(index === '4' && this.$route.path !== '/houtai/big'){
        this.$router.push("/houtai/big");
      }else if(index === '5' && this.$route.path !== '/houtai/jiegou'){
        this.$router.push("/houtai/jiegou");
      }else if(index === '6' && this.$route.path !== '/houtai/zhushou'){
        this.$router.push("/houtai/zhushou");
      }else if(index === '7' && this.$route.path !== '/houtai/management'){
        this.$router.push("/houtai/management");
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.home {
  width: 100vw;
  height: 100%;
}

.el-container {
  width: 100vw;
  height: 100%;
  background-size: 100% 100% !important;
}

.el-header {
  padding: 0;
  background: rgba(4, 10, 24, 0);
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.3);
}

.el-main {
  padding: 0;
  // background:url(../assets/images/0d3c88008f7f1cc3d6ece7859e41bef.png) no-repeat;
  // background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -ms-overflow-style: none;
  /* 隐藏IE和Edge的滚动条 */
  scrollbar-width: none;
  /* 隐藏Firefox的滚动条 */
  overflow: -moz-scrollbars-none;
  /* 隐藏Firefox的滚动条 */
}

.el-main::-webkit-scrollbar {
  display: none;
  /* 隐藏Chrome和Safari的滚动条 */
}
::v-deep .el-menu {
  width: 200px;
}
</style>