<template>
  <div style="width: 100%;height: 100%;background-color: #fff;">
    <iframe src="https://dianbiao.hualaizhike.com/prod/equipment/equipment" frameborder="0" style="width: 100%;height: 120%;background-color: #fff;"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>