<template>
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo hhh" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item-group v-for="subItem in childrennode" :key="subItem.path" style="overflow: hidden;">
            <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">
                <i :class="`${subItem.icon}`"></i>
                {{ subItem.label }}
            </el-menu-item>
        </el-menu-item-group>
        <!-- </el-submenu> -->
        <div class="headers-left-box" :class="asideShow === false ? 'headers-left-active' : ''" @click="handleMenu">
            <i style="font-size: 1.5vw; color: white; line-height: 1.25vw; height: 1.25vw;" class="el-icon-s-fold "></i>
        </div>
    </el-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getRoleFromCookie } from '../menthod/view/home';
export default {
    name: 'CommonAside',
    data() {
        return {
            childrennode:[],
        };
    },
    created() {
        this.getRoleFromCookie()
        this.childrennode = this.childrenNode
    },
    methods: {
        ...mapMutations(['selectMenu']),
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        clickMenu(item) {
            if (this.$route.path !== `${item.path}` && (this.$route.path !== '/')) {
                this.$router.push(`${item.path}`);
                console.log(123);
            }
            this.selectMenu(item)
        },
        getRoleFromCookie() {
            getRoleFromCookie(this)
        },
        handleMenu() {
            this.$store.commit('collapseMenu')
        },
    },
    //计算属性
    computed: {
        ...mapState(['parentmenu', 'menu']),
        // //没有子菜单
        // noChildren() {
        //     return this.menu.filter(item => !item.children)
        // },
        //有子菜单
        childrenNode() {
            // 根据 parentmenu.path 查找对应的菜单项
            const parentItem = this.menu.find(item => item.path === this.parentmenu);
            // console.log(parentItem);
            if (parentItem.children) {
                // console.log(1); 
                return parentItem.children;
            } else {
                return [];
            }
        },
        //获取store中的state
        isCollapse() {
            return this.$store.state.isCollapse
        },
        asideShow() {
            return this.$store.state.asideShow
        }
    }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 10.875vw;
    min-height: 25vw;
}
.el-menu--collapse {
    width: 50px !important;
}
.el-menu {
    position: relative;
    height: 100%;
    border-right: none;
    background-color: rgba(0, 0, 0, 0) !important;

    h3 {
        color: white;
        font-size: 1vw;
        text-align: center;
        line-height: 3vw;
        font-weight: 400;
    }
}

.headers-left-box {
    cursor: pointer;
    position: absolute;
    right: -.275vw;
    bottom: .625vw;
    transition: all 0.5s;
    transform-origin: center center;
    width: 1.25vw;
    height: auto;
    margin-right: .9375vw;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.headers-left-active {
    transform: rotate(0deg);
}
</style>

