<template>
  <div id="main01"></div>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "LeftTable",
  data() {
    return {};
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("main01");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#9f78d6", "#f6c13d"],
        tooltip: {
          trigger: "axis",
          color: "#fff",
        },
        legend: {
          bottom: 0,
        },

        toolbox: {
          top: 20,
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          textStyle: {
            color: "#fff",
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 x 轴线的颜色为白色
            },
          },
        },
        yAxis: {
          type: "value",

          textStyle: {
            color: "#fff",
          },
          axisLabel: {
            formatter: "{value} W",
            textStyle: {
              color: "#fff", // 设置 y 轴文字颜色为白色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 y 轴线的颜色为白色
            },
          },
        },
        series: [
          {
            name: "Highest",
            type: "line",
            data: [10, 11, 13, 11, 12, 12, 9],
            markPoint: {
              data: [{ type: "max", name: "Max" }],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
        ],
      };

      option && myChart.setOption(option);
      myChart.setOption(option);

      // 监听窗口大小变化，调整图表大小
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },

  },
  mounted() {
    this.tu();
  },
};
</script>
<style scoped>
#main {
  width: 40.3rem;
  height: 16.7rem;
}
</style>