<template>
  <div id="main04"></div>
</template>
<script>
import * as echarts from "echarts5";
export default {
  name: "FootRight",
  data() {
    return {};
  },
  props: ["todayTotalEnergy", "yesterdayTotalEnergy"],
  watch: {
    todayTotalEnergy: {
      handler() {
        this.tu();
      },
      immediate: true, // 立即执行一次以初始化图表
    },
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("main04");
      var myChart = echarts.init(chartDom);
      var option;

      const gaugeData = [
        {
          
          value: this.yesterdayTotalEnergy,
          name: "昨日用电总量",
          title: {
            offsetCenter: ["0%", "-60%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "-40%"],
          },
        },
        {
          value: this.todayTotalEnergy,
          name: "今日用电总量",
          title: {
            offsetCenter: ["0%", "0%"],
            // color:"#1fa9fe"
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "20%"],
            // color:"#1fa9fe"

          },
          itemStyle:{
            color:"#1fa9fe"
          }
        },
      ];
      option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#464646",
              },
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#36344e'], 
                ]
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,
            title: {
              fontSize: 10,
              color:'#fff'
            },
            detail: {
              width: 40,
              height: 10,
              fontSize: 10,
              color: "inherit",
              borderColor: "inherit",
              borderRadius: 20,
              borderWidth: 1,
              formatter: "{value}kw/h",
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    setTimeout(()=>{
      this.tu();
    },1000)
  },
};
</script>
<style>
#main04 {
  width: 100%;
  height: 207px;
  z-index: 10000;
}
</style>