<template>
  <div id="ps"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "PieTable",
  data() {
    return {
      myChart: null, // 保存图表实例的引用
    };
  },
  methods: {
    pie() {
      var chartDom = document.getElementById("ps");
      this.myChart = echarts.init(chartDom); // 将图表实例保存到组件的属性中
      function addBlank(list) {
        const ret = [];
        for (let _list of list) {
          ret.push(_list);
          ret.push({
            name: "",
            value: 1,
          });
        }
        return ret;
      }
      const list1 = [
        { value: 217, name: "故障类型一" },
        { value: 104, name: "故障类型二" },
        { value: 97, name: "故障类型三" },
        { value: 21, name: "故障类型四" },
      ];
      var option;
      option = {
        title: {
          text: "217",
          top: "40%",
          left: "center",
          textStyle: {
            fontSize: "3rem", // 根据窗口大小计算字体大小
            color: "#ffffff",
          },
          subtext: "故障总数",
          subtextStyle: {
            fontSize: "1.5rem",
            color: "rgba(223, 245, 252, 1)",
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: null,
        // 可动态生成颜色集合
        color: ["#0aa7dc", null, "#5a8da5", null, "#5ecfb6", null, "#92c0de"],
        series: [
          {
            name: "运维统计",
            type: "pie",
            radius: ["60%", "70%"],
            center: ["50%", "50%"], // 设置饼图的中心位置为画布中心
            label: {
              show: false,
            },
            data: addBlank(list1),
          },
          {
            type: "pie",
            radius: ["75%", "75.5%"],
            center: ["50%", "50%"], // 设置饼图的中心位置为画布中心
            label: {
              show: false,
            },
            data: addBlank(list1),
          },
        ],
      };
      option && this.myChart.setOption(option);
    },
    calculateFontSize() {
      // 根据窗口大小动态计算字体大小
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        return 15;
      } else {
        return 26;
      }
    },
  },
  mounted() {
    this.pie();
    // 监听窗口大小变化，调整图表大小
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
};
</script>
<style lang="scss" scoped>
#ps {
  width: 13.6rem;
  height: 15.6rem;
}
</style>
