import { render, staticRenderFns } from "./PieChart.vue?vue&type=template&id=4a6dcaa8&"
import script from "./PieChart.vue?vue&type=script&lang=js&"
export * from "./PieChart.vue?vue&type=script&lang=js&"
import style0 from "./PieChart.vue?vue&type=style&index=0&id=4a6dcaa8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.88.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports