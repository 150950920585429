<template>
  <div class="home">
    <!-- <button @click="onBtn">666</button> -->
    <el-dialog title="请选择设备与能耗" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="设备" :label-width="formLabelWidth">
          <el-select placeholder="请选择设备" v-model="stationId">
            <el-option
              v-for="item in eqData"
              :key="item.stationId"
              :label="item.name"
              :value="item.stationId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="能耗" :label-width="formLabelWidth">
          <el-select placeholder="能耗" v-model="type">
            <el-option label="电压" value="voltage"></el-option>
            <el-option label="电流" value="current"></el-option>
            <el-option label="功率" value="power"></el-option>
            <el-option label="功率因数" value="powerfactor"></el-option>
            <el-option label="用电量" value="energy"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <div class="header">
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData1.value }}</span>
            <span style="font-size: 1.2rem">( {{ typeData1.unit }} )</span>
            <img
              style="width: 1.2rem; margin-left: 4rem"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">{{typeData1.equipmentName}}</div>
        </div>
        <i
          class="el-icon-setting"
          style="
            color: aliceblue;
            position: absolute;
            right: 1rem;
            cursor: pointer;
            bottom: 0.2rem;
          "
          @click="setUp(1)"
        ></i>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData2.value }}</span>
            <span style="font-size: 1.2rem">( {{ typeData2.unit }} )</span>
            <img
              style="width: 1.2rem; margin-left: 4rem"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">{{ typeData2.equipmentName }}</div>
        </div>
        <i
          class="el-icon-setting"
          style="
            color: aliceblue;
            position: absolute;
            right: 1rem;
            cursor: pointer;
            bottom: 0.2rem;
          "
          @click="setUp(2)"
        ></i>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData3.value }}</span>
            <span style="font-size: 1.2rem">( {{ typeData3.unit }} )</span>
            <img
              style="width: 1.2rem; margin-left: 4rem"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">{{ typeData3.equipmentName }}</div>
        </div>
        <i
          class="el-icon-setting"
          style="
            color: aliceblue;
            position: absolute;
            right: 1rem;
            cursor: pointer;
            bottom: 0.2rem;
          "
          @click="setUp(3)"
        ></i>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData4.value }}</span>
            <span style="font-size: 1.2rem">( {{ typeData4.unit }} )</span>
            <img
              style="width: 1.2rem; margin-left: 4rem"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">{{ typeData4.equipmentName }}</div>
        </div>
        <i
          class="el-icon-setting"
          style="
            color: aliceblue;
            position: absolute;
            right: 1rem;
            cursor: pointer;
            bottom: 0.2rem;
          "
          @click="setUp(4)"
        ></i>
      </div>

      <div class="header05">
        <div class="dxj">待巡检</div>
        <div class="zdx">站点巡检评分</div>
      </div>
      <div class="header06">
        <div class="jing">
          <img src="../../assets/警告.png" alt="" />
          <div class="jing01">{{ fal }}</div>
          <div class="jing02">离线设备</div>
        </div>
      </div>
      <div class="header07">
        <div class="jing">
          <img src="../../assets/正确.png" alt="" />
          <div class="zc">{{ zaixian }}</div>
          <div class="jing02">在线设备</div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="center01">
        <div class="center0101">
          <div>
            <div>
              <span class="fadian">总电流( A )</span>
              <span class="jin"
                ><span style="font-size: 0.8rem">{{ currentDate }} </span></span
              >
            </div>
            <div class="lefttext">{{ totalData.totalCurrent }}</div>
            <div class="lefttext01"></div>
          </div>
        </div>

        <div class="center0102">
          <div>
            <div>
              <span class="fadian">总用电量( KW/h )</span>
              <span class="jin"
                ><span style="font-size: 0.8rem">{{ currentDate }} </span></span
              >
            </div>
            <div class="lefttext">{{ totalData.totalEnergy }}</div>
            <div class="lefttext01"></div>
          </div>
        </div>
      </div>
      <div class="center02">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2.5rem; height: 2.5rem"
          />
          <span style="font-size: 1rem">用电统计</span>
        </div>
        <div id="main" style="width: 96% !important"></div>
      </div>
      <div class="center03">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2.5rem; height: 2.5rem"
          />
          <span style="font-size: 1rem">用电功率趋势</span>
        </div>
        <div id="main01">
          <left-table></left-table>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="foot01">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2.5rem; height: 2.5rem"
          />
          <span style="font-size: 1rem">变压器状态统计</span>
          <!-- <span class="chakan"><a href="">查看详情</a></span> -->
        </div>
        <div class="foot0102">
          <div id="">
            <FootLeft></FootLeft>
            <div class="botttonleft">
              <span class="span01" style="font-size: 1.5rem">8</span
              ><span class="span02" style="font-size: 1.5rem">/8</span>
              <div>
                <span class="span03">在线设备数</span>
              </div>
            </div>
          </div>
          <div>
            <FootCenter></FootCenter>
            <div class="botttonleft">
              <span class="span01" style="font-size: 1.5rem">8</span
              ><span class="span02" style="font-size: 1.5rem">/8</span>
              <div>
                <span class="span03">运行设备数</span>
              </div>
            </div>
          </div>
          <div>
            <FootRight></FootRight>
            <div class="botttonleft">
              <span class="span01" style="font-size: 1.5rem">8</span
              ><span class="span02" style="font-size: 1.5rem">/8</span>
              <div>
                <span class="span03">故障设备数</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot02">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2.5rem; height: 2.5rem"
          />
          <span style="font-size: 1rem">站点基本信息</span>
        </div>
        <div class="mess">
          <div>
            <span>站点名称:</span>
            <span>示例项目</span>
          </div>
          <div>
            <span>详细地址:</span>
            <span></span>
          </div>
          <div>
            <span>联系方式:</span>
            <span>赵工/13800001234</span>
          </div>
          <div>
            <span>投运时间:</span>
            <span>2022 -05-11</span>
          </div>
          <div>
            <span>安全运行:</span>
            <span>567天</span>
          </div>
        </div>
      </div>
      <div class="foot03">
        <div class="rightbottom">
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div
              class="hj"
              style="position: absolute; top: 1.2rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">环境温度:</span>
              <div style="font-size: 1rem">21.1℃</div>
            </div>
          </div>
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div
              class="hj"
              style="position: absolute; top: 1.2rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">露点:</span>
              <div style="font-size: 1rem">5.2℃</div>
            </div>
          </div>
        </div>
        <!-- -----------------------分界线------------- -->
        <div class="rightbottom">
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div
              class="hj"
              style="position: absolute; top: 1.2rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">环境湿度:</span>
              <div style="font-size: 1rem">35.3%RH</div>
            </div>
          </div>
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div
              class="hj"
              style="position: absolute; top: 1.2rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">风速:</span>
              <div style="font-size: 1rem">0.8</div>
            </div>
          </div>
        </div>
        <!-- +++++++++++++++++++++++++++++++++++++ -->
        <div class="rightbottom">
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div
              class="hj"
              style="position: absolute; top: 1.2rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">瞬时辐射:</span>
              <div style="font-size: 1rem">363.0 mj/m²</div>
            </div>
          </div>
          <div style="position: relative">
            <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            />

            <div class="hj" style="position: absolute; top: 20px; left: 130px">
              <span style="font-size: 1rem">日辐射总量:</span>
              <div style="font-size: 1rem">8.4 kWh/m²</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState, mapMutations } from "vuex";
import Cookie from "js-cookie";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import LeftTable from "../shouye/components/main01.vue";
import FootLeft from "../shouye/components/progress04.vue";
import FootCenter from "../shouye/components/progress01.vue";
import FootRight from "../shouye/components/progress03.vue";
import axios from "axios";
import instance from "@/request/request";
import { nextTick } from 'vue';

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};
export default {
  // name: "HomeView",
  components: {
    LeftTable,
    FootLeft,
    FootCenter,
    FootRight,
  },
  data() {
    return {
      stationId:'',
      type:'',
      boxId:'',
      typeData1:{},
      typeData2:{},
      typeData3:{},
      typeData4:{},
      dialogFormVisible: false,
      lineData: [],
      currentDate: "",
      statusInterval: null,
      zx: [],
      succ: [],
      lx: [],
      fal: "",
      zaixian: "",
      cgx: [],
      cgx02: [],
      cgx03: [],
      mincgx: "",
      maxcgx: "",
      date01: [],
      date02: [],
      date03: [],
      date04: [],
      date05: [],
      lineChartData: lineChartData.newVisitis,
      username: "",
      data: [1, 2],
      eqData: [],
      totalData:[]
    };
  },
  created() {
    this.username = Cookie.get("username01");
    this.updateMenu(this.username);
  },
  methods: {
    onClose(){
      this.stationId = ''
      this.type=''
    },
    async getTotal(){
      const res = await instance('/ammeter/getTotalData')
      this.totalData = res.data.content
    },
    async onSubmit(){
      this.dialogFormVisible = false
      console.log(this.stationId);
      console.log(this.type);
      const res = await instance({
        url:'/ammeter/getDataByStationIdAndType',
        params:{
          stationId:this.stationId,
          type:this.type
        }
      })
      if(this.boxId == ''){
        
      }
      if(this.boxId == 1){
        this.typeData1 = res.data.content
      }else if(this.boxId == 2){
        this.typeData2 = res.data.content
      }else if(this.boxId == 3){
        this.typeData3 = res.data.content
      }else if(this.boxId == 4){
        this.typeData4 = res.data.content
      }
    },
    async setUp(i) {
      const res = await instance("/wx/getAllEquipment");
      this.eqData = res.data.content;
      this.dialogFormVisible = true;
      this.boxId = i
      console.log(this.boxId);
    },
    async web01() {
      const res = await instance({
        url: "/ammeter/getEquipmentCurrent",
      });
      // console.log(res.data[2], "eeeeeeeeeeeeee");
      if (!res.data.success) {
        this.$message.error(res.data.message);
        // this.$router.push('/shouye/home')
      } else {
        this.lineData = res.data.content.filter((x) => x.pid == null);
        console.log(
          res.data.content.filter((x) => x.pid == null),
          9999999999999999
        );
      }
    },
    getCurrentDate() {
      const date = new Date();
      this.currentDate = date.getDate();
    },
    async getStatus() {
      (this.zx = []), (this.succ = []), (this.lx = []);
      const res = await instance("/wx/getStatus");
      this.zx = res.data.content;
      console.log(this.zx, "cgxgcdfssdf");
      // if (this.zx.length) {
      //   for (var g = 0; g < this.zx.length; g++) {
      //     if (this.zx[g].message == "true") {
      //       this.succ.push(this.zx[g].message);
      //     }
      //     if (this.zx[g].message != "true") {
      //       this.lx.push(this.zx[g].message);
      //     }
      //   }
      // }
      this.succ = res.data.content.filter(x=>x.message == 'true')
      this.lx = res.data.content.filter(x=>x.message !== 'true')
        console.log(this.succ, "在线在线在线设备");
        console.log(this.lx, "离线的设备");
        this.zaixian = this.succ.length;
        this.fal = this.lx.length;
    },
    jie() {
      instance
        .get("/ammeter/getPowerData")
        .then((res) => {
          // 处理请求成功的情况
          console.log(res.data.content, "fffffff");
          const contentArray = res.data.content;
          this.cgx02 = res.data.content;
          // 使用数组的 slice 方法来截取前四个元素
          const firstFourElements = contentArray.slice(0, 4);

          console.log(firstFourElements, "aaaaaaaasss");
          for (var x = 0; x < firstFourElements.length; x++) {
            this.cgx.push(firstFourElements[x].avoltage);
          }
          console.log(this.cgx, "cgxcgxcgxcg");
          console.log(this.cgx02, "xxxxxxxxx");
          for (var f = 0; f < this.cgx02.length; f++) {
            this.cgx03.push(this.cgx02[f].avoltage);
          }
          console.log(this.cgx03, "wwwwwwwwwwwwwwwwww");

          const minValue = Math.min(...this.cgx03);

          // 找出最大值
          const maxValue = Math.max(...this.cgx03);
          this.mincgx = minValue;
          this.maxcgx = maxValue;
          console.log("最小值:", minValue);
          console.log("最大值:", maxValue);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("发生错误:", error);
        });

      //--------------------------------------------------
      axios
        .get(
          "https://api.qweather.com/v7/weather/now?location=101010100&key=599a1304e29b4198840ff8ecb04be02c"
        )
        .then((ress) => {
          console.log(ress, "vvvvv");
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("发生错误:", error);
        });
    },
    async onBtn() {
      await axios("http://localhost:3000/api/hello");
    },
    ...mapMutations(["updateMenu"]),
    centertable() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        color: ["#3cc4dd", "#f0d51f"],
        legend: {
          bottom: 0,
          textStyle: {
            color: "#ffffff",
          },
        },
        // tooltip: {
        //   color: "#fff",
        //   width:100
        // },
        dataset: {
          source: [
            ["product", "昨天", "今天"],
            ["配电室一", 43.3, 85.8],
            ["配电室二", 83.1, 73.4],
            ["配电室三", 86.4, 65.2],
            ["配电室四", 72.4, 53.9],
          ],
        },
        xAxis: {
          type: "category",
          textStyle: {
            // 设置标签文字样式
            color: "#fff", // 标签文字颜色
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 x 轴线的颜色为白色
            },
          },
          lineStyle: {
            color: "#fff",
            type: "solid",
          },
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} KWh",
            textStyle: {
              fontSize: "0.8rem",
              color: "#fff", // 设置 y 轴文字颜色为白色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 y 轴线的颜色为白色
            },
          },
          textStyle: {
            color: "#fff", //坐标值得具体的颜色
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            itemStyle: {
              // 设置渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3cc4dd" }, // 0% 处的颜色
                { offset: 1, color: "#154e90" }, // 100% 处的颜色
              ]),
            },
          },
          {
            type: "bar",
            itemStyle: {
              // 设置渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#f0d51f" }, // 0% 处的颜色
                { offset: 1, color: "#e28606" }, // 100% 处的颜色
              ]),
            },
          },
        ],
      };

      option && myChart.setOption(option);
      for (let i = 1; i < option.dataset.source.length; i++) {
        for (let j = 1; j < option.dataset.source[i].length; j++) {
          option.dataset.source[i][j] = this.generateRandomNumber(40, 90); // 你可以根据需要调整范围
        }
        myChart.setOption(option);

        // 监听窗口大小变化，调整图表大小
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    generateRandomNumber(min, max) {
      return (Math.random() * (max - min) + min).toFixed(1); // 保留一位小数
    },

    webs() {
      const socket = new WebSocket("ws://localhost:8082/ws/1");

      // 连接建立事件
      socket.addEventListener("open", () => {
        console.log("WebSocket连接已建立");
      });

      // 消息接收事件
      socket.addEventListener("message", (event) => {
        const message = event.data;
        console.log("收到消息：", message);
        // 处理接收到的消息，将其显示在前端界面上
      });

      // 发送消息
      function sendMessage(message) {
        socket.send(message);
      }

      // 调用发送消息的函数，例如在点击按钮后发送消息
      const sendButton = document.getElementById("sendBtn");
      sendButton.addEventListener("click", () => {
        const messageInput = document.getElementById("messageInput");
        const message = messageInput.value;
        sendMessage(message);
        messageInput.value = ""; // 清空输入框
      });

      // 连接关闭事件
      socket.addEventListener("close", () => {
        console.log("WebSocket连接已断开");
      });

      // 连接错误事件
      socket.addEventListener("error", (error) => {
        console.error("发生错误：", error);
      });
    },
    handleScroll() {
      // 在滚动时执行的操作，例如刷新组件
      location.reload(); // 这会强制重新渲染组件
      // console.log(1323);
    },
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.statusInterval);
  },
  mounted() {
    this.getTotal()
    this.stationId = 1
    nextTick(()=>{
      this.type = 'voltage'
    this.boxId = 1
    this.onSubmit()
    })
    nextTick(()=>{
      this.type = 'current'
    this.boxId = 2
    this.onSubmit()
    })
    nextTick(()=>{
      this.type = 'powerfactor'
    this.boxId = 3
    this.onSubmit()
    })
   nextTick(()=>{
    this.type = 'energy'
    this.boxId = 4
    this.onSubmit()
   })
    this.web01();
    this.getCurrentDate();
    this.getStatus();
    this.statusInterval = setInterval(() => {
      this.getStatus();
    }, 10000);
    this.centertable();
    this.jie();
    // console.log(window);
    // window.addEventListener('resize', this.handleScroll);

    // setInterval(() => {
    //   this.centertable();
    // }, 1500);
  },
  destroyed() {
    // 在组件销毁时移除事件监听器，以防止内存泄漏
    // window.removeEventListener('resize', this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 80vh !important;
}

.rightbottom > div > img {
  margin-top: 10px;
  margin-left: 20px;
}

.hj span {
  font-size: 20px;
  color: #798593;
}

.hj div {
  font-size: 20px;
  color: #45bfe0;
  vertical-align: middle;
}

.rightbottom {
  display: flex;
  width: 100%;
  height: 120px;
  background-color: #222e3c;
  margin-top: 5px;
}

.rightbottom > div {
  width: 49%;
  height: 120px;
  box-shadow: 2px 2px 20px 2px rgb(117, 115, 115);
  border-radius: 5px;
  margin-left: 9px;
}

.mess {
  color: #fff;
  font-size: 1rem;
}

.mess > div {
  margin-top: 5px;
  width: 100%;
  height: 50px;
  background-color: #222e3c;
}

.mess > div > span:nth-child(2) {
  margin-left: 20%;
}

.mess > div > span:nth-child(1) {
  margin-left: 10%;
}

.mess > div > span {
  display: inline-block;
  margin-top: 15px;
}

.botttonleft {
  text-align: center;
  background-color: #222e3c;
}

.botttonleft span {
  font-size: 28px;
  font-weight: 600;
}

.span01 {
  font-size: 1rem;
  color: #14c9c8;
}

.botttonleft .span03 {
  display: inline-block;
  font-size: 1rem;
}

.span02 {
  font-size: 1rem;
  color: #8596a5;
}

.foot0102 {
  width: 100%;
  height: 90%;
  background-color: #1d2031;
  justify-content: space-between;
  display: flex;
}

.foot0102 > div {
  width: 33%;
  background-color: #222f3d;
}

.chakan {
  margin-left: 50%;
}

.foot01,
.foot02,
.foot03 {
  height: 375px;

  background-color: #1d2031;
}

.foot01 {
  width: 33%;
}

.foot02 {
  width: 31.8%;
  background-color: #232f3d;
}

.foot03 {
  width: 34%;
}

.foot {
  width: 100%;
  margin-top: 10px;
  height: 360px;
  background-color: #1d2031;
  display: flex;
  justify-content: space-between;
}

.center0201 > span {
  color: #fff;
  vertical-align: middle;
}

.center0201 {
  width: 100%;
  height: 10%;
  background-color: #2a3a4a;
}

#main01,
#main {
  height: 80%;
}

.lefttext01 {
  font-size: 1rem;
  padding-top: 10px;
  color: #848fa1;
  padding-left: 10px;
}

.lefttext {
  font-size: 1.5rem;
  color: #379bd9;
  font-weight: 600;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  border-bottom: 3px solid black;
}

.fadian {
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  margin-left: 0.6rem;
}

.jin {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url(../../assets/日历1.png) no-repeat;
  margin-left: 10rem;
  margin-top: 1.2rem;
  text-align: center;
}

.jin > span {
  margin-top: 0.6rem;
  display: inline-block;
  color: #fff;
}

.center0101 {
  width: 100%;
  height: 11rem;
  background-color: #222e3c;
}

.center0102 {
  margin-top: 0.4rem;
  width: 100%;
  height: 200px;
  background-color: #222e3c;
}

.center01 {
  width: 15%;
  background-color: #1d2031;
  margin-right: 0.6rem;
}

.center02 {
  width: 50%;
  background-color: #222e3c;
  margin-right: 0.6rem;
}

.center03 {
  width: 33.9%;
  background-color: #222e3c;
}

.center {
  width: 100%;
  height: 420px;
  background-color: #1d2031;
  margin-top: 5px;
  display: flex;
}

.zc {
  color: #4fb58e;
}

.jing01 {
  color: #ca210b;
}

.jing02 {
  font-size: 0.8rem;
  margin-top: 10px;
}

.jing {
  text-align: center;
  color: #fff;
}

.jing img {
  width: 1.5rem;
  height: 1.5rem;
}

.dxj {
  font-size: 0.8rem;
  color: #fff;
  margin: 15px;
  margin-top: 25px;
}

.zdx {
  font-size: 0.6rem;
  color: #fff;
  font-size: 12px;
  color: #7c8ba0;
}

.header05 {
  text-align: center;
}

.header07 img,
.header06 img {
  margin-top: 15px;
}

.kk {
  color: #3aa5dc;
  margin: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.zj {
  font-size: 0.5rem;
  padding-top: 0.8rem;
  color: #7c8ba0;
}

.header {
  height: 120px;
  background-color: #1d2031;
  display: flex;

  .header03,
  .header02,
  .header01,
  .header04 {
    width: 20%;
  }

  // .header03 {
  //   width: 20%;
  // }

  // .header04 {
  //   width: 22%;
  // }

  .header05,
  .header06,
  .header07 {
    width: 10%;
  }
}

.header > div {
  height: 100%;
  margin-right: 10px;
  background-color: #222e3c !important;
  border-radius: 5px;
}

.header07 {
  margin-right: 0 !important;
}

.kwp {
  color: #3aa5dc;
  font-size: 1.5rem;
}

.kk img {
  margin-left: 60%;
}

#main {
  width: 100% !important;
}

#main {
  width: 100% !important;
}

#main ::v-deep div {
  width: 100% !important;
}

#main ::v-deep div:first-child {
  width: 100% !important;
}

#main ::v-deep div ::v-deep canvas {
  width: 100% !important;
}

#main ::v-deep div:first-child ::v-deep canvas {
  width: 100% !important;
}

#main > div ::v-deep canvas {
  width: 100% !important;
}

// @media screen and (max-width: 700px) {
//   .kk img {
//     display: none;
//   }

//   .rightbottom>div>img {
//     display: none;
//   }

//   .foot0102 {
//     display: none;
//   }
// }

::v-deep .el-container {
  height: 93% !important;
}

::v-deep element.style {
  left: 1rem;
  width: 50rem;
}
</style>