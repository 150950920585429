<template>
  <div class="login-container" @keyup.enter="login">
    <img src="../assets/hualai.png" alt="" style="width: 25rem" />
    <el-form
      ref="loginForm"
      :model="loginData"
      :rules="loginRules"
      class="login-form"
      v-if="trigger"
    >
      <h2 class="login-title">山东华莱智科综合能源管控系统</h2>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="loginData.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="loginData.password" type="password"></el-input>
      </el-form-item>
      <el-form-item
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div style="display: flex">
          <el-switch
            style="padding-right: 4vw"
            v-model="value"
            active-text="进入可视化大屏"
             class="hide-on-mobile"
            @change="LargeScreen"
          ></el-switch>
          <div class="btn" style="display: flex">
            <el-button type="primary" @click="login" size="mini"
              >登录</el-button
            >
            <el-button size="mini" type="primary" @click="goToRegister"
              >注册</el-button
            >
            <el-button size="mini" @click="wxLogin">微信登录</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import { Message } from "element-ui";
Vue.prototype.$message = Message;
export default {
  name: "Login",
  data() {
    return {
      trigger: true,
      value: true,
      wxCode: "",
      loginData: {
        username: "",
        password: "",
        showQrCode: false,
      },
      loginRules: {
        username: [
          { required: true, message: "用户名为空", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "用户名只能包含字母和数字",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "密码为空", trigger: "blur" }],
      },
      menudata: [],
    };
  },
  computed: {
    //获取store中的state
    ...mapState({
      LargeScreendata: (state) => state.LargeScreendata,
    }),
  },
  created() {
    this.BackLargeScreen();
  },
  methods: {
    ...mapMutations(["ChangeLargeScreen"]),
    ...mapMutations(["BackLargeScreen"]),
    goToRegister() {
      this.$router.push("/Register");
    },
    login() {
      // console.log(133);
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const loginTime = new Date();
          axios
          // http://192.168.3.13:8000
            .post("https://mqtt.hualaizhike.com/user/login", {
              username: this.loginData.username,
              password: this.loginData.password,
              // loginTime: loginTime,
            })
            .then((response) => {
              console.log(response);
              if(response.data.success){
                document.cookie = `token01=${response.data.content}; path=/`;
              document.cookie = `username01=${this.loginData.username}; path=/`;
              document.cookie = `role=${response.data.role}; path=/`;
              // localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
              // this.$store.commit('addMenu', this.$router)
              // this.$message({
              //   message: '登录成功',
              //   type: 'success'
              // });
              this.$message.success("登录成功");
 
                if(this.value){
                  this.$router.push("/screen");
                  this.enterFullscreen();
                }else{
                  this.$router.push("/shouye/home");
                }
              }else{
                this.$message.error("登录失败，请检查用户名密码");
              }

            })
            .catch((error) => {
              this.$message.error("登录失败，请检查用户名密码");

              console.error("登录失败", error);
            });
        } else {
          console.log("Form validation failed.");
        }
      });
    },
    LargeScreen() {
      this.ChangeLargeScreen();
    },
    enterFullscreen() {
      const element = document.documentElement;

      if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // Internet Explorer and Edge
        element.msRequestFullscreen();
      }
    },
    async wxLogin() {
      this.showQrCode = true;
      const res = await axios("https://hlzk-wx.hualaizhike.com/");
      console.log(res.data);
      let a = document.createElement("a");
      a.href = res.data;
      a.click();
    },
  },
};
</script>


<style lang="less" scoped>
.login-container {
  display: flex;
  justify-content: center; /* 左右居中 */
  align-items: center; /* 上下居中 */
  flex-wrap: wrap;
  // background: url(../assets/切图/5f4f1171fcce5fb02080b27c8785355.png) no-repeat;
  // background-position: 50% -900%;
  width: 25rem;
  height: 25rem;
  margin: 0 auto;
  padding: 2.5rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.25rem;
  background-color: #ccccccc0;
}

form {
  text-align: center;
  font-size: 0.8rem;
  width: 25rem !important;
  // margin-bottom: 1.25rem;
}

.login-form {
  // margin-top: 9.0625rem;
  position: relative;
}
::v-deep .el-switch__label * {
  font-size: 0.8rem !important;
}
::v-deep .el-form-item__content {
  font-size: 0.8rem !important;
  line-height: 2.5rem !important;
}
::v-deep .el-form-item__label {
  font-size: 0.8rem !important;
  line-height: 2.5rem !important;
}
::v-deep .el-form-item {
  margin-bottom: 1rem;
}
::v-deep .el-input__inner {
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.8rem !important;
  // width: 60%;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-button {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}
::v-deep .el-switch {
  line-height: 1.25rem;
  height: 1.25rem;
  font-size: 0.8rem;
}
::v-deep .el-switch__core {
  line-height: 2rem;
  // height: 2rem;
}
::v-deep .el-switch__label--right {
  margin-left: 0.6rem;
}
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

</style>
