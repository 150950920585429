<template>
  <div class="con">
    <div class="top">
      <div class="top01">
        <span>山东华莱智科智慧平台大屏</span>
      </div>
      <div class="top02">
        <span class="span01" @click="goBack"><span>返回首页</span></span>
        <span class="span02"><span>进入控制台</span></span>
        <span class="span03">{{ currentTime }}</span>
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>
    <div class="center">
      <div class="center01">
        <div class="center0101">
          <div class="title01">
            <span class="titlespan01">站点信息</span>
          </div>
          <div>
            <span class="jieru01">接入站点数</span>
            <span class="jieru02">变压器数</span>
          </div>
          <div class="lefttop">
            <div><span style="font-size: 2rem;">17</span><span style="font-size: 1.6rem;">个</span></div>
            <div><span style="font-size: 2rem;">432</span><span style="font-size: 1.6rem;">个</span></div>
          </div>
          <div class="leftcenter">
            <span style="font-size: 0.8rem;">在线站点</span>
            <span class="lixian">离线站点</span>
            <span class="lixian01">故障站点</span>
            <span class="lixian02">告警站点</span>
          </div>
          <div class="topbotton">
            <div class="zx01">12</div>
            <div class="zx02">5</div>
            <div class="zx03">3</div>
            <div class="zx04">8</div>
          </div>
        </div>
        <div class="center0102">
          <div class="title01">
            <span class="titlespan01">用电信息</span>
          </div>
          <div class="center010201">
            <span class="center55">当日</span>
            <span class="center56">21.3</span>
            <span class="center57">kwh</span>
            <span class="center58">31.2</span>
            <span class="center59">元</span>
          </div>
          <div class="center010202">
            <span class="center55">当月</span>
            <span class="center56">301.2</span>
            <span class="center57">kwh</span>
            <span class="center58">931.2</span>
            <span class="center59">元</span>
          </div>
          <div class="center010203">
            <span class="center55">当年</span>
            <span class="center56">67701.2</span>
            <span class="center57">kwh</span>
            <span class="center58">21431.2</span>
            <span class="center59">元</span>
          </div>
        </div>
        <div class="center0103">
          <div class="title01">
            <span class="titlespan01">实时功率曲线</span>
          </div>
          <div id="tuzz"></div>
        </div>
      </div>
      <div class="center02">
        <div class="center0284">
          <div class="center0285">
            <div class="fa fa-bar-chart-o" style="width:2.8rem; height: 3.1rem" alt=""></div>
            <span>1012.3</span>
            <div class="di">累计用电量 kwh</div>
          </div>
          <div class="center0286">
            <div class="fa fa-leaf" style="width: 2.8rem; height: 3.1rem" alt=""></div>
            <span>947.5</span>
            <div>累计收益 元</div>
          </div>
          <div class="center0287">
            <div class="fa fa-pie-chart" style="width: 2.8rem; height: 3.1rem" alt=""></div>
            <span>4200</span>
            <div>总装机容量 kw</div>
          </div>
          <div class="center0288">
            <div class="fa fa-bell-o" style="width: 2.8rem; height: 3.1rem" alt=""></div>
            <span>3950</span>
            <div>当前总功率 kw</div>
          </div>
        </div>
        <div class="centerheader">
          <span>电站分布</span>
        </div>
        <div class="center02125">
          <MapTable></MapTable>
        </div>
      </div>
      <div class="center03">
        <div class="center0301">
          <div class="title0301">
            <span class="titlespan01">设备功率</span>
          </div>
          <div class="danwei"><span style="font-size: 0.8rem;">单位:kwh</span></div>
          <div class="righttable">
            <span class="danspan01">1</span>
            <span class="danspan02">配电箱01</span>
            <el-progress :percentage="50" class="proc"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan002">2</span>
            <span class="danspan02">配电箱02</span>
            <el-progress :percentage="50" class="proc proc02"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan003">3</span>
            <span class="danspan02">配电箱03</span>
            <el-progress :percentage="50" class="proc proc03"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan004">4</span>
            <span class="danspan02">配电箱04</span>
            <el-progress :percentage="50" class="proc proc04"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan004">5</span>
            <span class="danspan02">配电箱05</span>
            <el-progress :percentage="50" class="proc proc05"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan004">6</span>
            <span class="danspan02">配电箱06</span>
            <el-progress :percentage="50" class="proc proc06"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan004">7</span>
            <span class="danspan02">配电箱07</span>
            <el-progress :percentage="50" class="proc proc07"></el-progress>
            <span class="dan139">51.24</span>
          </div>
          <div class="righttable">
            <span class="danspan01 danspan004">8</span>
            <span class="danspan02">配电箱08</span>
            <el-progress :percentage="50" class="proc proc08"></el-progress>
            <span class="dan139">51.24</span>
          </div>
        </div>
        <div class="center0302">
          <div class="title0301">
            <span class="titlespan01">运维统计</span>
          </div>
          <div class="right188">
            <PieTable></PieTable>
            <div class="right190">
              <div class="right191">
                <span class="rightspan01">47</span>
                <span class="rightspan02">故障类型一</span>
                <span class="rightspan03">217</span>
              </div>
              <div class="right192">
                <span class="rightspan01">24</span>
                <span class="rightspan02">故障类型二</span>
                <span class="rightspan03">104</span>
              </div>
              <div class="right193">
                <span class="rightspan01">22</span>
                <span class="rightspan02">故障类型三</span>
                <span class="rightspan03">97</span>
              </div>
              <div class="right194">
                <span class="rightspan01">17</span>
                <span class="rightspan02">故障类型四</span>
                <span class="rightspan03">21</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="end">
      <div class="end01">
        <div class="end0101">
          <span class="fdqs">用电趋势</span>
        </div>
        <div class="end0102">
          <EndTable></EndTable>
        </div>
      </div>
      <div class="end02">
        <div class="end0202">
          <span class="titlespan01">等效社会贡献</span>
        </div>
        <div class="endfirst">
          <div class="endf01">
            <span style="
                position: absolute;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1.8rem;
                color: #37dabf;
                left: 1.5rem;
                top:1.7rem;
              " class="fa fa-gears"></span>
            <span class="spand01">3312</span><br />
            <span class="spand02">减排co2</span>
          </div>
          <div class="endf02">
            <span style="
                position: absolute;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1.8rem;
                color: #37dabf;
                left:1.5rem;
                top: 1.5rem;
              " class="fa fa-line-chart"></span>
            <span class="spand01">3312</span><br />
            <span class="spand02">减排co2</span>
          </div>
        </div>
        <!-- 分界线 -->
        <div class="endfirst">
          <div class="endf01">
            <span style="
                position: absolute;
                display: inline-block;
                width:1.5rem;
                height: 1.5rem;
                font-size:1.8rem;
                color: #37dabf;
                left:1.5rem;
                top: 1.7rem;
              " class="fa fa-refresh"></span>
            <span class="spand01">3312</span><br />
            <span class="spand02">减排co2</span>
          </div>
          <div class="endf02">
            <span style="
                position: absolute;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1.8rem;
                color: #37dabf;
                left: 1.5rem;
                top: 1.7rem;
              " class="fa fa-lightbulb-o"></span>
            <span class="spand01">3312</span><br />
            <span class="spand02">减排co2</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import MapTable from "./components/map.vue";
import PieTable from "./components/PieTable.vue";
import EndTable from "./components/EndTable.vue";
export default {
  components: {
    MapTable,
    PieTable,
    EndTable,
  },
  data() {
    return {
      currentTime: "",
      imageUrl: "",
    };
  },
  methods: {
    goBack() {
      if (this.isFullscreen()) {
        this.$router.push("/shouye/home");
        location.reload();
        this.exitFullscreen();
      } else {
        this.$router.push("/shouye/home");
      }
    },
    isFullscreen() {
      return (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    tuzz() {
      var chartDom = document.getElementById("tuzz");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        grid: {
          // 设置边距
          top: "5%",
          bottom: "8%", // 调整底部边距，给 y 轴标签留更多空间
          left: "5%", // 调整左边边距，给 y 轴标签留更多空间
          right: "5%",
          containLabel: true, // 自动包含坐标轴标签
        },
        xAxis: {
          type: "category",
          data: [
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
          ],
          axisLine: {
            lineStyle: {
              color: "white", // 设置 x 轴线的颜色为白色
            },
          },
          axisLabel: {
            color: "white", // 设置 x 轴标签的字体颜色为白色
            fontSize: this.calculateFontSize(), // 动态计算字体大小
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "white", // 设置 y 轴线的颜色为白色
            },
          },
          axisLabel: {
            color: "white", // 设置 y 轴标签的字体颜色为白色
            fontSize: this.calculateFontSize(), // 动态计算字体大小
          },
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130, 140, 150, 20],
            type: "bar",
            itemStyle: {
              // 渐变色设置
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#dfedd2" }, // 渐变色起始颜色
                { offset: 1, color: "#ab9b6b" }, // 渐变色结束颜色
              ]),
            },
          },
        ],
      };

      option && myChart.setOption(option);

      // 监听窗口大小变化，调整图表大小和字体大小
      window.addEventListener("resize", () => {
        myChart.resize();
        myChart.setOption({
          xAxis: {
            axisLabel: {
              fontSize: this.calculateFontSize(), // 动态计算字体大小
            },
          },
          yAxis: {
            axisLabel: {
              fontSize: this.calculateFontSize(), // 动态计算字体大小
            },
          },
        });
      });
    },
    calculateFontSize() {
      // 根据窗口大小动态计算字体大小
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
      } else if (screenWidth < 1200) {
        return 10;
      } else {
        return 12;
      }
    },

    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getCurrentTime();
    this.tuzz();
    // 更新时间，每秒钟更新一次
    setInterval(this.getCurrentTime, 1000);
  },
};
</script>
<style lang="scss" scoped>
.con {
  width: 100%;
  height: 100vh;
  background-color: black;
}

.top {
  height: 3.75rem;
  width: 100%;
  background: rgb(105, 185, 216);
  background-image: url(../../assets/121604.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  background-size: 100% 3.75rem;
}

// .top01 {
//   width: 80%;
//   height: 100%;
//   background-color: #fff;
//   background-image: url(../../assets/121603.jpg);
//   background-repeat: no-repeat;
// }
.top01 {
  width: 31.25rem;
  margin-top: 0.625rem;
  height: 60%;
  //   background: linear-gradient(to right, #102836, #234c5f);
  border-radius: 20%;
  text-align: center;
}

.top01 span {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
}

.top02 {
  width: 31.25rem;
}

.span02,
.span01 {
  display: inline-block;
  width: 7.18rem;
  text-align: center;
  height: 2.5rem;
  // background-image: url(../../assets/121701.png);
  background-size: cover;
  border: 0.0625rem solid #06dee9;
  border-radius: 0.3rem;
  margin-left: 0.625rem;
  background-position-x: -0.31rem;
  background-color: rgba($color: #f3efef, $alpha: 0.2);

  background-repeat: no-repeat;
  margin-top: -1.25rem;
  position: relative;
  top: -0.75rem;
}

.span02 {
  // background-image: url(../../assets/121701.png);

  margin-left: 1.5rem;
  background-position-x: -0.5rem;
  border: 0.06rem solid #06dee9;
}

.span03 {
  color: #fff;
  font-size: 1.25rem;
  display: inline-block;
  width: 7.1rem;
  height: 2.5rem;
  vertical-align: middle;
  margin-top: -0.9rem;
  margin-left: 1.5rem;
}

.avatar-uploader .el-upload {
  border: 0.06rem dashed #d9d9d9;
  border-radius: 0.37rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.center0102 {
  margin-top: 0.9rem;
}

.avatar-uploader-icon {
  font-size: 2.8rem;
  color: #8c939d;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 3.1rem;
  text-align: center;
  border-radius: 50%;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  border-radius: 50%;
}

.avatar-uploader {
  width: 2.5rem;
  display: inline-block;
  border-radius: 50%;
}

.avatar-uploader ::v-deep div ::v-deep i {
  margin-top: -0.625rem !important;
}

.center {
  width: 100%;
  height: 68vh;
  margin-top: 0.625rem;
  background-color: #0e2535;
  // background-image: url(../../assets/121705.png);
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
}

.center03,
.center01 {
  width: 27.5%;
  height: 100%;
  background-color: #122835;
}

.center02 {
  width: 44%;
  height: 100%;
  background-color: pink;
}

.center01>div {
  width: 100%;
  height: 32%;
  background-color: #122835;
  margin-bottom: 1.333%;
}

.title01 {
  width: 100%;
  height: 20%;
  background-image: url(../../assets/121704.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.title0301 {
  width: 100%;
  height: 14%;
  background-image: url(../../assets/121704.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.end0202 {
  width: 100%;
  height: 20%;
  background-image: url(../../assets/121704.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 1%;
}

.titlespan01 {

  display: inline-block;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.7%;
  margin-left: 7%;
}

.jieru02,
.jieru01 {
  font-size: 0.8rem;
  color: #fff;
}

.jieru02 {
  margin-left: 38%;
}

.lefttop {
  width: 100%;
  height: 38%;
  display: flex;
  justify-content: space-between;
}

.lefttop>div {
  background-color: (13, 49, 65, 0.5);
  background-color: rgba($color: #0d3141, $alpha: 0.6);
  width: 48%;
  border-radius: 0.18rem;
  text-align: center;
  background: linear-gradient(to bottom right, #123143, #1a6177);
  /* 添加阴影以增加光泽感 */
  box-shadow: 0 0 0.31rem rgba(255, 255, 255, 0.3);
  // background-image: url(../../assets/121802.png);
}

.lefttop div:first-child {
  margin-left: 0.31rem;
}

.lefttop>div>span:first-child {
  color: #fff;
  font-size: 2.81rem;
  margin-top: 3%;
  display: inline-block;
}

.lefttop>div>span {
  color: #fff;
}

.jieru01 {
  margin-left: 0.625rem;
}

.leftcenter>span {
  color: #fff;
  margin-left: 10%;
}

.topbotton {
  width: 100%;
  height: 21%;
  display: flex;
  justify-content: space-between;

  // margin-left: 5px;
}

.topbotton div:first-child {
  margin-left: 0.31rem;
}

.topbotton>div {
  width: 24%;
  height: 100%;
  border-radius: 0.2rem;
  display: flex;
  background-color: rgba($color: #0d3141, $alpha: 0.6);
  background: linear-gradient(to bottom right, #123143, #1a6177);
  align-items: center;
  justify-content: center;

  /* 添加阴影以增加光泽感 */
  box-shadow: 0 0 0.3rem rgba(255, 255, 255, 0.3);
  // background-image: url(../../assets/121802.png);
}

.topbotton div {
  color: #fff;
  font-size: 1.56rem;
  text-align: center;
  padding-top: 0.0625rem;
}

.zx01 {
  color: #63eade !important;
}

.zx02 {
  color: #e25e84 !important;
}

.zx04 {
  color: #e59b2b !important;
}

.leftcenter span:first-child {
  margin-left: 0.5rem !important;
}

.lixian {
  font-size: 0.8rem;
  margin-left: 4.375rem !important;
}

.lixian01 {
  font-size: 0.8rem;
  margin-left: 4.0625rem !important;
}

.lixian02 {
  font-size: 0.8rem;
  margin-left: 4.0625rem !important;
}

.leftcenter {
  margin-top: 0.31rem;
}

.center010203,
.center010202,
.center010201 {
  position: relative;
  width: 100%;
  height: 25%;
  background-color: #122835;
  background-image: url(../../assets/121803.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0.625rem;
  margin-top: 1%;
}

.center010203>span,
.center010202>span,
.center010201>span {
  display: inline-block;
}

.center55 {
  font-size: 0.8rem;
  color: #fff;
  margin-top: 2%;
  margin-left: 6%;
}

.center56 {
  position: absolute;
  top: 0;
  color: #ccce8a;
  margin-top: 2%;
  font-size: 1.375rem;
  margin-left: 15%;
  font-weight: 600;
}

.center57 {
  font-size: 0.8rem;
  color: #fff;
  margin-left: 15%;
  position: absolute;
  top: 1.06rem;
  left: 10rem;
}

.center58 {
  margin-left: 15%;
  color: #67e6d7;
  font-size: 1.375rem;
  font-weight: 600;
  position: absolute;
  top: 0.81rem;
  left: 14rem;
}

.center59 {
  font-size: 0.8rem;
  color: #fff;
  margin-left: 12%;
  position: absolute;
  top: 1.06rem;
  left: 23rem;
}

.center0103 {
  margin-top: 0.625rem;
  margin-bottom: 0;
}

#tuzz {
  width: 100%;
  height: 75%;
}

.center0284 {
  width: 100%;
  height: 15%;
  background-color: #0e2535;
  display: flex;
  justify-content: space-between;
}

.center0288,
.center0287,
.center0286,
.center0285 {
  width: 24%;
  height: 87%;
  margin-top: 1%;
  // background-color: rgb(125, 224, 163);
  // background-image: url(../../assets/121809.png);
  border: 0.06rem solid #5e8a97;
  padding-bottom: 0.3rem !important;
  border-radius: 0.625rem;
  vertical-align: middle;
  position: relative;
  text-align: center;
}

.center0288 span,
.center0287 span,
.center0286 span,
.center0285 span {
  color: #dbb759;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin-top: 3%;
  display: inline-block;
}

.center0288 div,
.center0287 div,
.center0286 div,
.center0285 div {
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}

.center0288 img,
.center0287 img,
.center0286 img,
.center0288 :first-child,
.center0287 :first-child,
.center0286 :first-child,
.center0285 :first-child {
  margin-top: 1.25rem;
  position: absolute;
  color: #37dabf;
  left: 0;
  font-size: 1.6rem;
  top: 0.625rem;
}

.center0286 span {
  color: #44edd2;
}

.center0288 span,
.center0287 span {
  color: #fff;
}

.centerheader {
  width: 100%;
  height: 5%;
  background-color: #fff;
  background-image: url(../../assets/121808.png);
  background-size: cover;
}

.centerheader span {
  color: #fff;
  font-weight: 600;
  margin-left: 4%;
  margin-top: 0.7%;
  display: inline-block;
}

.center02125 {
  width: 100%;
  height: 80%;
  background-color: #122f41;
}

.center0301 {
  width: 100%;
  height: 52%;
  background-color: #081a26;
}

.danwei {
  width: 100%;
  height: 7%;
  background-color: #081a26;
  color: #fff;
  margin-right: 0.625rem;
  position: relative;
}

.danwei span {
  position: absolute;
  right: 0.6rem;
}

.danspan01 {
  font-size: 0.8rem;
  display: inline-block;
  background-color: red;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  margin-right: 5%;
}

.danspan002 {
  background-color: #fb8b44;
}

.danspan003 {
  background-color: #d8c92b;
}

.danspan004 {
  background-color: #128479;
}

.danspan02 {
  font-size: 0.8rem;
  color: #fff;
  margin-right: 5%;
  display: inline-block;
}

.righttable {
  padding-top: 1%;
  padding-bottom: 1%;
  border-bottom: 0.05rem solid grey;
}

.el-progress {
  display: inline-block;
}

.proc {
  width: 13rem;
  // margin-left: 5%;
}

.proc ::v-deep .el-progress-bar ::v-deep .el-progress__text {
  margin-left: 30% !important;
}

.proc ::v-deep .el-progress-bar__inner {
  background-image: linear-gradient(to right, #8f2004, #ff3300) !important;
}

.proc02 ::v-deep .el-progress-bar__inner {
  background-image: linear-gradient(to right, #d27936, #da855f) !important;
}

.proc03 ::v-deep .el-progress-bar__inner {
  background-image: linear-gradient(to right, #a7a138, #f5cd53) !important;
}

.proc08 ::v-deep .el-progress-bar__inner,
.proc07 ::v-deep .el-progress-bar__inner,
.proc06 ::v-deep .el-progress-bar__inner,
.proc05 ::v-deep .el-progress-bar__inner,
.proc04 ::v-deep .el-progress-bar__inner {
  background-image: linear-gradient(to right, #1b7a8d, #39aab0) !important;
}

.proc ::v-deep .el-progress__text {
  display: none !important;
}

.dan139 {
  font-size: 0.8rem;
  color: #fff;
}

.danspan01 {
  margin-left: 2%;
}

.center0302 {
  height: 46%;
  margin-top: 2%;
  background-color: #0b1d29;
}

.right188 {
  width: 32.3rem;
  height: 11.6rem;
  display: flex;
}

.right190 div {
  background-image: url(../../assets/121901.png);
  width: 80%;
  height: 25%;
  margin-top: 0%;
  background-size: cover;
  position: relative;
}

.right190 {
  height: 100%;
  width: 80%;
  background-color: #0b1d29;
  padding-block: 0.625rem;
  margin-top: 1%;
}

.rightspan01 {

  font-size: 0.8rem;
  position: absolute;
  color: #fff;
  left: 0.93rem;
  top: 0.81rem;
}

.rightspan02 {
  font-size: 0.8rem;
  position: absolute;
  color: #fff;
  left: 4.06rem;
  top: 0.8rem;
}

.rightspan03 {
  font-size: 1.2rem;
  position: absolute;
  color: #3996a7;
  left: 11.8rem;
  top: 0.68rem;

  font-weight: 600;
}

.end {
  width: 100%;
  height: 23.8%;
  background-color: #081a26;
  display: flex;
}

.end01 {
  width: 72.2%;
  height: 100%;
  background-color: #091b27;
}

.end02 {
  width: 27%;
  height: 100%;
}

.end0101 {
  width: 100%;
  height: 22%;
  background-image: url(../../assets/121903.png);
}

.fdqs {
  color: #fff;
  font-weight: 600;
  margin-top: 0.93rem;
  display: inline-block;
  margin-left: 3%;
}

.end0102 {
  height: 77%;
  margin-top: 0.5%;
  width: 100%;
  background-color: #122835;
}

.endfirst {
  display: flex;
  width: 98%;
  height: 40%;
  padding-left: 1%;
  margin-right: 1%;
  background-color: #081a26;
  justify-content: space-between;
}

.endfirst>div {
  width: 48%;
  height: 90%;
  background-color: red;
  margin-top: 1%;
  background-image: url(../../assets/121905.png);
  background-size: cover;
  position: relative;
  text-align: center;
  border-radius: 0.25rem;
}

.spand01 {
  color: #37dabf;
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
  margin-top: 5%;
}

.spand02 {
  font-size: 0.8rem;
  color: #fff;
}

.con {
  overflow-y: hidden;
}

.span02 span,
.span01 span {
  color: #fff;
  font-size: 1rem;
  text-align: center !important;
  font-weight: 600;
  // margin-left: 10%;
  position: relative;
  top: 0.43rem;
  left: 0.18rem;
}

.span02 {
  margin-top: -1.25rem !important;
}

::v-deep .el-progress-bar__outer {
  height: 0.3rem;
  border-radius: 6rem;
}

::v-deep .element.style {
  width: 13rem;
  height: 18rem;
}
</style>