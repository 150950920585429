<template>
  <div id="con"></div>
</template>

<script>
import * as echarts from "echarts";
import instance from '@/request/request';

export default {
  name: "RightTable",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    dateData:{
      type:Array
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById("con"));
      this.updateChart();
    },
    updateChart() {
      if (!this.chart) return;

      const option = {
        backgroundColor: "#1c2431",
        color: ["#fcc550", "#4fd7fd"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
        },
        legend: {
          textStyle: {
            fontSize: "12",
            color: "#fff",
          },
        top:10
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateData,
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "平均电压(V)",
            smooth: true,
            data: this.value,
            type: "line",
            areaStyle: {},
          },
        ],
      };
      this.chart.setOption(option);
      window.addEventListener('resize', () => {
        this.chart.resize();
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.updateChart();
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    console.log(this.value,777);
    this.initChart();
    // this.getData(); // Uncomment if you want to fetch data on mount
  },
  beforeDestroy() {
    if (this.chart) {
      window.removeEventListener('resize', this.chart.resize);
      this.chart.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
#con {
  width: 80rem;
  height: 370px;
}
</style>
