<template>
  <div>
    <a-spin :spinning="loading" tip="加载中...">
      <a-table
        v-if="!loading"
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: 15 }"
        headerBg="#fafafa"
        :bordered="false"
        align="center"
        :customRow="handleRowClick"
        style="width: 100%"
      >
        <template v-slot:no-data>
          <div class="no-data-text">没数据</div>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { Table, Spin } from "ant-design-vue";
// import Vue from 'vue'
// Vue.config.productionTip = false
// Vue.use(Table)
// import 'ant-design-vue/dist/antd.css';
import instance from "@/request/request";
export default {
  components: {
    "a-table": Table,
    "a-spin": Spin,
  },
  props: ["date"],
  data() {
    return {
      loading: false,
      columns: [
        {
          title: "名称",
          dataIndex: "name", // 更新这里
          customHeaderClass: "name-header",
          key: "name",
        },
        {
          title: "站号",
          dataIndex: "stationId", // 更新这里
          customHeaderClass: "id-header",
        },
        {
          title: "A相电流",
          dataIndex: "acurrent", // 更新这里
          customHeaderClass: "current-header",
        },
        {
          title: "B相电流",
          dataIndex: "bcurrent", // 更新这里
          customHeaderClass: "current-header",
        },
        {
          title: "C相电流",
          dataIndex: "ccurrent", // 更新这里
          customHeaderClass: "current-header",
        },
        {
          title: "A相电压",
          dataIndex: "avoltage", // 更新这里
          customHeaderClass: "voltage-header",
        },
        {
          title: "B相电压",
          dataIndex: "bvoltage", // 更新这里
          customHeaderClass: "voltage-header",
        },
        {
          title: "C相电压",
          dataIndex: "cvoltage", // 更新这里
          customHeaderClass: "voltage-header",
        },
        {
          title: "A相功率",
          dataIndex: "aactivePower",
          customHeaderClass: "power-header",
        },
        {
          title: "B相功率",
          dataIndex: "bactivePower",
          customHeaderClass: "power-header",
        },
        {
          title: "C相功率",
          dataIndex: "cactivePower",
          customHeaderClass: "power-header",
        },
        {
          title: "用电量",
          dataIndex: "dian",
          customHeaderClass: "power-header",
        },
      ],
      data: [],
    };
  },
  watch: {
    date: {
      handler(newVal) {

        this.fetchData(newVal);
      },
    },
  },
  mounted() {
    this.fetchData(this.date);
    this.$on("date-change", this.fetchData);
  },
  methods: {
    // handleRowClick() {
    //   console.log(8888888);
    // },
    handleRowClick(record, index) {
      return {
        on: {
          click: () => {
            // console.log("点击了我");
            this.$router.push("/shebeiDetail");
          },

          // dblclick: () => {
          //   console.log("双击了我");
          // },

          // ...
        },
      };
    },
    async fetchData(date) {
      try {
        const response = await instance({
          url: "/ammeter/getPowerData",
          params: {
            stationId: this.$route.query.stationId,
            time: date.join(";"),
          },
        });
        this.data = response.data.content;
        console.log(response.data.content);
        this.loading = false;
        if (this.data.length === 0) {
          this.loading = false;
          const placeholder = this.$el.querySelector(".ant-table-placeholder");
          if (placeholder) {
            placeholder.style.backgroundColor = "#3e5368";
          }
          // 设置 .ant-empty-description 中的文本内容为 "暂无数据"
          const emptyDescription = this.$el.querySelector(
            ".ant-empty-description"
          );
          if (emptyDescription) {
            emptyDescription.textContent = "暂无数据";
          }
        }
      } catch (error) {
        console.error("获取数据时发生错误:", error);
      }
    },
  },
};
</script>

<style>
@import "ant-design-vue/dist/antd.css";

/* 修改表格中所有文字颜色为白色 */
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  color: white;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  width: 10vw !important;
}
/* 修改表头背景色为黑色 */
.ant-table-thead > tr > th {
  background-color: #88a6ed;
}

/* 修改鼠标悬停时的文字颜色为灰色 */
.ant-table-tbody > tr:hover > td,
.ant-table-tbody > tr:hover > td a {
  color: grey;
}

/* 设置表格宽度 */
.ant-table {
  table-layout: fixed;
  width: 90vw;
}

/* 设置每列的宽度 */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  width: 10%;
  /* 你可以根据需要调整每列的百分比宽度 */
  box-sizing: border-box;
}
.no-data-text {
  text-align: center;
  padding: 20px;
  color: #999;
}

/* 创建闪烁的动画 */
@keyframes blink {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

/* 应用动画到表格 */
.ant-table {
  animation: blink 15s infinite;
}
.ant-empty-description {
  color: #fff !important;
}
</style>