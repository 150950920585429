function formatDates(dates) {
    return dates.map(date => {
        // 将日期对象转换为 'YYYY-MM-DD' 格式的字符串
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    });
}

export default formatDates
