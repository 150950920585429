<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="demo-form-inline">
      <el-form-item label="点位名称" style="width: 80%" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确认添加</el-button>
        <el-button @click="canceled">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AddPlace",
  props: {
    equipVal: {
      require: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入点位名称", trigger: "blur" },
          { validator: this.checkName, trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    onSubmit() {
        this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证通过，提交表单数据
          this.$emit("valueKey", this.form.name);
        this.form.name = "";
        } else {
          // 表单验证失败，不提交表单数据
          console.log("表单验证失败");
        }
      });
    },
    canceled() {
      this.$emit("cancel");
      this.form.name = "";
    },
    checkName(rule, value, callback) {
      // 自定义校验函数，检查输入的值是否与 equipVal 数组中的任何值相同
      if (this.equipVal.some((item) => item.name === value)) {
        callback(new Error("点位名称已存在，请重新输入"));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
