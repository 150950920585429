<template>
    <div class="register-container">
        <img src="../assets/hualai.png" alt="" style="width: 25rem" />
        <el-form class="register-form" :model="form" :rules="rules" ref="registerForm">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="relaname">
                <el-input v-model="form.relaname"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <!-- <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </el-form-item> -->
            
            <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="form.confirmPassword"></el-input>
            </el-form-item>
            <!-- <el-form-item label="职务" prop="zhiwu">
                <el-input v-model="form.zhiwu"></el-input>
            </el-form-item> -->

            <el-form-item>
                <el-button class="register-form-register" type="primary" @click="submitForm">注册</el-button>
            </el-form-item>
            <!-- <el-form-item label="工作区域" prop="quyu">
                <el-input v-model="form.quyu"></el-input>
            </el-form-item> -->
        </el-form>
    </div>
</template>
  
<script>
import Vue from 'vue'
import { Notification } from 'element-ui';
import axios from 'axios';
import {Message} from 'element-ui'
import instance from '@/request/request'
Vue.prototype.$message = Message
export default {
    name: 'Register',
    data() {
        return {
            form: {
                username: '',
                password: '',
                confirmPassword: '',
                relaname: '',
                phone:'',
                zhiwu:'',
                quyu:'',
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { pattern: /^[a-zA-Z0-9]+$/, message: '用户名只能包含字母和数字', trigger: 'blur' }
                ],
                relaname: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { pattern: /^[\u4e00-\u9fa5]{2,4}$/, message: '请输入正确的真实姓名', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^\d{11}$/, message: '手机号格式错误', trigger: 'blur' }
                ],
                zhiwu: [
                    { required: true, message: '请输入职务', trigger: 'blur' },
                    { pattern: /^[\u4e00-\u9fa50-9]+$/, message: '请输入正确的真实职务', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/, message: '密码必须包含字母和数字，并且长度不能少于六位数', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { validator: this.validateConfirmPassword, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        submitForm() {
            this.$refs.registerForm.validate( async(valid) => {
                if (valid) {
                    // 表单验证通过，可以在这里提交注册逻辑
                   
                    instance.post('/user/register', {
                        username: this.form.username,
                        password: this.form.confirmPassword,
                        realName: this.form.relaname,
                        // phone: this.form.phone,
                        // zhiwu: this.form.zhiwu,
                        // quyu: this.form.quyu
                    })
                        .then(response => {
                           if(response.data.success){
                            this.$router.push('/login');
                            this.$message.success('注册成功');
                           }else{

                               this.showNotification();
                           }
                        })
                        .catch(error => {
                            console.log(666);
                        });
                } else {
                    this.showNotification();
                    return false;
                }
            });
        },
        validateConfirmPassword(rule, value, callback) {
            if (value !== this.form.password) {
                callback(new Error('两次输入的密码不一致'));
            } else {
                callback();
            }
        },
        showNotification() {
            Notification({
                title: '提示',
                message: '注册信息有误或已注册，注册失败',
                type: 'error',
                duration: 3000, // 显示时间，单位为毫秒
                position: 'top-left',
            });
        },
    }
};
</script>

<style lang="less" scoped>
.register-container {
    // background: url(../assets/切图/5f4f1171fcce5fb02080b27c8785355.png) no-repeat;
    // background-position: 50% -200%;
    width: 25rem;
    height: 25rem;
    margin: 0 auto;
    padding: 2.5rem;
    border: .0625rem solid #ccc;
    border-radius: .25rem;
    background-color: #ccccccc0;

    .register-form {
        margin-top: 5rem;
        position: relative;
        word-wrap: break-word;

        /* 添加自动换行 */
        .el-form-item {
            margin-left: 5%;
            float: left;
            width: 40%;
        }

        .register-form-register {
            position: absolute;
            left: 18rem;
            // bottom: -9rem;
        }
    }

}
::v-deep .el-switch__label * {
  font-size: 0.8rem !important;
}
::v-deep .el-form-item__content {
  font-size: 0.8rem !important;
  line-height: 2.5rem !important;
}
::v-deep .el-form-item__label {
  font-size: 0.8rem !important;
  line-height: 2.5rem !important;
}
::v-deep .el-form-item {
  margin-bottom: 1rem;
}
::v-deep .el-input__inner {
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.8rem !important;
  // width: 60%;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-button {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}
::v-deep .el-switch {
  line-height: 1.25rem;
  height: 1.25rem;
  font-size: 0.8rem;
}
::v-deep .el-switch__core {
  line-height: 2rem;
  // height: 2rem;
}
::v-deep .el-switch__label--right {
  margin-left: 0.6rem;
}
</style>