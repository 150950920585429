<template>
  <div
    class="home"
    v-loading="loading"
    element-loading-text="loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <button @click="onBtn">666</button> -->
    <el-dialog title="请选择设备" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="下拉选择设备">
          <el-select
            placeholder="请选择设备"
            v-model="selectId"
            @change="onChange"
          >
            <el-option
              v-for="item in eqData"
              :key="item.stationId"
              :label="item.name"
              :value="item.stationId"
            >
              {{ item.name }}【{{ item.message == "true" ? "在线" : "离线" }}】
              <!-- <div :class="eqData.message == 'true' ? 'green' : 'red'" style="display: flex"></div> -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="按照站号搜索">
          <div class="input">
            <el-input
              style="display: flex"
              placeholder="请输入站号"
              v-model="inputId"
              @change="onInput"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>

      <!-- <div style="display: flex;"></div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <div class="header">
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span style="font-size: 1.2rem; margin-top: 1rem">{{
              typeData.equipmentName
            }}</span>
          </div>
          <div class="zj"></div>
        </div>
        <div>
          <span
            style="
              color: aliceblue;
              position: absolute;
              right: 1.1rem;
              cursor: pointer;
              bottom: 0.1rem;
            "
            >选择设备:</span
          >

          <i
            class="el-icon-setting"
            style="
              color: red;
              position: absolute;
              right: 0.1rem;
              cursor: pointer;
              bottom: 0.2rem;
            "
            @click="setUp"
          ></i>
        </div>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData.voltage }}</span>
            <span style="font-size: 1.2rem">( V )</span>
            <img
              style="width: 1.2rem; position: absolute; top: 10px; right: 10px"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">实时电压</div>
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="type03 && typeData03 != 0"
        >
          <i
            class="el-icon-top"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: red;
            "
          ></i
          >{{ typeData03.toFixed(2) }}
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="!type03 && typeData03 != 0"
        >
          <i
            class="el-icon-bottom"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: #67c23a;
            "
          ></i
          >{{ typeData03.toFixed(2) }}
        </div>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData.power }}</span>
            <span style="font-size: 1.2rem">( W )</span>
            <img
              style="width: 1.2rem; position: absolute; top: 10px; right: 10px"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">实时功率</div>
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="type05 && typeData05 != 0"
        >
          <i
            class="el-icon-top"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: red;
            "
          ></i
          >{{ typeData05.toFixed(2) }}
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="!type05 && typeData05 != 0"
        >
          <i
            class="el-icon-bottom"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: #67c23a;
            "
          ></i
          >{{ typeData05.toFixed(2) }}
        </div>
      </div>
      <div class="header01" style="position: relative">
        <div class="kk">
          <div style="display: flex; align-items: center">
            <span class="kwp">{{ typeData.powerFactor }}</span>
            <span style="font-size: 1.2rem">( % )</span>
            <img
              style="width: 1.2rem; position: absolute; top: 10px; right: 10px"
              src="../../assets//输电.png"
              alt=""
            />
          </div>
          <div class="zj">实时功率因数</div>
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="type04 && typeData04 != 0"
        >
          <i
            class="el-icon-top"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: red;
            "
          ></i
          >{{ typeData04.toFixed(2) }}
        </div>
        <div
          style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
          v-if="!type04 && typeData04 != 0"
        >
          <i
            class="el-icon-bottom"
            style="
              font-size: 0.8rem;
              line-height: 0.8rem;
              margin-left: 1rem;
              color: #67c23a;
            "
          ></i
          >{{ typeData04.toFixed(2) }}
        </div>
      </div>

      <div class="header05">
        <div class="dxj">日期</div>
        <div class="zdx" style="font-size: 1.2rem">{{ date01 }}</div>
      </div>
      <div class="header06">
        <div class="jing">
          <img src="../../assets/正确.png" alt="" v-if="lx" />
          <img src="../../assets/警告.png" alt="" v-else />
          <div :class="lx ? 'zc' : 'jing01'">{{ lx ? "在线" : "离线" }}</div>
          <div class="jing02">在线状态</div>
        </div>
      </div>
      <div class="header07">
        <div class="jing">
          <img src="../../assets/正确.png" alt="" />
          <div class="zc">{{ zaixian }}</div>
          <div class="jing02">在线设备数量</div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="center01">
        <div class="center0101">
          <div>
            <div>
              <span class="fadian">实时电流( A )</span>
            </div>
            <div class="lefttext">{{ typeData.current }}</div>
          </div>
          <div
            style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
            v-if="type02 && typeData02 != 0"
          >
            <i
              class="el-icon-top"
              style="
                font-size: 0.8rem;
                line-height: 0.8rem;
                margin-left: 1rem;
                color: red;
              "
            ></i
            >{{ typeData02.toFixed(2) }}
          </div>
          <div
            style="position: absolute; top: 50%; right: 1rem; color: aliceblue"
            v-if="!type02 && typeData02 != 0"
          >
            <i
              class="el-icon-bottom"
              style="
                font-size: 0.8rem;
                line-height: 0.8rem;
                margin-left: 1rem;
                color: #67c23a;
              "
            ></i
            >{{ typeData02.toFixed(2) }}
          </div>
        </div>
        <div class="center0101">
          <div>
            <div>
              <span class="fadian">当日最大功率( W )</span>
            </div>
            <div class="lefttext">{{ typeData.highestPower }}</div>
          </div>
        </div>
        <div class="center0101">
          <div>
            <div>
              <span class="fadian">总用电量( KW/h )</span>
            </div>
            <div class="lefttext">{{ typeData.energy }}</div>
            <div class="lefttext01"></div>
          </div>
        </div>
      </div>
      <div class="center02">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2rem; height: 2rem"
          />
          <span style="font-size: 1rem">设备用电量统计</span>
        </div>
        <div ref="chart" style="width: 106%; height: 90%"></div>
      </div>
      <div
        class="center03"
        v-loading="loading01"
        element-loading-text="loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <!-- <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 2rem; height: 2rem"
          />
          <span style="font-size: 1rem">用电数据趋势</span>
        </div> -->
        <div class="rightbig01">
          <!-- **************************************************gz********************************************************************* -->
          <div
            style="
              display: flex;
              align-items: center;
              background-color: #222e3c;
              border-radius: 0 !important;
              width: 120%;
              height: 10%;
            "
          >
            <span style="color: white; font-size: 0.8rem">查询时间</span>
            <div class="content" style="display: flex">
              <el-radio-group
                v-model="radio3"
                style="margin-left: 1.5vw; margin-right: 1.5vw"
                size="mini"
              >
                <el-radio-button label="日"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
                <el-radio-button label="年"></el-radio-button>
                <!-- <el-radio-button label="自定义"></el-radio-button> -->
              </el-radio-group>

              <el-date-picker
                v-model="date"
                type="date"
                placeholder="选择日"
                v-if="radio3 === '日'"
                size="mini"
                key="day"
              >
              </el-date-picker>
              <!-- <el-button v-popover:pickerVisible>选择日</el-button> -->

              <el-date-picker
                v-model="date"
                type="month"
                placeholder="选择月"
                v-if="radio3 === '月'"
                size="mini"
                key="month"
              >
              </el-date-picker>
              <!-- <el-button v-popover:pickerVisible>选择月</el-button> -->

              <el-date-picker
                v-model="date"
                type="year"
                placeholder="选择年"
                v-if="radio3 === '年'"
                size="mini"
                key="year"
              >
              </el-date-picker>
              <!-- <el-button v-popover:pickerVisible>选择年</el-button> -->
            </div>
            <el-button
              size="mini"
              type="primary"
              style="margin-left: 1vw"
              @click="onSearch"
              >查询</el-button
            >
            <!-- <el-button size="small" type="primary" style="margin-left: 1vw"
          >深度分析</el-button
        > -->
          </div>
          <!-- gz********************************************************************************************************************* -->
          <el-tabs
            type="border-card"
            id="el01"
            @tab-click="handleTabClick"
            v-model="activeTab"
            style="width: 122%; height: 90%; margin-top: 0.15rem"
          >
            <el-tab-pane label="电流" name="current">
              <div class="dian00">
                <RightTable
                  :value="value"
                  :dateData="dateData"
                  style="width: 40rem"
                ></RightTable>
              </div>
            </el-tab-pane>
            <el-tab-pane label="电压" name="voltage">
              <div class="dian00">
                <RightTwo
                  :value="value"
                  :dateData="dateData"
                  style="width: 40rem"
                ></RightTwo>
              </div>
            </el-tab-pane>
            <el-tab-pane label="功率" name="power">
              <div class="dian00">
                <RightThree
                  :value="value"
                  :dateData="dateData"
                  style="width: 40rem"
                ></RightThree>
              </div>
            </el-tab-pane>

            <el-tab-pane label="功率因数" name="powerFactor">
              <div class="dian00">
                <RightFour
                  :value="value"
                  :dateData="dateData"
                  style="width: 40rem"
                ></RightFour>
              </div>
            </el-tab-pane>
            <el-tab-pane label="电度" name="energy">
              <div class="dian00">
                <RightFive
                  :value="value"
                  :dateData="dateData"
                  style="width: 40rem"
                ></RightFive>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="foot01">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 1.6rem; height: 1.6rem"
          />
          <span style="font-size: 1rem">设备状态统计</span>
          <!-- <span class="chakan"><a href="">查看详情</a></span> -->
        </div>
        <div class="foot0102">
          <div id="">
            <div class="botttonleft">
              <div>
                <span class="span03">设备在线率</span>
              </div>
            </div>
            <FootLeft :onlineRate="leftData.onlineRate"></FootLeft>
          </div>
          <!-- <div>
            <div class="botttonleft">
              <div>
                <span class="span03">上次离线时间</span>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5rem;
              "
            >
              <span style="color: #1da4fe; font-size: 1rem">{{
                leftData.lastOffLineTime
              }}</span>
            </div>
          </div> -->
          <div>
            <div class="botttonleft">
              <div>
                <span class="span03">总用电量对比</span>
              </div>
            </div>
            <FootRight
              :yesterdayTotalEnergy="leftData.yesterdayTotalEnergy"
              :todayTotalEnergy="leftData.todayTotalEnergy"
            ></FootRight>
          </div>
        </div>
      </div>
      <div class="foot02">
        <div class="center0201">
          <img
            src="../../assets/xin.png"
            alt=""
            style="width: 1.6rem; height: 1.6rem"
          />
          <span style="font-size: 1rem">设备基本信息</span>
        </div>
        <div class="mess">
          <div>
            <span>站点名称:</span>
            <span>{{ equipment.name }}</span>
          </div>
          <div>
            <span>详细线路:</span>
            <span>{{ equipment.line }}</span>
          </div>
          <div>
            <span>变比值:</span>&nbsp;&nbsp;&nbsp;
            <span>{{ equipment.transformationRatio }}</span>
          </div>
          <div>
            <span>上次离线:</span>
            <span>{{ leftData.lastOffLineTime }}</span>
          </div>
          <div>
            <span>投运时间:</span>
            <span>{{ equipment.useTime }}</span>
          </div>
          <!-- <div>
              <span>运行时间:</span>
              <span>{{runningTime}}</span>
            </div> -->
        </div>
      </div>
      <div class="foot03">
        <div class="rightbottom">
          <div style="position: relative">
            <i
              class="el-icon-aim"
              style="
                width: 4.5rem;
                height: 4.5rem;
                font-size: 5rem;
                color: #1da4fe;
              "
            ></i>

            <div
              class="hj"
              style="position: absolute; top: 2.4rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">环境温度:</span>
              <div style="font-size: 1rem">21.1℃</div>
            </div>
          </div>
          <div style="position: relative">
            <!-- <img
              src="../../assets/12801.png"
              alt=""
              style="width: 4.5rem; height: 4.5rem"
            /> -->
            <i
              class="el-icon-help"
              style="
                width: 4.5rem;
                height: 4.5rem;
                font-size: 5rem;
                color: #1da4fe;
              "
            ></i>

            <div
              class="hj"
              style="position: absolute; top: 2.4rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">A相电网频率:</span>
              <div style="font-size: 1rem">{{ typeData.agridFrequency }}</div>
            </div>
          </div>
        </div>
        <!-- -----------------------分界线------------- -->
        <div class="rightbottom">
          <div style="position: relative">
            <i
              class="el-icon-circle-plus-outline"
              style="
                width: 4.5rem;
                height: 4.5rem;
                font-size: 5rem;
                color: #1da4fe;
              "
            ></i>

            <div
              class="hj"
              style="position: absolute; top: 2.4rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">B相电网频率:</span>
              <div style="font-size: 1rem">{{ typeData.bgridFrequency }}</div>
            </div>
          </div>
          <div style="position: relative">
            <i
              class="el-icon-remove-outline"
              style="
                width: 4.5rem;
                height: 4.5rem;
                font-size: 5rem;
                color: #1da4fe;
              "
            ></i>

            <div
              class="hj"
              style="position: absolute; top: 2.4rem; left: 8.1rem"
            >
              <span style="font-size: 1rem">C相电网频率:</span>
              <div style="font-size: 1rem">{{ typeData.cgridFrequency }}</div>
            </div>
          </div>
        </div>
        <!-- +++++++++++++++++++++++++++++++++++++ -->
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import { mapState, mapMutations } from "vuex";
import Cookie from "js-cookie";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import LeftTable from "../shouye/components/main01.vue";
import FootLeft from "../shouye/components/progress.vue";
import FootRight from "../shouye/components/progress02.vue";
import RightEight from "./components/RightEight.vue";
import RightFive from "./components/RightFive.vue";
import RightFour from "./components/RightFour.vue";
import RightSeven from "./components/RightSeven.vue";
import RightSix from "./components/RightSix.vue";
import RightTable from "./components/RightTable.vue";
import RightThree from "./components/RightThree.vue";
import RightTwo from "./components/RightTwo.vue";
import axios from "axios";
import instance from "@/request/request";
import { nextTick } from "vue";

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};
export default {
  // name: "HomeView",
  components: {
    LeftTable,
    FootLeft,
    FootRight,
    RightTable,
    RightTwo,
    RightThree,
    RightFour,
    RightFive,
    RightSix,
    RightSeven,
    RightEight,
  },
  data() {
    return {
      typeData02: 0,
      typeData03: 0,
      typeData05: 0,
      typeData04: 0,
      type03: null,
      type04: null,
      type05: null,
      type02: null,
      loading: true,
      loading01: true,
      date01: null,
      selectId: null,
      inputId: null,
      valueData: [],
      todayEnergy: [],
      yesterdayEnergy: [],
      pickerVisible: true,
      transformationRatio: "",
      energy: 0,
      type: 1,
      activeTab: "current",
      value: [],
      dateData: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00",
      ],
      date: "2024-06-27",
      radio3: "日",
      tabName: "current",
      stationId: 11,
      boxId: "",
      typeData: {},
      dialogFormVisible: false,
      lineData: [],
      currentDate: "",
      statusInterval: null,
      zx: [],
      succ: [],
      lx: [],
      fal: "",
      zaixian: "",
      zong: "",
      cgx: [],
      cgx02: [],
      cgx03: [],
      mincgx: "",
      maxcgx: "",
      date01: [],
      date02: [],
      date03: [],
      date04: [],
      date05: [],
      lineChartData: lineChartData.newVisitis,
      username: "",
      data: [1, 2],
      eqData: [],
      totalData: [],
      leftData: {},
    };
  },
  created() {
    this.username = Cookie.get("username01");
    this.updateMenu(this.username);
  },
  methods: {
    onChange() {
      this.inputId = null;
    },
    onInput() {
      this.selectId = null;
    },
    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");

      this.date = `${year}-${month}-${day}`;
      this.date01 = `${year}-${month}-${day}`;
    },
    async onSearch() {
      if (this.radio3 == "日") {
        console.log(this.date);

        this.type = 1;
        this.date = this.formatDate(new Date(this.date));
        this.getDayData();
      } else if (this.radio3 == "月") {
        this.type = 2;
        this.date = this.formatDate(new Date(this.date));
        setTimeout(() => {
          this.getDayData();
        }, 0);
      } else if (this.radio3 == "年") {
        this.type = 3;
        this.date = this.formatDate(new Date(this.date));
        setTimeout(() => {
          this.getDayData();
        }, 0);
      }
    },
    formatDate(date) {
      // 获取年份
      const year = date.getFullYear();

      // 获取月份，月份从0开始，所以要加1
      const month = String(date.getMonth() + 1).padStart(2, "0");

      // 获取日期
      const day = String(date.getDate()).padStart(2, "0");

      // 拼接成所需格式
      return `${year}-${month}-${day}`;
    },
    handleTabClick(tab) {
      this.tabName = tab.name;
      this.getDayData();
    },
    async getDayData() {
      this.loading01 = true;
      if (this.radio3 == "日") {
        console.log(this.date);

        this.type = 1;
        this.date = this.formatDate(new Date(this.date));
      } else if (this.radio3 == "月") {
        this.type = 2;
        this.date = this.formatDate(new Date(this.date));
      } else if (this.radio3 == "年") {
        this.type = 3;
        this.date = this.formatDate(new Date(this.date));
      }
      let res;
      try {
        res = await instance({
          url: "/ammeter/getDataByStationIdAndTypeAndTime",
          params: {
            time: this.date,
            stationId: this.stationId,
            type: this.type,
            funType: this.tabName,
          },
        });
      } catch (error) {
        this.$message.error("该设备暂无数据");
      }

      console.log(res.data.content.data, 789);
      this.valueData = res.data.content.data;
      this.value = res.data.content.data.map((item) => Object.values(item)[0]);
      if (this.radio3 == "日") {
        // this.value.pop();
        this.dateData = [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "24:00",
        ];
      } else if (this.radio3 == "月") {
        this.valueData.pop();
        this.dateData = this.valueData.map(
          (entry) => Object.keys(entry)[0].split(" ")[0]
        );
        console.log(this.dateData);
      } else if (this.radio3 == "年") {
        this.valueData.pop();
        this.dateData = this.valueData.map((entry) => {
          const date = Object.keys(entry)[0];
          return date.substring(0, 7);
        });
        console.log(this.dateData);
      }
      this.loading01 = false;
    },
    initChart(yesterdayEnergy, todayEnergy) {
      const chartContainer = this.$refs.chart;
      console.log(yesterdayEnergy, 66);

      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      let legendData = ["今日", "昨日"];
      let xAxisData = [
        "0时",
        "1时",
        "2时",
        "3时",
        "4时",
        "5时",
        "6时",
        "7时",
        "8时",
        "9时",
        "10时",
        "11时",
        "12时",
        "13时",
        "14时",
        "15时",
        "16时",
        "17时",
        "18时",
        "19时",
        "20时",
        "21时",
        "22时",
        "23时",
        "24时",
      ];
      let seriesData = todayEnergy;
      let seriesData1 = yesterdayEnergy;
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "30%",
          left: "2%",
          right: "10%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          icon: "rect",
          right: "center",
          top: "2%",
          itemWidth: 7, // 设置宽度
          itemHeight: 7, // 设置高度
          itemGap: 15, // 设置间距
          textStyle: {
            //图例文字的样式
            color: "#89BFE5",
            fontSize: 12,
          },
        },
        xAxis: {
          boundaryGap: true,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "rgba(117, 168, 202, 0.4)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
        },
        yAxis: [
          {
            // name: '单位：个',
            // type: "value",
            inverse: false,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(117, 168, 202, 0.3)",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#0A5C95",
              },
            },
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#89BFE5",
                fontSize: 12,
              },
            },
            nameTextStyle: {
              color: "#89BFE5",
              padding: [0, 0, 0, 15],
              fontSize: 12,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: legendData[0],
            type: "line",
            symbol: "circle", //设定为实心点
            showAllSymbol: true,
            symbolSize: 5,
            smooth: true,
            itemStyle: {
              normal: {
                color: `#2BDFD4`,
                lineStyle: {
                  //线的颜色
                  color: `#2BDFD4`,
                  width: 1.5,
                },
                areaStyle: {
                  //type: 'default',
                  //旧版渐变色实现
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                  //    //三种由深及浅的颜色
                  //    [{
                  //       offset: 0,
                  //       color: 'rgba(1, 180, 255, 0.2)'
                  //    }, {
                  //       offset: 1,
                  //       color: 'rgba(1, 180, 255, 0.1)'
                  //    }])

                  //新版渐变色实现
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(1, 180, 255, 0.2)",
                      },
                      {
                        offset: 1,
                        color: "rgba(1, 180, 255, 0.1)",
                      },
                    ],
                  },
                },
              },
            },
            data: seriesData,
          },
          {
            name: legendData[1],
            type: "line",
            symbol: "circle", //设定为实心点
            showAllSymbol: true,
            symbolSize: 5,
            smooth: true,
            itemStyle: {
              normal: {
                color: `#FFD076`,
                lineStyle: {
                  //线的颜色
                  color: `#FFD076`,
                  width: 1.5,
                },
                areaStyle: {
                  //旧版渐变色实现
                  // type: 'default',
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                  //    //三种由深及浅的颜色
                  //    [{
                  //       offset: 0,
                  //       color: 'rgba(255, 223, 4, 0.2)'
                  //    }, {
                  //       offset: 1,
                  //       color: 'rgba(255, 223, 4, 0.1)'
                  //    }])
                  //新版渐变色实现
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(255, 223, 4, 0.2)",
                      },
                      {
                        offset: 1,
                        color: "rgba(255, 223, 4, 0.1)",
                      },
                    ],
                  },
                },
              },
            },
            data: seriesData1,
          },
        ],
      };
      // 使用 setOption 方法设置图表配置项
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
    async getTotal() {
      const res = await instance("/ammeter/getTotalData");
      this.totalData = res.data.content;
    },
    async getCenter() {
      this.loading = true;
      const res = await instance({
        url: "/home/getHomeDataByStationIdTuBiao",
        params: {
          stationId: this.stationId,
        },
      });
      const data = await instance({
        url: "/home/getHomeDataByStationIdYuan",
        params: {
          stationId: this.stationId,
        },
      });
      this.leftData = data.data.content;
      const yesterdayEnergy = res.data.content.yesterdayEnergy.map(
        (entry) => Object.values(entry)[0]
      );
      const todayEnergy = res.data.content.todayEnergy.map(
        (entry) => Object.values(entry)[0]
      );
      setTimeout(() => {
        this.initChart(yesterdayEnergy, todayEnergy);
        this.loading = false;
      }, 1000);
    },
    async getHome() {
      const res = await instance({
        url: "/home/getHomeDataByStationId",
        params: {
          stationId: this.stationId,
        },
      });
      this.typeData = res.data.content;
      this.equipment = res.data.content.equipment;
      this.typeData.powerFactor = (this.typeData.powerFactor * 100).toFixed(2);
    },

    async onSubmit() {
      this.loading = true;
      this.dialogFormVisible = false;
      if (this.inputId) {
        this.stationId = this.inputId;
      } else if (this.selectId) {
        this.stationId = this.selectId;
      }
      setTimeout(() => {
        this.getHome();
        this.getDayData();
        this.getCenter();
        this.getStatus()
      });
      this.loading = false;
    },
    async setUp(i) {
      this.loading = true;
      const res = await instance("/wx/getAllEquipment");
      this.eqData = res.data.content;
      this.dialogFormVisible = true;
      this.loading = false;
    },
    async web01() {
      const res = await instance({
        url: "/ammeter/getEquipmentCurrent",
      });
      // console.log(res.data[2], "eeeeeeeeeeeeee");
      if (!res.data.success) {
        this.$message.error(res.data.message);
        // this.$router.push('/shouye/home')
      } else {
        this.lineData = res.data.content.filter((x) => x.pid == null);
        console.log(
          res.data.content.filter((x) => x.pid == null),
          9999999999999999
        );
      }
    },
    getCurrentDate() {
      const date = new Date();
      this.currentDate = date.getDate();
    },
    async getStatus() {
      (this.zx = []), (this.succ = []), (this.lx = []);
      const res = await instance("/wx/getStatus");
      this.zx = res.data.content;
      // if (this.zx.length) {
      //   for (var g = 0; g < this.zx.length; g++) {
      //     if (this.zx[g].message == "true") {
      //       this.succ.push(this.zx[g].message);
      //     }
      //     if (this.zx[g].message != "true") {
      //       this.lx.push(this.zx[g].message);
      //     }
      //   }
      // }
      this.succ = res.data.content.filter((x) => x.message == "true");
      this.lx =
        res.data.content.filter(
          (x) => x.getPowerDataResp.stationId == this.stationId
        )[0].message == "true";

      this.zaixian = this.succ.length;
      this.zaixian = this.succ.length;
      this.zong = this.zaixian + this.fal;
      this.yunxing = ((this.zaixian / this.zong) * 100).toFixed(1);
      this.guzhang = ((this.fal / this.zong) * 100).toFixed(1);
    },
    jie() {
      instance
        .get("/ammeter/getPowerData")
        .then((res) => {
          // 处理请求成功的情况
          console.log(res.data.content, "fffffff");
          const contentArray = res.data.content;
          this.cgx02 = res.data.content;
          // 使用数组的 slice 方法来截取前四个元素
          const firstFourElements = contentArray.slice(0, 4);

          console.log(firstFourElements, "aaaaaaaasss");
          for (var x = 0; x < firstFourElements.length; x++) {
            this.cgx.push(firstFourElements[x].avoltage);
          }
          console.log(this.cgx, "cgxcgxcgxcg");
          console.log(this.cgx02, "xxxxxxxxx");
          for (var f = 0; f < this.cgx02.length; f++) {
            this.cgx03.push(this.cgx02[f].avoltage);
          }
          console.log(this.cgx03, "wwwwwwwwwwwwwwwwww");

          const minValue = Math.min(...this.cgx03);

          // 找出最大值
          const maxValue = Math.max(...this.cgx03);
          this.mincgx = minValue;
          this.maxcgx = maxValue;
          console.log("最小值:", minValue);
          console.log("最大值:", maxValue);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("发生错误:", error);
        });

      //--------------------------------------------------
      axios
        .get(
          "https://api.qweather.com/v7/weather/now?location=101010100&key=599a1304e29b4198840ff8ecb04be02c"
        )
        .then((ress) => {
          console.log(ress, "vvvvv");
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("发生错误:", error);
        });
    },
    async onBtn() {
      await axios("http://localhost:3000/api/hello");
    },
    ...mapMutations(["updateMenu"]),
    centertable() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        color: ["#3cc4dd", "#f0d51f"],
        legend: {
          bottom: 0,
          textStyle: {
            color: "#ffffff",
          },
        },
        // tooltip: {
        //   color: "#fff",
        //   width:100
        // },
        dataset: {
          source: [
            ["product", "昨天", "今天"],
            ["配电室一", 43.3, 85.8],
            ["配电室二", 83.1, 73.4],
            ["配电室三", 86.4, 65.2],
            ["配电室四", 72.4, 53.9],
          ],
        },
        xAxis: {
          type: "category",
          textStyle: {
            // 设置标签文字样式
            color: "#fff", // 标签文字颜色
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 x 轴线的颜色为白色
            },
          },
          lineStyle: {
            color: "#fff",
            type: "solid",
          },
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} KWh",
            textStyle: {
              fontSize: "0.8rem",
              color: "#fff", // 设置 y 轴文字颜色为白色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff", // 设置 y 轴线的颜色为白色
            },
          },
          textStyle: {
            color: "#fff", //坐标值得具体的颜色
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            itemStyle: {
              // 设置渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3cc4dd" }, // 0% 处的颜色
                { offset: 1, color: "#154e90" }, // 100% 处的颜色
              ]),
            },
          },
          {
            type: "bar",
            itemStyle: {
              // 设置渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#f0d51f" }, // 0% 处的颜色
                { offset: 1, color: "#e28606" }, // 100% 处的颜色
              ]),
            },
          },
        ],
      };

      option && myChart.setOption(option);
      for (let i = 1; i < option.dataset.source.length; i++) {
        for (let j = 1; j < option.dataset.source[i].length; j++) {
          option.dataset.source[i][j] = this.generateRandomNumber(40, 90); // 你可以根据需要调整范围
        }
        myChart.setOption(option);

        // 监听窗口大小变化，调整图表大小
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    generateRandomNumber(min, max) {
      return (Math.random() * (max - min) + min).toFixed(1); // 保留一位小数
    },

    webs() {
      const socket = new WebSocket("ws://localhost:8082/ws/1");

      // 连接建立事件
      socket.addEventListener("open", () => {
        console.log("WebSocket连接已建立");
      });

      // 消息接收事件
      socket.addEventListener("message", (event) => {
        const message = event.data;
        console.log("收到消息：", message);
        // 处理接收到的消息，将其显示在前端界面上
      });

      // 发送消息
      function sendMessage(message) {
        socket.send(message);
      }

      // 调用发送消息的函数，例如在点击按钮后发送消息
      const sendButton = document.getElementById("sendBtn");
      sendButton.addEventListener("click", () => {
        const messageInput = document.getElementById("messageInput");
        const message = messageInput.value;
        sendMessage(message);
        messageInput.value = ""; // 清空输入框
      });

      // 连接关闭事件
      socket.addEventListener("close", () => {
        console.log("WebSocket连接已断开");
      });

      // 连接错误事件
      socket.addEventListener("error", (error) => {
        console.error("发生错误：", error);
      });
    },
    handleScroll() {
      // 在滚动时执行的操作，例如刷新组件
      location.reload(); // 这会强制重新渲染组件
      // console.log(1323);
    },
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.statusInterval);
  },
  mounted() {
    this.getCurrentTime();
    this.getDayData();
    this.getCurrentDate();
    this.getCenter();
    this.getHome();

    // this.$nextTick(() => {
    //   // 获取 el-tabs 组件实例
    //   const tabs = this.$refs.el01;
    //   // 找到默认激活的选项卡信息
    //   const defaultTab = { label: "电流", name: "current" };
    //   // 手动触发点击事件
    //   this.handleTabClick(defaultTab);
    // });
    this.web01();
    this.getStatus();
    // setTimeout(()=>{
    //   this.loading = false
    // },2000)
    this.statusInterval = setInterval(() => {
      this.typeData01 = this.typeData;
      this.getHome();
      setTimeout(() => {
        this.typeData02 = this.typeData.current - this.typeData01.current;
        this.typeData03 = this.typeData.voltage - this.typeData01.voltage;
        this.typeData04 =
        this.typeData.powerFactor - this.typeData01.powerFactor;
        this.typeData05 = this.typeData.power - this.typeData01.power;
        
        this.type02 = this.typeData.current > this.typeData01.current;
        this.type03 = this.typeData.voltage > this.typeData01.voltage;
        this.type05 = this.typeData.power > this.typeData01.power;
        this.type04 = this.typeData.powerFactor > this.typeData01.powerFactor;
        this.getStatus()
        // console.log(
        //   this.type02,
        //   this.typeData02,
        //   this.type03,
        //   this.typeData03,
        //   this.type04,
        //   this.typeData04,
        //   this.type05,
        //   this.typeData05
        // );
        // console.log(this.type03 && this.typeData03 != 0);
        // console.log(!this.type03 && this.typeData03 != 0);
      }, 2000);
    }, 12000);
    this.centertable();
    this.jie();

    setInterval(() => {
      this.getCenter();
    }, 3600000);
  },
  destroyed() {
    // 在组件销毁时移除事件监听器，以防止内存泄漏
    // window.removeEventListener('resize', this.handleScroll);
  },
};
</script>
  <style lang="scss" scoped>
.input {
  display: flex;
  .el-input {
    display: flex;

    width: auto;
  }
  ::v-deep .el-input__inner {
    padding: 0 26px !important;
  }
}
.main04 {
  width: 10rem;
  height: 207px;
}
.el-tabs--border-card {
  border: none;
}
.home {
  height: 100vh !important;
  background-color: #666;
}

.rightbottom > div > img {
  margin-top: 10px;
  margin-left: 20px;
}

.hj span {
  font-size: 20px;
  color: #798593;
}

.hj div {
  font-size: 20px;
  color: #45bfe0;
  vertical-align: middle;
}

.rightbottom {
  display: flex;
  width: 100%;
  height: 7.5rem;
  background-color: #222e3c;
  margin-top: 5px;
}

.rightbottom > div {
  width: 49%;
  height: 8rem;
  box-shadow: 2px 2px 20px 2px rgb(117, 115, 115);
  border-radius: 5px;
  margin-left: 9px;
}

.mess {
  color: #fff;
  font-size: 1rem;
  height: 300px;
}

.mess > div {
  margin-top: 5px;
  width: 100%;
  // height: 50px;
  background-color: #222e3c;
}

.mess > div > span:nth-child(2) {
  margin-left: 20%;
}

.mess > div > span:nth-child(1) {
  margin-left: 10%;
}

.mess > div > span {
  display: inline-block;
  margin-top: 15px;
}

.botttonleft {
  text-align: center;
  background-color: #222e3c;
}

.botttonleft span {
  font-size: 28px;
  font-weight: 600;
}

.span01 {
  font-size: 1rem;
  color: #14c9c8;
}

.botttonleft .span03 {
  display: inline-block;
  font-size: 1rem;
}

.span02 {
  font-size: 1rem;
  color: #8596a5;
}

.foot0102 {
  width: 100%;
  height: 90%;
  background-color: #1d2031;
  justify-content: space-between;
  display: flex;
}

.foot0102 > div {
  width: 49%;
  background-color: #222f3d;
}

.chakan {
  margin-left: 50%;
}

.foot01,
.foot02,
.foot03 {
  height: 300px;

  background-color: #1d2031;
}
.foot03 i {
  line-height: 8rem;
  margin-left: 1rem;
}
.foot01 {
  width: 33%;
}

.foot02 {
  width: 31.8%;
  background-color: #232f3d;
}

.foot03 {
  width: 34%;
}

.foot {
  width: 100%;
  margin-top: 10px;
  background-color: #1d2031;
  display: flex;
  justify-content: space-between;
}

.center0201 > span {
  color: #fff;
  vertical-align: middle;
}

.center0201 {
  width: 100%;
  height: 10%;
  background-color: #2a3a4a;
}

#main01,
#main {
  height: 80%;
}

.lefttext01 {
  font-size: 1rem;
  padding-top: 10px;
  color: #848fa1;
  padding-left: 10px;
}

.lefttext {
  font-size: 1.5rem;
  color: #379bd9;
  font-weight: 600;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: 3px solid black;
}

.fadian {
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  margin-left: 0.6rem;
}

.jin {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url(../../assets/日历1.png) no-repeat;
  margin-left: 10rem;
  margin-top: 1.2rem;
  text-align: center;
}

.jin > span {
  margin-top: 0.6rem;
  display: inline-block;
  color: #fff;
}

.center0101 {
  width: 100%;
  height: 32.6%;
  border: 1.5px solid #1d2031;
  background-color: #222e3c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.center0102 {
  margin-top: 0.4rem;
  width: 100%;
  height: 33%;
  background-color: #222e3c;
}

.center01 {
  width: 15%;
  background-color: #1d2031;
  margin-right: 0.6rem;
}

.center02 {
  width: 44%;
  background-color: #222e3c;
  margin-right: 0.6rem;
}

.center03 {
  width: 40%;
  background-color: #222e3c;
}

.center {
  width: 100%;
  height: 440px;
  background-color: #1d2031;
  margin-top: 5px;
  display: flex;
}

.zc {
  color: #4fb58e;
}

.jing01 {
  color: #ca210b;
}

.jing02 {
  font-size: 0.8rem;
  margin-top: 10px;
}

.jing {
  text-align: center;
  color: #fff;
}

.jing img {
  width: 1.5rem;
  height: 1.5rem;
}

.dxj {
  font-size: 0.8rem;
  color: #fff;
  margin: 15px;
  margin-top: 25px;
}

.zdx {
  font-size: 0.6rem;
  color: #fff;
  font-size: 12px;
  color: #7c8ba0;
}

.header05 {
  text-align: center;
}

.header07 img,
.header06 img {
  margin-top: 15px;
}

.kk {
  color: #3aa5dc;
  margin: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.zj {
  font-size: 1rem;
  padding-top: 0.8rem;
  color: #7c8ba0;
}
.rightbig01 {
  width: 80%;
  border-radius: 0.5rem !important;
  height: 90%;
}
.header {
  height: 120px;
  background-color: #1d2031;
  display: flex;

  .header03,
  .header02,
  .header01,
  .header04 {
    width: 20%;
  }

  // .header03 {
  //   width: 20%;
  // }

  // .header04 {
  //   width: 22%;
  // }

  .header05,
  .header06,
  .header07 {
    width: 10%;
  }
}

.header > div {
  height: 100%;
  margin-right: 10px;
  background-color: #222e3c !important;
  border-radius: 5px;
}

.header07 {
  margin-right: 0 !important;
}

.kwp {
  color: #3aa5dc;
  font-size: 1.5rem;
}

.kk img {
  margin-left: 60%;
}

#main {
  width: 100% !important;
}

#main {
  width: 100% !important;
}

#main ::v-deep div {
  width: 100% !important;
}

#main ::v-deep div:first-child {
  width: 100% !important;
}

#main ::v-deep div ::v-deep canvas {
  width: 100% !important;
}

#main ::v-deep div:first-child ::v-deep canvas {
  width: 100% !important;
}

#main > div ::v-deep canvas {
  width: 100% !important;
}

// @media screen and (max-width: 700px) {
//   .kk img {
//     display: none;
//   }

//   .rightbottom>div>img {
//     display: none;
//   }

//   .foot0102 {
//     display: none;
//   }
// }

::v-deep .el-container {
  height: 93% !important;
}

::v-deep element.style {
  left: 1rem;
  width: 50rem;
}
::v-deep .el-tabs__nav-scroll {
  background-color: #333;
}
::v-deep .el-radio-button--mini .el-radio-button__inner {
  padding: 0.4rem 0.9rem;
  font-size: 0.6rem;
}
</style>