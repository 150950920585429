<template>
  <div
    class="box"
    style="
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      width: 100vw;
      height: 100vh;
    "
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="boxed" ref="captureArea">
      <img
        src="../../assets/jiebaian(1).png"
        alt=""
        class="img"
        ref="imgDiv"
        @mousedown="move"
      />
      <el-popover
        placement="bottom"
        :title="item.equipname"
        width="100"
        trigger="hover"
        v-for="(item, index) in equipVal"
        :key="index"
      >
        <div>
          <div style="display: flex; justify-content: space-around">
            <span>实时电压:</span><span>{{ item.voltage }}</span>
          </div>
          <div style="display: flex; justify-content: space-around">
            <span>实时电流:</span><span>{{ item.electricity }}</span>
          </div>
          <div style="display: flex; justify-content: space-around">
            <span>在线时长:</span><span>{{ item.runTime }}</span>
          </div>
        </div>
        <i
          class="el-icon-s-opportunity"
          style="font-size: 0.8rem"
          @mousedown="startDrag($event, `${item.stats}`)"
          @mousemove="handleDrag($event, `${item.stats}`)"
          @mouseup="endDrag(`${item.stats}`)"
          slot="reference"
          @click="compile(item.name)"
          :ref="`${item.stats}`"
          >{{ item.name }}</i
        >
      </el-popover>
      <div></div>
    </div>
    <!-- <button @click="captureScreen">Capture Screen</button> -->
    <div class="butt">
      <div class="queren" v-if="!drawer">
        <div class="btn three-d" @click="rTan">设置</div>
      </div>
      <div class="queren" v-else>
        <div class="three-d" @click="tanchu">选择</div>
        <div class="three-d" @click="cancelMoveITag">取消</div>
        <div class="three-d" @click="confirmMoveITag">确认</div>
        <div class="three-d" @click="addPlace">添加</div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="redact" width="30%">
      <Select :keyValue="equipVal"></Select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="redact = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="addDianwei" width="30%">
      <add-place
        :equipVal="equipVal"
        @valueKey="valueKey"
        @cancel="addDianwei = false"
      ></add-place>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="compileDianwei" width="30%">
      <el-input
        v-model="input"
        placeholder="请输入内容"
        maxlength="10"
        show-word-limit
        style="width: 250px; margin-right: 20px"
      ></el-input>
      <el-button type="primary" @click="xiugaiDianwei">修改</el-button>
      <el-button type="primary" @click="shanchuDianwei">删除</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Select from "../../components/xunjian/select.vue";
import addPlace from "../../components/xunjian/addPlace.vue";
import html2canvas from "html2canvas";
import instance from "@/request/request";
import { size } from "@antv/util";
export default {
  name: "HAHome",
  components: {
    Select,
    addPlace,
  },
  data() {
    return {
      loading: true,
      equipVal: [],
      equipData: [
        {
          name: "设备一",
          voltage: 100,
          electricity: 100,
          runTime: 100,
        },
        {
          name: "设备二",
          voltage: 200,
          electricity: 200,
          runTime: 200,
        },
        {
          name: "设备三",
          voltage: 300,
          electricity: 300,
          runTime: 300,
        },
      ], //设备数据
      isDragging: false, // 用于追踪是否正在拖动图片
      redact: false, //控制编辑弹窗
      drawer: false, // 编辑弹窗
      isDra: false, //图标是否拖动
      initialX: 0,
      initialY: 0,
      iconX: 0,
      iconY: 0,
      equipId: 0,
      addDianwei: false, //添加点位
      randomStrings: [], //用来存放随机生成的字符串
      compileDianwei: false, //编辑点位
      lastClickTime: 0, //定义点击间隔
      doubleClickInterval: 500, // 两次点击之间的最小时间间隔，单位为毫秒
      IndexNum: 0,
      input: "", //修改的名字
      viewportWidth: 1920,
      viewportHeight: 945,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        let i = parseInt(newVal);
        this.render = this.equipData[i];
      },
    },
    value2: {
      deep: true,
      handler(newVal) {
        let i = parseInt(newVal);
        this.render2 = this.equipData[i];
      },
    },
    value3: {
      deep: true,
      handler(newVal) {
        let i = parseInt(newVal);
        this.render3 = this.equipData[i];
      },
    },
    value4: {
      deep: true,
      handler(newVal) {
        let i = parseInt(newVal);
        this.render4 = this.equipData[i];
      },
    },
    viewportWidth(newValue, oldValue) {
      // 在视口宽度变化时执行操作
      this.equipVal[0].number[1] = (newValue * 75) / 1920;
      this.equipVal[1].number[1] = (newValue * 190) / 1920;
      this.equipVal[2].number[1] = (newValue * 420) / 1920;
      this.equipVal[3].number[1] = (newValue * 420) / 1920;
      this.equipVal[4].number[1] = (newValue * 420) / 1920;
      this.equipVal[5].number[1] = (newValue * 75) / 1920;
      console.log(
        "Viewport width changed from",
        oldValue,
        "to",
        this.equipVal[0].number[1].toFixed(0)
      );
      const img = this.$refs.imgDiv;
      let tops = parseInt(img.style.top) || 0;
      let lefts = parseInt(img.style.left) || 0;
      this.equipVal.forEach((item, i) => {
        this.$refs[item.stats][0].style.top = tops + item.number[0] + "px";
        this.$refs[item.stats][0].style.left = lefts + item.number[1] + "px";
      });
      this.equipVal[0].number[0] = (newValue * 130) / 1920;
      this.equipVal[1].number[0] = (newValue * 465) / 1920;
      this.equipVal[2].number[0] = (newValue * 120) / 1920;
      this.equipVal[3].number[0] = (newValue * 165) / 1920;
      this.equipVal[4].number[0] = (newValue * 80) / 1920;
      this.equipVal[5].number[0] = (newValue * 225) / 1920;
      //   const img = this.$refs.imgDiv;
      // let tops = parseInt(img.style.top) || 0;
      // let lefts = parseInt(img.style.left) || 0;
      this.equipVal.forEach((item, i) => {
        this.$refs[item.stats][0].style.top = tops + item.number[0] + "px";
        this.$refs[item.stats][0].style.left = lefts + item.number[1] + "px";
      });
      // 可以在这里执行其他操作，比如重新计算布局等
    },
    // viewportHeight(newValue, oldValue) {
    //   // 在视口高度变化时执行操作
    //   this.equipVal[0].number[0] = (newValue * 150) / 945;
    //   this.equipVal[1].number[0] = (newValue * 520) / 945;
    //   this.equipVal[2].number[0] = (newValue * 140) / 945;
    //   this.equipVal[3].number[0] = (newValue * 185) / 945;
    //   this.equipVal[4].number[0] = (newValue * 85) / 945;
    //   this.equipVal[5].number[0] = (newValue * 250) / 945;
    //   const img = this.$refs.imgDiv;
    //   let tops = parseInt(img.style.top) || 0;
    //   let lefts = parseInt(img.style.left) || 0;
    //   this.equipVal.forEach((item, i) => {
    //     this.$refs[item.stats][0].style.top = tops + item.number[0] + "px";
    //     this.$refs[item.stats][0].style.left = lefts + item.number[1] + "px";
    //   });
    //   console.log("Viewport height changed from", oldValue, "to", newValue);
    //   // 可以在这里执行其他操作，比如重新计算布局等
    // },
  },
  mounted() {
    this.getData();
    window.addEventListener("resize", this.handleResize);
    // 初始化时获取一次视口宽高

    // setTimeout(() => {
      this.loading = false;
    // }, 500);
   

    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    this.render = this.equipData[0];
    this.render2 = this.equipData[0];
    this.render3 = this.equipData[0];
    this.render4 = this.equipData[0];
  },
  methods: {
    async getData() {
      const res = await instance({
        url: "/ammeter-area/query-list",
        params: {
          page: 1,
          size: 10,
        },
      });
      // this.equipVal = res.data.content.list.map((x) => {
      //   x.number = x.number.split(",").map(Number);
      //   x.inceptionxy = x.inceptionxy.split(",").map(Number);
      // });
      this.equipVal = res.data.content.list.map((x) => {
        // 转换 number 和 inceptionxy 属性
        x.number = x.number.split(",").map(Number);
        x.inceptionxy = x.inceptionxy.split(",").map(Number);
        return x; // 返回修改后的对象
      });
    const img = this.$refs.imgDiv;

      let tops = parseInt(img.style.top) || 0;
    let lefts = parseInt(img.style.left) || 0;

      this.equipVal.forEach((item, i) => {
      setTimeout(()=>{
        this.$refs[item.stats][0].style.top = tops + item.number[0] + "px";
      this.$refs[item.stats][0].style.left = lefts + item.number[1] + "px";
      },0)
    });
    },
    captureScreen() {},
    downloadImage(data, filename) {
      const a = document.createElement("a");
      a.href = data;
      a.download = filename;
      a.click();
    },
    move(e) {
      if (!this.drawer) {
        e.preventDefault();
        this.isDragging = true;
        const img = this.$refs.imgDiv;
        this.initialX = e.pageX - img.offsetLeft;
        this.initialY = e.pageY - img.offsetTop;

        window.addEventListener("mousemove", this.linkmove);
        window.addEventListener("mouseup", this.endMove);
      }
    },
    linkmove(e) {
      if (!this.isDragging) return;

      const img = this.$refs.imgDiv;
      img.style.left = e.pageX - this.initialX + "px";
      img.style.top = e.pageY - this.initialY + "px";

      this.updateButtonsPosition();
    },
    endMove() {
      this.isDragging = false;
      window.removeEventListener("mousemove", this.linkmove);
      window.removeEventListener("mouseup", this.endMove);
    },
    updateButtonsPosition() {
      const img = this.$refs.imgDiv;
      let tops = parseInt(img.style.top) || 0;
      let lefts = parseInt(img.style.left) || 0;
      this.equipVal.forEach((item, i) => {
        this.$refs[item.stats][0].style.top = tops + item.number[0] + "px";
        this.$refs[item.stats][0].style.left = lefts + item.number[1] + "px";
      });
    },
    tanchu() {
      this.redact = true;
    },
    rTan() {
      this.drawer = true;
      this.equipVal.forEach((item, i) => {
        item.inceptionxy[0] = parseInt(this.$refs[item.stats][0].style.top);
        item.inceptionxy[1] = parseInt(this.$refs[item.stats][0].style.left);
      });
      console.log(this.equipVal);
    },
    async confirmMoveITag() {
      this.drawer = false;
      let data = this.equipVal.map(x=>{
        // 转换 number 和 inceptionxy 属性
        x.number = x.number.join(",")
        x.inceptionxy = x.inceptionxy.join(",")
        return x; //返回修改后的对象
      })
      console.log(data);
      const res = await instance({
        url: "/ammeter-area/saveList",
        method: "post",
        data: data,
      });
      // const captureArea = this.$refs.captureArea;
      // html2canvas(captureArea).then((canvas) => {
      //   const image = canvas.toDataURL("image/png");
      //   this.downloadImage(image, "screenshot.png");
      // });
      // // 生成截图并发送到后端
      // html2canvas(captureArea).then(async (canvas) => {
      //   // 将 canvas 转换为 Blob
      //   canvas.toBlob(async (blob) => {
      //     // 创建 FormData 对象并添加 Blob
      //     const formData = new FormData();
      //     formData.append("file", blob, "screenshot.png");
      //     try {
      //       // 发送 POST 请求将图片发送到后端
      //       const response = await instance({
      //         url: "/xunjian/save",
      //         method: "post",
      //         data: formData,
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       });

      //       console.log("图片上传成功:", response.data);
      //       this.$message({
      //     message: '上传成功',
      //     type: 'success'
      //   });
      //     } catch (error) {
      //       console.error("图片上传失败:", error);
      //       this.$message.error('图片上传失败');
      //     }
      //   }, "image/png");
      // });
    },
    cancelMoveITag() {
      this.drawer = false;
      this.equipVal.forEach((item, i) => {
        this.$refs[item.stats][0].style.top = item.inceptionxy[0] + "px";
        this.$refs[item.stats][0].style.left = item.inceptionxy[1] + "px";
      });
    },

    startDrag(e, val) {
      const i = this.$refs[val][0];
      if (this.drawer) {
        e.preventDefault();
        this.isDra = true;
        this.iconX = e.pageX - i.offsetLeft;
        this.iconY = e.pageY - i.offsetTop;
        window.addEventListener("mousemove", this.linkmove);
        window.addEventListener("mouseup", this.endMove);
      }
    },
    handleDrag(e, val) {
      if (!this.isDra) return;
      const i = this.$refs[val][0];
      i.style.left = e.pageX - this.iconX + "px";
      i.style.top = e.pageY - this.iconY + "px";
    },
    endDrag(val) {
      this.isDra = false;
      window.removeEventListener("mousemove", this.linkmove);
      window.removeEventListener("mouseup", this.endMove);
      const img = this.$refs.imgDiv;
      let tops = parseInt(img.style.top) || 0;
      let lefts = parseInt(img.style.left) || 0;
     if(this.equipVal != []){
      this.equipVal.forEach((item, i) => {
        item.number[0] = parseInt(this.$refs[item.stats][0].style.top) - tops;
        item.number[1] = parseInt(this.$refs[item.stats][0].style.left) - lefts;
      });
     }
      console.log(this.equipVal);
    },
    confirm() {
      this.redact = false;
      this.equipVal.forEach((item, i) => {
        this.equipData.forEach((val, ind) => {
          if (item.equipname == val.name) {
            item.voltage = val.voltage;
            item.electricity = val.electricity;
            item.runTime = val.runTime;
          }
        });
      });
      console.log("0303033030303", this.equipVal);
    },
    addPlace() {
      this.addDianwei = true;
    },
    generateRandomString() {
      //随机生成5位英文字符
      const characters = "abcdefghijklmnopqrstuvwxyz";
      let randomString = "";

      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
      }

      return randomString;
    },
    valueKey(val) {
      this.addDianwei = false;
      console.log(val);

      let newRandomString;
      do {
        newRandomString = this.generateRandomString();
      } while (this.randomStrings.includes(newRandomString));
      this.randomStrings.push(newRandomString);
      console.log("randomStrings", this.randomStrings);
      let status = this.randomStrings[this.randomStrings.length - 1];
      this.equipVal.push({
        name: val,
        equipname: "设备一",
        voltage: 100,
        electricity: 100,
        runTime: 100,
        stats: status,
        number: [300, 300],
        inceptionxy: [0, 0],
      });
    },
    compile(i) {
      console.log(i);
      let match = i.match(/\d+/);
      let num = parseInt(match[0], 10);
      this.$router.push(`shebeiDetail?stationId=${num}`);

      // if (!this.drawer) return;
      // this.IndexNum = i;
      // const now = Date.now();
      // if (now - this.lastClickTime < this.doubleClickInterval) {
      //   // 两次点击间隔小于最小时间间隔，执行命令
      //   this.compileDianwei = true;
      // }
      // this.lastClickTime = now; // 更新上一次点击时间
    },
    xiugaiDianwei() {
      this.equipVal.forEach((item, i) => {
        if (i != this.IndexNum) return;
        item.name = this.input;
      });
      this.compileDianwei = false;
    },
    shanchuDianwei() {
      this.equipVal.splice(this.IndexNum, 1);
      this.compileDianwei = false;
    },
    handleResize() {
      // 在窗口大小变化时调用该方法
      this.getViewportSize();
      console.log(this.viewportWidth, "宽度");
      console.log(this.viewportHeight, "高度");
    },
    getViewportSize() {
      // 获取视口宽高
      const width = window.innerWidth || document.documentElement.clientWidth;
      const height =
        window.innerHeight || document.documentElement.clientHeight;
      // 将宽高存储到组件的数据中，以便在模板中使用
      this.viewportWidth = width;
      this.viewportHeight = height;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: rgba(52, 52, 52, 0.8);
  overflow-y: hidden;
}
.boxed {
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 27vw;
  height: 36vw;
  margin: 0 auto;
  padding-top: 30px;
  border: 1px solid red;
  // overflow: hidden;
}
.boxed img {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // min-height: 600px;
  // min-width: 430px;
  // transform: translate(-50%, -50%);
  /* 添加过渡效果，使缩放更平滑 */
  transition: transform 0.3s;
}
.el-icon-s-opportunity {
  position: absolute;
  cursor: pointer;
  color: red;
  font-size: 24px;
}
.butt,
.queren {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.three-d {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  letter-spacing: 1rem;
  text-indent: 1rem;
  border-radius: 20px;
  box-sizing: border-box;
  margin-right: 10px;
  color: #fff;
  background-color: #f1c40f;
  text-shadow: -2px 2px 2px rgb(209 132 0), -2px 2px 2px rgb(209 132 0),
    -2px 2px 2px rgb(209 132 0), -2px 2px 2px rgb(209 132 0),
    -2px 2px 2px rgb(209 132 0), -2px 2px 2px rgb(209 132 0);
  box-shadow: 0px 15px 0px 0px #f39c12;
  transition: all 0.5s;
}

.three-d:hover {
  background-color: #fcdc5e;
}

.three-d:active {
  transform: translate(0, 4px);
  box-shadow: 0px 1px 0px 0px #f39c12;
}
</style>
