import { mapState, mapMutations } from 'vuex';
export default {
  首页: '首页',
  统计: '统计',
  设置: '设置',
  表单: '表单',
  computed: {
    ...mapState(['menu']),
    //没有子菜单
    parentNodes() {
        return this.menu.map(item => {
            if (!item.children || item.children.length === 0) {
                return item;
            } else {
                // 如果有子节点，将子节点置为空
                return {
                    path: item.path,
                    name: item.name,
                    label: item.label,
                    icon: item.icon,
                    url: item.url,
                };
            }
        });
    },
},
}
