<template>
  <div>
    <div
      v-for="(val, i) in keyValue"
      :key="i"
      style="
        display: flex;
        align-items: center;
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 20px;
      "
    >
      <div class="changeEquip">{{ val.name }}&nbsp;&nbsp;&nbsp;</div>
      <el-select
        v-model="val.equipname"
        clearable
        placeholder="选择设备"
      >
        <el-option
          v-for="item in options"
          :key="item.status"
          :label="item.label"
          :value="item.status"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    keyValue:{
      require:true,
    }
  },
  data() {
    return {
      valued: "",
      options: [
        {
          status: "设备一",
          label: "设备一",
        },
        {
          status: "设备二",
          label: "设备二",
        },
        {
          status: "设备三",
          label: "设备三",
        },
      ],
    };
  },

  mounted() {
    
  },

  methods: {
  },
};
</script>

<style scoped>
.changeEquip {
  margin-right: 30px;
}
</style>
