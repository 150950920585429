<template>
  <div>
    <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        id="form01"
      >
        <el-form-item>
          <el-select v-model="formInline.region" placeholder="请选择站点或支架">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-checkbox-group v-model="formInline.type">
            <el-checkbox label="全选" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button type="success" icon="el-icon-film">设置跟踪模式</el-button>
        <el-button type="success" icon="el-icon-film">设置方位角度</el-button>
        <el-button type="success" icon="el-icon-film">设置俯仰角度</el-button>

        <el-form-item class="ping01">
          <el-button :type="trigger? 'primary':'info'" @click="onSubmit" icon="el-icon-menu" 
            >平铺</el-button
          >
          <el-button :type="trigger? 'info':'primary'" icon="el-icon-s-grid" @click="onBtn">表格</el-button>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.region01" placeholder="选择状态">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="formInline.user"
            placeholder="搜索名称"
            icon="el-icon-search"
          ></el-input>
        </el-form-item>
      </el-form>
      </div>
</template>

<script>
export default {
  data(){
    return{
        formInline: {
        user: "",
        region: "",
        type: [],
        region01: "",
        
      },
    }
  }
}
</script>

<style>
.ping01{
    margin-left: 22% !important;
}
</style>