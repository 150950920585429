<template>
  <div class="table04">
    <div style="display: flex;justify-content: center;align-items: center;">
      <el-select
      v-model="selectValue"
      placeholder="请选择"
      style="margin: 1rem"
      @change="onChange"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="selectId"
      placeholder="请选择"
      style="margin: 1rem"
      @change="onChange"
    >
      <el-option
        v-for="item in opData"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      >
      </el-option>
    </el-select>
    <el-input placeholder="请输入箱号或功能号" style="width: auto;" v-model="xiang"></el-input>
    <el-button style="margin-left: 1rem;" icon="el-icon-search" circle @click="onXiang"></el-button>
    </div>
    <div @scroll="getScrollTop" :class="!scrollSwitch ? 'scrollBox' : ''">
      <div @scroll="getScrollTop" :class="scrollSwitch ? 'scrollBox' : ''">
        <div
          class="tablet"
          v-if="trigger"
          v-loading="load"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="ta01">
            <div
              class="ta02"
              :class="item.message == 'true' ? 'gai' : 'gai01'"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <div
                class="ta03 gai00"
                :class="item.message == 'true' ? 'gai' : 'gai01'"
              >
                <!-- <input type="checkbox" name="fruit" value="apple" /> -->
                <span style="margin-left: 0.4rem">{{ item.name }}</span>
                <span
                  @click="onDetail(item.getPowerDataResp.stationId, item.name,index)"
                  style="cursor: pointer; float: right; margin-right: 0.4rem"
                  >详情</span
                >
              </div>
              <div class="ta04">
                <!-- 支架运行模式 实际方位角度: 实际俯仰角度: 水平光照差值:
                俯仰光照差值: 组串直流电压: 组串直流电流: -->
                <div class="imga">
                  <img src="@/assets/121600.jpg" alt="" />
                </div>
                <div class="ta06" v-if="item.getPowerDataResp">
                  <!-- <span>电流:{{ item.name.search('单') == -1? 'A相'+item.getPowerDataResp.acurrent+'A'+'B相'+item.getPowerDataResp.bcurrent+'A'+'C相'+item.getPowerDataResp.ccurrent+'A':'' }}</span
                  ><br />
                  <span>电压:{{ item.aVoltage + "V" }}</span
                  ><br /> -->
                  <span
                    >A相:{{ item.getPowerDataResp.acurrent + "A" }}&nbsp;{{
                      item.getPowerDataResp.avoltage + "V"
                    }}&nbsp;{{ item.getPowerDataResp.aactivePower + "W" }}</span
                  ><br v-if="item.name.search('单') == -1" />
                  <span v-if="item.name.search('单') == -1"
                    >B相:{{ item.getPowerDataResp.bcurrent + "A" }}&nbsp;{{
                      item.getPowerDataResp.bvoltage + "V"
                    }}&nbsp;{{ item.getPowerDataResp.bactivePower + "W" }}</span
                  ><br v-if="item.name.search('单') == -1" />
                  <span v-if="item.name.search('单') == -1"
                    >C相:{{ item.getPowerDataResp.ccurrent + "A" }}&nbsp;{{
                      item.getPowerDataResp.cvoltage + "V"
                    }}&nbsp;{{ item.getPowerDataResp.cactivePower + "W" }}</span
                  ><br />
                  <span>运行时间:{{ item.runningTime }}</span
                  ><br />
                  <!-- <span>{{item.message == 'true'&&item.lastOfflineTime ==?"上次离线时间:":"上次在线时间:" + item.lastOfflineTime }}</span><br /> -->
                  <span v-if="item.message == 'true'"
                    >上次离线时间:{{
                      item.lastOfflineTime == "" ? "暂无" : item.lastOfflineTime
                    }}</span
                  ><br v-if="item.message == 'true'" />
                  <span v-if="item.message !== 'true'"
                    >上次在线时间:{{
                      item.lashOnlineTime == "" ? "暂无" : item.lashOnlineTime
                    }}</span
                  ><br v-if="item.message !== 'true'" />
                  <!-- <span>总用电量:1300kWh</span><br />
                  <span>当前功率:1380W</span><br /> -->
                </div>
                <div class="ta07">安装位置: 捷百安</div>
              </div>
            </div>

            <div></div>
          </div>
          <div
            style="
              width: 3rem;
              height: 3rem;
              background-color: rgba(255, 255, 255, 0.7);
              position: fixed;
              bottom: 5%;
              right: 1.7%;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
            @click="goBack"
            v-if="scrollBtn"
          >
            <i
              class="el-icon-top"
              style="
                font-size: 0.8rem;
                display: inline-block;
                margin-top: 0.6rem;
              "
            ></i>
            <p style="font-size: 0.6rem">返回顶部</p>
          </div>
        </div>

        <div
          class="tablet"
          v-else
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <bei-table></bei-table>
          <div
            style="
              width: 3rem;
              height: 3rem;
              background-color: rgba(255, 255, 255, 0.7);
              position: fixed;
              bottom: 9%;
              right: 1%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            "
            @click="goBack"
            v-if="scrollBtn"
          >
            <i
              class="el-icon-top"
              style="font-size: 1rem; display: inline-block; margin-top: 0.6rem"
            ></i>
            <p style="font-size: 0.6rem">返回顶部</p>
          </div>
        </div>
        <!-- <div></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import instance from "@/request/request";
import stringParser from "@/util/stringObj.js";
import BeiTable from "./components/BeiTable.vue";
import BeiHeader from "./components/BeiHeader.vue";
import { flatten } from "@antv/util";
export default {
  components: {
    BeiTable,
    BeiHeader,
  },
  data() {
    return {
      xiang:null,
      load: true,
      opData:[],
      selectId:'全部',
      wgz: false,
      cgx: true,
      data: [],
      tableData: [],
      changer: true,
      changer1: true,
      loading: false,
      value: true,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      selectValue: null,
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
      scrollBtn: false,
      scrollSwitch: true,
      formInline: {
        user: "",
        region: "",
        type: [],
        region01: "",
      },
      trigger: true,
      statusInterval: null,
    };
  },
  methods: {
    onXiang(){
      this.getStatus()
    },
    async getOpData() {
      const res = await instance("/wx/getEquipmentStatusList");
      this.opData = res.data.content;
      this.opData.unshift({id:1,name:"全部"})
    },
    async getOption() {
      const res = await instance("/eq-type/getAllEqType");
      this.options = res.data.content;
      this.options.unshift({ id: null, name: "全部" });
      console.log(this.options, 9999999);
    },

    mergeData(data1, data2) {
      // 创建一个空数组来存储合并后的结果
      let mergedData = [];

      // 遍历第一组数据
      data1.forEach((item1) => {
        // 在第二组数据中查找与当前项名称相同的项
        let matchingItem = data2.find((item2) => item2.name === item1.name);

        // 如果找到匹配项，则将两组数据合并
        if (matchingItem) {
          mergedData.push({ ...item1, ...matchingItem });
        } else {
          // 如果未找到匹配项，则只将第一组数据中的项添加到结果中
          mergedData.push(item1);
        }
      });

      // 将第二组数据中不在第一组中的项添加到结果中
      data2.forEach((item2) => {
        let existingItem = data1.find((item1) => item1.name === item2.name);
        if (!existingItem) {
          mergedData.push(item2);
        }
      });

      return mergedData;
    },

    fff() {
      this.wgz = true;
      this.cgx = false;
    },
    async getStatus() {
      // this.load = true
      const res = await instance({
        url: "/wx/getStatus",
        params: {
          typeId: this.selectValue,
          type:this.selectId != '全部'?this.selectId:null,
          boxNumber:this.xiang
        },
      });
      this.tableData = res.data.content;
      console.log(this.tableData, 6666666666);
      this.load = false;
    },
    async fetchData() {
      try {
        const response = await instance({
          url: "/ammeter/getPowerData",
        });
        this.data = response.data.content;
        //     if(this.tableData != [] && this.data != []){
        //     console.log(
        //   this.mergeData(this.tableData, this.data),77777777777777

        //     );
        // }
        // console.log(response.data.content);
        // this.data = response.data.filter((x) => !x.name.includes("閰嶇數绠�")); // 直接将后端返回的数据赋给组件的 data 属性
      } catch (error) {
        console.error("获取数据时发生错误:", error);
      }
    },
    // async getData() {
    //   const res = await axios({
    //     url: "http://mqtt-1.hualaizhike.com:8000/ammeter/getEquipment",
    //   });
    //   console.log(res.data.content);
    // },
    onChange() {
      console.log(this.selectId);
      this.load = true
      this.getStatus();
    },
    onDetail(stationId, name,index) {
      this.$router.push(`shebeiDetail?stationId=${stationId}&name=${name}`);
    },
    switchScroll() {
      if (this.value === false) {
        this.value = false;
        this.scrollSwitch = false;
      } else {
        this.value = true;
        this.scrollSwitch = true;
      }
    },
    goBack() {
      // this.$refs.outerDom.scrollTop = 0;
      let that = this; // 防止this指向问题
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      if (that.scrollTrigger) {
        return;
      }
      // 获取当前距离顶部的距离
      let scrollTop = this.scrollTop;
      console.log(scrollTop);
      let steep = scrollTop / 2000;
      let timer = setInterval(() => {
        that.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 20;
        if (scrollTop <= 0) {
          clearInterval(timer);
          that.scrollTrigger = false;
        }
        document.querySelector(".scrollBox").scrollTop = scrollTop;
      }, 30);
    },
    getScrollTop(e) {
      // console.log(this.$refs.outerDom.scrollTop);
      let that = this; // 防止this指向问题
      // 设备一屏的高度
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scrollTop = e.srcElement.scrollTop;
      this.scrollTop = scrollTop;
      if (scrollTop > 10) {
        that.scrollBtn = true;
      } else {
        that.scrollBtn = false;
      }
      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > clientHeight) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    },
    onSubmit() {
      this.trigger = true;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    onBtn() {
      this.trigger = false;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    rowClassName(row, index) {
      if (index === 1) {
        return "demo-table-info-row";
      } else if (index === 3) {
        return "demo-table-error-row";
      }
      return "";
    },
    onInput() {
      const sliderEl = document.querySelector("#slider-input");
      // 获取数值显示容器实例
      const selectedEl = document.querySelector(".selected");
      // 监听滑动事件
      selectedEl.innerHTML = sliderEl.value;
      // const sizeSlider = document.getElementById("size-slider");
      const resizableBox = document.querySelector("tr");
      const resizableBox2 = document.querySelectorAll("td");
      // console.log( typeof resizableBox);
      const newSize = sliderEl.value / 5 + 6 + "px";
      // console.log(newSize);
      resizableBox2.forEach((x) => (x.style.fontSize = newSize));
      //   console.log(newSize);
      resizableBox.style.fontSize = newSize;
      //   resizableBox2.style.fontSize = newSize;
    },
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.statusInterval);
  },
  mounted() {
    this.getStatus();
    this.getOption();
    this.getOpData()
    this.statusInterval = setInterval(() => {
      this.getStatus();
    }, 10000);
    // const ws = new WebSocket("ws://mqtt-1.hualaizhike.com:8000/ws/6669");
    // ws.onopen = () => {
    //   console.log("连接成功");
    // };
    // ws.error = () => {
    //   console.log("连接失败");
    // };
    // ws.onmessage = (msg1) => {
    //   // console.log(msg1.data);
    //   // // 假设你收到的消息内容为 event.data，即 "{message='false', name='03040100003A7007'}"
    //   // const dataString = msg1.data;

    //   // // 提取 message 的值
    //   // const messageIndex = dataString.indexOf("message=");
    //   // const messageStartIndex = messageIndex + "message=".length;
    //   // const messageEndIndex = dataString.indexOf(",", messageStartIndex);
    //   // const messageValue =
    //   //   dataString
    //   //     .substring(messageStartIndex, messageEndIndex)
    //   //     .trim()
    //   //     .replace(/'/g, "") === "true";

    //   // // 提取 name 的值
    //   // const nameIndex = dataString.indexOf("name=");
    //   // const nameStartIndex = nameIndex + "name=".length;
    //   // const nameEndIndex = dataString.indexOf("}", nameStartIndex);
    //   // const nameValue = dataString
    //   //   .substring(nameStartIndex, nameEndIndex)
    //   //   .replace(/'/g, "");

    //   // // console.log("message:",  messageValue === 'true'); // 输出 message 的值
    //   // // console.log("name:", nameValue); // 输出 name 的值
    //   // if(this.tableData.length <12){
    //   //    // 添加新的数据
    //   // this.tableData.push({
    //   //   message: messageValue,
    //   //   name: nameValue,
    //   // });
    //   // }
    //   // if (this.tableData != []) {
    //   //   const existingIndex = this.tableData.findIndex(
    //   //     (item) => item.name === nameValue
    //   //   );
    //   //   if (existingIndex !== -1) {
    //   //     // 如果存在相同名字的数据，删除前面的一条
    //   //     this.tableData.splice(existingIndex, 1, {
    //   //       message: messageValue,
    //   //       name: nameValue,
    //   //     });
    //   //   }
    //   // }

    //   // // 添加新的数据
    //   console.log(msg1.data);
    //   this.tableData = stringParser.parseStringToObjectArray(msg1.data);
    //   this.fff();
    //   console.log(this.tableData);
    // };
    if (!this.trigger) {
      const resizableBox = document.querySelector("tr");
      const resizableBox2 = document.querySelectorAll("td");
      resizableBox2.forEach((x) => (x.style.fontSize = "16px"));
      //   console.log(newSize);
      resizableBox.style.fontSize = "16px";
    }
    // setTimeout(() => {

    // }, 500);
  },
};
</script>
<style lang="scss" scoped>
.table04 {
  width: 100vw;
  height: 150vh !important;
  background-color: #1c2232;
  // overflow: auto !important;
}
.ping01 {
  margin-left: 10vw !important;
}

.el-form-item .el-select {
  background-color: #1c2232 !important;
}

.el-form-item
  ::v-deep
  .el-form-item__content
  .el-checkbox-group
  .el-checkbox
  .el-checkbox__input
  .el-checkbox__inner {
  background-color: #283046;
}

.el-form-item
  ::v-deep
  .el-form-item__content
  ::v-deep
  .el-select
  ::v-deep
  div
  ::v-deep
  .el-input__inner {
  background-color: #283046;
}
.tablet {
  width: 98%;
  height: 120vh;
  background-color: #1a2433;
  margin: 0 auto;
}
.ta01 {
  margin-top: 0.3rem;
  width: 100%;
  font-size: 0.8rem !important;
  background-color: #1a2433;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
}
.ta02 {
  width: 19.5%;
  height: 100%;
  margin: 0.18rem;
  background-color: #fff;
  border: 0.06rem solid black;
}
.ta03 {
  width: 100%;
  height: 10%;
  background-color: #a0a5a9;
}
.ta03 span,
.ta03 a {
  color: #fff;
  // margin-left: 1.25rem;
}
.dan {
  border-radius: 0;
}
.ta04 {
  width: 100%;
  height: 90%;
  position: relative;
  background-color: #222e3c;
}
.ta04 > div {
  // padding-top: 20px;
}
.ta04 div:first-child {
  padding-top: 2.5rem;
  margin-left: 0.6rem;
}
.ta06 {
  position: absolute;
  top: 5vh;
  left: 9rem;
  color: #6e7e8e;
}
.ta07 {
  margin-top: 2.1rem;
  margin-left: 0.6rem;
  color: #6e7e8e;
}
/* 自定义样式 */
.custom-checkbox {
  background-color: black;
}
.gai00 {
  background-color: #bab5b5;
  border: 0.06rem solid #0db970;
}
.gai {
  background-color: #0db970;
  border: 0.06rem solid #0db970;
}
.gai01 {
  background-color: #e30304;
  border: 0.06rem solid #e30304;
}

.scrollBox {
  overflow-y: auto;
  height: 93vh;
}
.imga {
  width: 6rem;
  height: 9rem;
  overflow: hidden;
  padding-left: 2vw;
}
div img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例并覆盖整个盒子 */
  object-position: center;
}
.vvv01 {
  background-color: #aaa6a6;
  border: 0.06rem solid #aaa6a6;
}
.vvv02 {
  background-color: #aaa6a6;
  border: 0.06rem solid #aaa6a6;
}
</style>