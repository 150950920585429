<template>
  <div style="">
    <div id="table06">
      <div class="right">
        <div class="ssgl" style="margin-top: 0.2rem;display: flex; align-items: center;">
          <!-- <span class="ssgl02"
            ><el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="number"
            ></el-progress
          ></span> -->
          <span style="display: flex; align-items: center; color: #fff;margin-left: 0.5rem;">
            在线状态
            <div
              style="
                width: 0.3rem;
                height: 1rem;
                border-radius: 0.5rem;
                background-color: red;
                margin-left: 0.3rem;
              "
              v-for="(item, index) in number"
              :key="index"
              :class="item.message == 'true' ? 'green01' : ''"
            ></div>
            <span style="margin-left: 2rem"> [设备名]{{ $route.query.name }}</span>
          </span>
          
        </div>
        <div class="righttop" style="overflow-y: auto; width: 100vw;height: 86.5vh">
          <div
            style="
              display: flex;
              flex-direction: row;
              width: 100rem;
              height: 15vh;
              margin: 0 auto !important;
            "
          >
            <div class="topfirst01" style="width: 100rem; margin-right: 1rem">
              <div
                class="topfirst0101"
                style="width: 8rem; height: 16.5vh; margin-left: 13rem"
              >
                <div class="gz01">
                  <img
                    class="img00"
                    height="100%"
                    src="../../assets/shebei/dianliu.png"
                    alt=""
                    style="margin-left: -0.1rem"
                  />
                  <span style="font-size: 0.8rem; color: #96a6be">通讯状态</span
                  ><br />
                </div>

                <i
                  class="el-icon-s-promotion"
                  :class="status == 'true' ? 'on' : 'off'"
                  style="font-size: 1.5rem; transform: translate(200%, 60%)"
                ></i>
                <span
                  style="
                    transform: translate(-80%, 120%);
                    margin: 0 auto;
                    text-align: center;
                    display: inline-block;
                  "
                  class="on01"
                  :class="status == 'true' ? 'on' : 'off'"
                  >{{ status == "true" ? "Online" : "Offline" }}</span
                >
              </div>

              <div class="topfirst0103">
                <div class="gz01">
                  <img
                    class="img00"
                    height="100%"
                    src="../../assets/shebei/dianliu.png"
                    alt=""
                    style="margin-left: -0.1rem"
                  />
                  <span style="font-size: 0.8rem; color: #96a6be">故障状态</span
                  ><br />
                </div>

                <i
                  class="el-icon-s-opportunity"
                  style="
                    font-size: 1.5rem;
                    color: gray;
                    display: inline-block;
                    line-height: 11vh;
                    transform: translateX(200%);
                  "
                ></i>
              </div>
            </div>
            <div class="big" style="transform: translateX(-15rem)">
              <div
                class="leftbig01"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <div class="cont">
                  <div class="dianliu01">
                    <img
                      height="100%"
                      src="../../assets/shebei/dianliu.png"
                      alt=""
                      style="margin-left: -0.1rem"
                    />
                    <span style="font-size: 0.8rem">电流</span>
                    <img
                      height="100%"
                      src="../../assets/shebei/sanxiangdianya.png"
                      alt=""
                      style="margin-left: 1.5rem"
                    />
                    <span style="font-size: 0.8rem">相电压</span>
                    <img
                      height="100%"
                      src="../../assets/shebei/a-Iconsindexdianya.png"
                      alt=""
                    />
                    <span style="font-size: 0.8rem">线电压</span>
                  </div>
                  <div class="diankiu02">
                    <div class="vv01">
                      <div style="height: 5%"></div>
                      <span class="vv0101">IA</span>
                      <span class="vv0102">{{ powerData.acurrent }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">IB</span>
                      <span class="vv0102">{{ powerData.bcurrent }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">IC</span>
                      <span class="vv0102">{{ powerData.ccurrent }}</span>
                    </div>
                    <div class="vv02">
                      <div style="height: 5%"></div>
                      <span class="vv0101">UA</span>
                      <span class="vv0102">{{ powerData.avoltage }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">UB</span>
                      <span class="vv0102">{{ powerData.bvoltage }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">UC</span>
                      <span class="vv0102">{{ powerData.cvoltage }}</span>
                    </div>
                    <div class="vv03">
                      <div style="height: 5%"></div>
                      <span class="vv0101">UBC</span>
                      <span class="vv0102">{{ powerData.cvoltage }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">UAB</span>
                      <span class="vv0102">{{ powerData.bvoltage }}</span>
                      <p class="p01"></p>
                      <div style="height: 8%"></div>
                      <span class="vv0101">UCA</span>
                      <span class="vv0102">{{ powerData.avoltage }}</span>
                    </div>
                  </div>
                </div>
                <div class="box" style="margin-left: 0.5rem">
                  <div class="dianliu09">
                    <img
                      height="100%"
                      src="../../assets/shebei/gongshuai.png"
                      alt=""
                    />
                    <span style="font-size: 0.8rem">功率</span>
                    <img
                      height="100%"
                      src="../../assets/shebei/zongyongdianliang.png"
                      alt=""
                      style="margin-left: 1rem"
                    />
                    <span style="font-size: 0.8rem">总用电量</span>
                  </div>

                  <div class="leftt01">
                    <div
                      class="vv01"
                      style="width: 10rem; margin-right: 0.1rem"
                    >
                      <div style="height: 5%"></div>
                      <span class="vv0101">PA</span>
                      <span class="vv0102">{{ powerData.aactivePower }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101">PB</span>
                      <span class="vv0102">{{ powerData.bactivePower }}</span>
                      <p class="p01"></p>
                      <div style="height: 8%"></div>
                      <span class="vv0101">PC</span>
                      <span class="vv0102">{{ powerData.cactivePower }}</span>
                    </div>
                    <div
                      class="vv01"
                      style="width: 11rem; margin-right: 0.1rem"
                    >
                      <div style="height: 5%"></div>
                      <span class="vv0101" id="ttt">变比值</span>
                      <span class="kk0102" style="margin-left: 2.3rem">{{
                        transformationRatio
                      }}</span>
                      <p class="p01"></p>
                      <div style="height: 5%"></div>
                      <span class="vv0101" id="ttt">显示用电量 </span>
                      <span class="kk0102">{{ ele }} <span>kw/h</span></span>
                      <p class="p01"></p>
                      <div style="height: 8%"></div>
                      <span class="vv0101" id="ttt">实际用电量</span>
                      <span class="kk0102">{{ energy }} <span>kw/h</span></span>
                    </div>
                    <!-- <div class="leftt03" tyle="margin-left: 0.5rem;">
                      <p>
                        <span style="font-size: 0.8rem">变比值</span>
                        <span id="dy" style="font-size: 0.8rem">{{
                          transformationRatio
                        }}</span>
                      </p>
                      <p>
                        <span style="font-size: 0.8rem">显示用电量 </span>
                        <span id="dy" style="font-size: 0.8rem">{{ ele }}</span>
                        <span style="font-size: 0.8rem">kw/h</span>
                      </p>
                      <p>
                        <span style="font-size: 0.8rem">实际用电量</span>
                        <span id="dy" style="font-size: 0.8rem">{{
                          energy
                        }}</span>
                        <span>kw/h</span>
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="smallLeft"
            style="
              display: flex;
              margin-top: 2.5rem;
              margin-left: 10rem;
              margin-bottom: 2rem;
            "
          >
            <div class="smallright">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                style="height: 57rem"
              >
                <el-table-column
                  type="selection"
                  width=""
                  header-align="center"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="回路名称"
                  width=""
                  prop="name"
                  header-align="center"
                  align="center"
                ></el-table-column>

                <el-table-column
                  prop=""
                  width=""
                  label="回路状态"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div
                      :class="scope.row.value ? 'green' : 'red'"
                      style="display: flex; justify-content: center"
                    ></div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  show-overflow-tooltip
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.value ? "启用" : "禁用" }}:
                    <el-switch
                      style="margin-left: 1vw"
                      v-model="scope.row.value"
                      active-color="#409eff"
                      inactive-color="gray"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div>
              <div class="leftTop">
                <div
                  style="margin-bottom: 3rem; width: 28rem; height: 26rem"
                  v-loading="loading"
                >
                  <img
                    id="previewImage"
                    :src="gz01"
                    alt=""
                    style="width: 28rem; height: 26rem"
                  />
                  <button
                    class="btn"
                    @click="handleUploadClick"
                    style="margin-bottom: 3rem"
                  >
                    <span>点击上传</span></button
                  >&nbsp;&nbsp;&nbsp;
                  <span style="color: #fff; margin-bottom: 3rem">更新时间:</span
                  >&nbsp;&nbsp;<span style="color: #fff">{{ this.gz02 }}</span>
                  <input
                    type="file"
                    id="fileInput"
                    style="display: none"
                    accept="image/*"
                    @change="handleFileChange"
                  />
                </div>
              </div>

              <div class="small" style="margin-top: 8rem">
                <img
                  src="../../assets/dianlu.png"
                  alt=""
                  style=""
                  class="fullscreen-image"
                />
              </div>
            </div>
          </div>

    
            <div class="rightbig01">
            <!-- **************************************************gz********************************************************************* -->
            <div
              style="
                display: flex;
                align-items: center;
                background-color: #222e3c;
                border-radius: 0 !important;
                margin-top: 2rem;
              "
            >
              <span style="color: white">查询时间</span>
              <div class="content">
                <el-radio-group
                  v-model="radio3"
                  size="small"
                  style="margin-left: 1.5vw; margin-right: 1.5vw"
                >
                  <el-radio-button label="日"></el-radio-button>
                  <el-radio-button label="月"></el-radio-button>
                  <el-radio-button label="年"></el-radio-button>
                  <!-- <el-radio-button label="自定义"></el-radio-button> -->
                </el-radio-group>

                <el-popover
                  v-if="radio3 === '日'"
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="pickerVisible"
                >
                  <el-date-picker
                    v-model="date"
                    type="date"
                    placeholder="选择日"
                    key="day"
                  >
                  </el-date-picker>
                  <!-- <el-button v-popover:pickerVisible>选择日</el-button> -->
                </el-popover>

                <el-popover
                  v-if="radio3 === '月'"
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="pickerVisible"
                >
                  <el-date-picker
                    v-model="date"
                    type="month"
                    placeholder="选择月"
                    key="month"
                  >
                  </el-date-picker>
                  <!-- <el-button v-popover:pickerVisible>选择月</el-button> -->
                </el-popover>

                <el-popover
                  v-if="radio3 === '年'"
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="pickerVisible"
                >
                  <el-date-picker
                    v-model="date"
                    type="year"
                    placeholder="选择年"
                    key="year"
                  >
                  </el-date-picker>
                  <!-- <el-button v-popover:pickerVisible>选择年</el-button> -->
                </el-popover>

                <el-popover
                  v-if="radio3 === '自定义'"
                  placement="bottom-start"
                  width="auto"
                  trigger="click"
                  v-model="pickerVisible"
                >
                  <el-date-picker
                    v-model="date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                  <!-- <el-button v-popover:pickerVisible>选择日期范围</el-button> -->
                </el-popover>
              </div>
              <el-button
                size="small"
                type="primary"
                style="margin-left: 1vw"
                @click="onSearch"
                >查询</el-button
              >
              <!-- <el-button size="small" type="primary" style="margin-left: 1vw"
          >深度分析</el-button
        > -->
            </div>
            <!-- gz********************************************************************************************************************* -->
            <el-tabs
              type="border-card"
              id="el01"
              @tab-click="handleTabClick"
              v-model="activeTab"
              v-loading="loading01"
              element-loading-text="loading..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              style="margin-top: -3rem;"
            >
              <el-tab-pane label="电流" name="current">
                <div class="dian00">
                  <RightTable :value="value" :dateData="dateData"></RightTable>
                </div>
              </el-tab-pane>
              <el-tab-pane label="电压" name="voltage">
                <div class="dian00">
                  <RightTwo :value="value" :dateData="dateData"></RightTwo>
                </div>
              </el-tab-pane>
              <el-tab-pane label="功率" name="power">
                <div class="dian00">
                  <RightThree :value="value" :dateData="dateData"></RightThree>
                </div>
              </el-tab-pane>

              <el-tab-pane label="功率因数" name="powerFactor">
                <div class="dian00">
                  <RightFour :value="value" :dateData="dateData"></RightFour>
                </div>
              </el-tab-pane>
              <el-tab-pane label="电度" name="energy">
                <div class="dian00">
                  <RightFive :value="value" :dateData="dateData"></RightFive>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatDates from "@/util";
import instance from "@/request/request";
import aTime from "./time.vue";
import RightEight from "./components/RightEight.vue";
import RightFive from "./components/RightFive.vue";
import RightFour from "./components/RightFour.vue";
import RightSeven from "./components/RightSeven.vue";
import RightSix from "./components/RightSix.vue";
import RightTable from "./components/RightTable.vue";
import RightThree from "./components/RightThree.vue";
import RightTwo from "./components/RightTwo.vue";
import { nextTick } from "vue";

export default {
  data() {
    return {
      valueData: [],
      pickerVisible: true,
      loading01:true,
      transformationRatio: "",
      energy: 0,
      type: 1,
      activeTab: "current",
      value: [],
      dateData: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00",
      ],
      tabName: "current",
      status: "",
      ele: 0,
      date: "2024-06-24",
      radio3: "日",
      powerData: [],
      number: "",
      gz02: "",
      gz01: "",
      dialogImageUrl: "",
      dialogVisible02: false,
      isFullscreen: false,
      loading: false,
      dialogShow: false,
      input: "",
      name: "",
      dialogVisible: false,
      input2: "",
      tableData: [],
      multipiblv1leSelection: [],
      filterText: "",
      data: [
        {
          id: 1,
          label: "示范项目",
          children: [
            {
              id: 4,
              label: "1#并网柜",
              children: [
                {
                  id: 9,
                  label: "1#NBQ",
                },
                {
                  id: 10,
                  label: "2#NBQ",
                },
                {
                  id: 11,
                  label: "3#NBQ",
                },
                {
                  id: 12,
                  label: "4#NBQ",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "2#并网柜",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "气象站",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
        {
          id: 4,
          label: "支架01",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  components: {
    aTime,
    RightTable,
    RightTwo,
    RightThree,
    RightFour,
    RightFive,
    RightSix,
    RightSeven,
    RightEight,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");

      this.date = `${year}-${month}-${day}`;

    },
    formatDate(date) {
      // 获取年份
      const year = date.getFullYear();

      // 获取月份，月份从0开始，所以要加1
      const month = String(date.getMonth() + 1).padStart(2, "0");

      // 获取日期
      const day = String(date.getDate()).padStart(2, "0");

      // 拼接成所需格式
      return `${year}-${month}-${day}`;
    },
    handleTabClick(tab) {
      this.tabName = tab.name;
      this.getDayData();
    },
    async getDayData() {
      this.loading01 = true
      const res = await instance({
        url: "/ammeter/getDataByStationIdAndTypeAndTime",
        params: {
          time: this.date,
          stationId: this.$route.query.stationId,
          type: this.type,
          funType: this.tabName,
        },
      });
      console.log(res.data.content.data, 789);
      this.valueData = res.data.content.data;
      this.value = res.data.content.data.map((item) => Object.values(item)[0]);
      if (this.radio3 == "日") {
        // this.value.pop();
        this.dateData = [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "24:00",
        ];
      } else if (this.radio3 == "月") {
        this.valueData.pop();
        this.dateData = this.valueData.map(
          (entry) => Object.keys(entry)[0].split(" ")[0]
        );
        console.log(this.dateData);
      } else if (this.radio3 == "年") {
        this.valueData.pop();
        this.dateData = this.valueData.map((entry) => {
          const date = Object.keys(entry)[0];
          return date.substring(0, 7);
        });
        console.log(this.dateData);
      }
      this.loading01 = false

    },
    async getEle() {
      const res = await instance({
        url: "/ammeter/getElectricData",
        params: {
          time: "2024-06-19",
          stationId: this.$route.query.stationId,
          type: 1,
        },
      });
      this.transformationRatio = res.data.content.transformationRatio;
      if (this.transformationRatio) {
        let ratioParts = res.data.content.transformationRatio.split(":");

        // 将字符串数组转换为数字数组
        let numerator = Number(ratioParts[0]);
        let denominator = Number(ratioParts[1]);
        let simplifiedRatio = numerator / denominator;
        this.ele = Object.entries(res.data.content.data[0])[0][1];
        this.energy = +this.ele * simplifiedRatio;
      } else {
        this.energy = this.ele;
      }
      console.log(Object.entries(res.data.content.data[0])[0][1], 99999999);
    },
    async onSearch() {
      if (this.radio3 == "日") {
        console.log(this.date);

        this.type = 1;
        this.date = this.formatDate(new Date(this.date));
        this.getDayData();
      } else if (this.radio3 == "月") {
        this.type = 2;
        this.date = this.formatDate(new Date(this.date));
        setTimeout(() => {
          this.getDayData();
        }, 0);
      } else if (this.radio3 == "年") {
        this.type = 3;
        this.date = this.formatDate(new Date(this.date));
        setTimeout(() => {
          this.getDayData();
        }, 0);
      }
    },
    async getPowerData() {
      const res = await instance({
        url: "/ammeter/getPowerData",
        params: {
          stationId: this.$route.query.stationId,
        },
      });
      this.powerData = res.data.content[0];
      console.log(res.data.content);
    },
    async getHistory() {
      const res = await instance({
        url: "/ammeter/getHistory",
        params: {
          stationId: this.$route.query.stationId,
        },
      });
      const history = res.data.content.filter((x) => x.message === "true");
      // this.number = history.length / res.data.content.length * 100
      this.number = res.data.content;
      this.status = res.data.content.slice(-1)[0].message;
      console.log(this.status, 666);
    },
    // async uploadFile(file) {
    //   console.log(file, "fffffffffffffsssssssssssssssssssssswwwwwwwwwwwww");
    //   try {
    //     // 创建一个 FormData 对象
    //     // const formData = new FormData();
    //     // // 将文件添加到 FormData 对象中
    //     // formData.append("file", file);
    //     // console.log(formData, "fdddddddddsssssssssssssss");
    //     // 设置请求参数
    //     const params = {
    //       stationId: 1, // 示例中的参数，根据实际情况进行修改
    //       file: file,
    //     };

    //     const res = await instance({
    //       url: `/ammeter/upload`,
    //       method: "post",
    //       data: params,
    //     });

    //     // 处理后端的响应
    //     console.log("上传成功：", res.data);
    //   } catch (error) {
    //     // 处理上传失败的情况
    //     console.error("上传失败：", error);
    //   }
    // },
    async gettu() {
      try {
        // 发送 POST 请求到后端
        const res = await instance.get(`/ammeter/getImage`, {
          params: {
            stationId: this.$route.query.stationId,
          },
        });

        // 处理后端的响应
        console.log(
          "上传成功：",
          res.data.content[0],
          "aaaaaaaaaaaaaaaaaaaaaaaaaaa"
        );
        this.gz01 = res.data.content[0].url;
        this.gz02 = res.data.content[0].updateTime;
        console.log(this.gz01, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
      } catch (error) {
        // 处理上传失败的情况
        console.error("上传失败：", error);
      }
    },
    async uploadFile(file) {
      this.loading = true;
      try {
        // 创建一个 FormData 对象
        const formData = new FormData();
        // 将文件添加到 FormData 对象中
        formData.append("file", file);
        // 将参数添加到 FormData 对象中
        formData.append("stationId", this.$route.query.stationId);
        // console.log(formData, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");

        // 发送 POST 请求到后端
        const res = await instance.post(`/ammeter/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // 处理后端的响应
        console.log("上传成功：", res.data);
        this.gettu();
        this.loading = false;
      } catch (error) {
        // 处理上传失败的情况
        console.error("上传失败：", error);
      }
    },
    handleUploadClick() {
      // 触发文件输入框的点击事件
      document.getElementById("fileInput").click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      console.log(file, "ttttttttttttttttttttttttt");
      this.uploadFile(file);

      if (file) {
        // 读取文件并显示在图片预览区域
        const reader = new FileReader();
        reader.onload = (e) => {
          document.getElementById("previewImage").src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible02 = true;
    },
    parseStringToObjectArray(inputString) {
      // 定义正则表达式模式，匹配每个对象的字符串
      var pattern = /\{.*?\}/g;

      // 使用正则表达式找到所有匹配项
      var matches = inputString.match(pattern);
      console.log(matches);
      // 定义结果数组
      var resultArray = [];

      // 遍历匹配到的字符串数组
      for (var i = 0; i < matches.length; i++) {
        // 将字符串解析为对象
        // var obj = JSON.parse(matches[i].replace(/'/g, '"'));
        // 将解析得到的对象添加到结果数组中
        resultArray.push(obj);
      }

      // 返回结果数组
      return resultArray;
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      // Toggle fullscreen class on body
      if (this.isFullscreen) {
        document.body.classList.add("fullscreen");
      } else {
        document.body.classList.remove("fullscreen");
      }
    },
    Delete(e) {
      this.tableData = this.tableData.filter((item) => item.id != e);
    },
    allOpen() {
      this.multipleSelection.forEach((selectedItem) => {
        // 在 tableData 中找到对应的数据并取反 value 属性
        const foundItem = this.tableData.find(
          (item) => item.id === selectedItem.id
        );
        if (foundItem) {
          foundItem.value = true;
        }
      });
    },
    allSwitch() {
      this.multipleSelection.forEach((selectedItem) => {
        // 在 tableData 中找到对应的数据并取反 value 属性
        const foundItem = this.tableData.find(
          (item) => item.id === selectedItem.id
        );
        if (foundItem) {
          foundItem.value = false;
        }
      });
    },
    allDelete() {
      this.tableData = this.tableData.filter(
        (item) => !this.multipleSelection.includes(item)
      );
    },
    handleSuffixClick() {
      this.tableData = this.data;
      this.tableData = this.tableData.filter((x) =>
        x.name.includes(this.input2)
      );
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
  mounted() {
    this.getCurrentTime()
    this.getEle();
    this.$nextTick(() => {
      // 获取 el-tabs 组件实例
      const tabs = this.$refs.el01;
      // 找到默认激活的选项卡信息
      const defaultTab = { label: "电流", name: "current" };
      // 手动触发点击事件
      this.handleTabClick(defaultTab);
    });

    this.getHistory();
    this.getPowerData();
    this.gettu();
  },
};
</script>
<!-- import ElementUI from 'element-ui'; -->
<style lang="scss" scoped>
.smallLeft {
  width: 30vw;
  height: 102vh;
  margin-top: 1vh;
  margin-left: 2vw;

  // background-color: #fff;
  .leftTop {
    display: flex;
    width: 100%;
    height: 23rem;

    img {
      width: 55vw;
      height: 30rem;
      border: 1px solid #333;
      border-radius: 10px;
    }
  }
}

.smallright {
  // flex: 1;
  // background-color: pink;

  margin-right: 2vw;
  // height: 40rem;
  .el-table {
    border: 1px solid #333;
    // border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }
}

#table06 {
  width: 100%;
  height: 250vh;
  background-color: black !important;
  display: flex;
}

.left {
  width: 18%;
  height: 100%;
  background-color: #222e3c;
}

.right {
  width: 100vw;
  // margin-left: 1%;
  // background-color: skyblue;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.top {
  width: 100%;
  background-color: skyblue;
  height: 7%;
}

.tabs-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.tab-buttons {
  display: flex;
  background-color: #1b2232;
  color: white;
  width: 400px;
  height: 100%;
}

.tab-button {
  flex: 1;
  padding: 5px;
  text-align: center;
  cursor: pointer;

  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: #3c4856;
}

.tab-content {
  flex: 1;
  padding: 20px;
  background-color: #1b2232;
  color: white;
  display: none;
}

.tab-content.active {
  display: block;
}

.left ::v-deep .el-input input {
  background-color: #3c4856;
  border: none;
}

.left ::v-deep .el-input {
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
}

.left ::v-deep .el-tree {
  background-color: #222e3c;
}

.righttop {
 
  background-color: #222e3c;
}

.righttop .el-tabs {
  height: 100%;
}

.righttop .el-tabs ::v-deep .el-tabs__content {
  height: 200% !important;
  background-color: #1b2232;
}

.ssgl {
  width: 100%;
  height: 3rem;

  background-color: #1b2232 !important;
}

.ssgl01 {
  font-size: 0.9rem;
  color: #fff;
  margin-top: 10px;
  display: inline-block;
  margin-left: 10px;
}

.ssgl02 {
  display: inline-block;
  margin-left: 0.6rem;
  width: 12.5rem;
  height: 40%;
  vertical-align: middle;
  background-color: #222e3c;
  // background-image: url(../../assets/121906.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
}

#ck {
  margin-left: 75% !important;
}

.topfirst01 {
  width: 100%;
  height: 7vh;
  // background-color: #1b2232;
  display: flex;
  margin-bottom: 10px;
}

.topfirst01 > div {
  width: 12%;
  height: 100%;
  background: #263646;
  margin-left: 0.5%;
  border-radius: 3px;
  position: relative;
}

.topfirst01 > div span:first-child {
  color: #fff;
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}

.topfirst01 > div span:nth-child(2) {
  color: red;
  font-size: 20px;
}

.on01 {
  color: red;
  font-size: 1rem;
  position: absolute;
  top: 0;
  top: 40%;
  left: 60%;
}
.on {
  color: #67c23a;
}
.off {
  color: red;
}
.on01 font {
  font-size: 1rem;
}

.font {
  color: #1f8967;
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dianliu01,
.diankiu02 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

// .diankiu02 {
//   margin-top: 20px;
//   /* 调整垂直间距 */
// }

.dianliu01 {
  width: 30rem;
  height: 3vh;
  background-color: #222e3c;
  vertical-align: middle;
}

.dianliu09 {
  width: 27rem;
  height: 3vh;
  background-color: #222e3c;
  vertical-align: middle;
}

.dianliu09 span {
  color: #96a6be;
  vertical-align: middle;
  margin-right: 21%;
}

.dianliu09 img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.dianliu01 span {
  color: #96a6be;
  vertical-align: middle;
  margin-right: 21%;
}

.dianliu01 img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.diankiu02 {
  display: flex;
  width: 100%;
  height: 11vh;
  background-color: #21313e;
  justify-content: space-between;
}

.diankiu02 > div {
  width: 33%;
  height: 120%;
  background-color: skyblue;
}

.vv01 {
  background-color: #283848 !important;
  background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.vv02 {
  background-color: #283848 !important;
  background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.vv03 {
  background-color: #283848 !important;
  background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.vv03 span,
.vv02 span,
.vv01 span {
  color: #fff;
  font-size: 12px;
}

.vv0101 {
  font-size: 0.9rem !important;
  margin-top: 2%;
  display: inline-block;
  margin-left: 0.6rem;
}

.vv0102 {
  font-size: 0.9rem !important;
  display: inline-block;
  margin-left: 45%;
}
.kk0102 {
  font-size: 0.9rem !important;
  display: inline-block;
  margin-left: 5%;
}

.rightbig01 > div {
  border-radius: 0.5rem !important;
}

.big {
  display: flex;
  width: 80rem;
  height: 48vh;
}

.leftbig01 {
  width: 50%;
}

.rightbig01 {
  margin: 0 auto;
  // margin-top: 20rem !important;

  width: 80%;
  // height:50rem;
  background-color: #222e3c;
  border-radius: 0.5rem !important;
  margin-top: 8rem !important;
}

.leftt01 {
  width: 80%;
  height: 13vh;
  background-color: #1f2f3c;
  display: flex;
  justify-content: space-between;
}

.leftt02 {
  width: 12rem;
  height: 100%;
  background-color: #283848 !important;
  background-size: cover;
  position: relative;
  margin-right: 0.5rem;
}

.leftt02 span {
  color: #fff;
  margin-top: 13%;
  display: inline-block;
  margin-left: 3%;
}

.leftt02 span:nth-child(2) {
  margin-left: 43%;
}

.leftt03 {
  width: 14rem;
  height: 100%;
  background-color: #283848 !important;
  background-size: cover;
  position: relative;
}

.leftt03 {
  color: #fff;
}

.leftt03 p > span {
  margin-left: 5%;
}

.leftt03 p > span:nth-child(2) {
  margin-left: 2%;
}

#dy {
  margin-left: 40px;
}

.dian00 {
  width: 100%;
  height: 40.2vh;
  background-color: #222e3c !important;
}

.di01 {
  display: flex;
  width: 100%;
  height: 10vh;
  background-color: #1f2b39;
  justify-content: space-between;
}

#el01 ::v-deep .el-tabs__content {
  height: 400px !important;
}

.di01 > div {
  width: 12%;
  height: 100%;
  background-color: #222e3c;
  background-image: url(../../assets/122017.png);
  background-size: cover;
  position: relative;
}

.zc {
  color: #fff;

  display: inline-block;
  position: absolute;
  left: 30px;
}

.zc01 {
  color: #51baac;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 30px;
}

.p01 {
  width: 100%;
  display: block;
  margin-top: 2%;
  border-bottom: 1px solid #1f2f3c;
  margin: 0 !important;
}

.zc02 {
  color: #bac62e;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 60px;
}

.dilast {
  width: 100%;
  height: 10vh;
  display: flex;
  background-color: #222e3c;
  justify-content: space-between;
}

.dilast > div {
  width: 49.8%;
  height: 100%;
  background-color: #1f8967;
  background-image: url(../../assets/122018.png);
  background-size: cover;
}

.dilast01 {
  position: relative;
}

.dilast02 > span,
.dilast01 > span {
  color: #fff;
  display: inline-block;
}

.dilast02 span:first-child,
.dilast01 span:first-child {
  left: 55px;
  position: absolute;
  top: 30px;
}

.dilast02 span:nth-child(2),
.dilast01 span:nth-child(2) {
  left: 55px;
  position: absolute;
  top: 60px;
}

.dilast02 span:nth-child(3),
.dilast01 span:nth-child(3) {
  left: 198px;
  position: absolute;
  top: 60px;
}

.dilast02 span:nth-child(4),
.dilast01 span:nth-child(4) {
  left: 275px;
  position: absolute;
  top: 60px;
  color: #1f8967;
}

.dilast02 span:nth-child(5),
.dilast01 span:nth-child(5) {
  left: 450px;
  position: absolute;
  top: 60px;
  color: #fff;
}

.dilast .dilast02 {
  background-image: url(../../assets/122101.png);
  background-size: cover;
  position: relative;
}

.el-table {
  margin-left: 2vw;
  width: 40vw;
  /* 在这里设置宽度 */
}

.el-table__header-wrapper {
  display: flex;
  justify-content: center;
}
.green01 {
  background-color: #67c23a !important;
}

.green {
  width: 15px;
  height: 15px;
  background-color: #67c23a;
  /* 设置背景颜色 */
  border-radius: 50%;
}

.red {
  width: 15px;
  height: 15px;
  background-color: red;
  /* 设置背景颜色 */
  border-radius: 50%;
}

.fullscreen-image {
  cursor: pointer;
  width: 30vw;
  height: 26rem;
  border: 1px solid #333;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  /* Add box-shadow for the shadow effect */
  // transition: box-shadow 0.3s ease;
  /* Add any other styles for the image */
}

.sfullscreen-image:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  /* Increase box-shadow on hover */
}

.righttop .el-tabs {
  border: 10px solid #fff;
}

::v-deep .el-progress-bar__inner {
  border-radius: 6.2rem;
}

::v-deep .element.style {
  height: 1.6rem;
}

::v-deep .el-progress-bar__outer {
  height: 1.3rem !important;
  width: 15rem !important;
  border-radius: 6.2rem;
}

::v-deep .element.style {
  height: 1.5rem;
}

::v-deep .el-progress-bar__innerText {
  font-size: 0.6rem;
  margin: 0 0.3rem;
}
.btn {
  margin-top: 0.5rem;
  width: 4rem;
  height: 2rem;
  border-radius: 1rem;
}
::v-deep .el-table__row:hover {
  background-color: #f5f7fa !important;
}

::v-deep .el-table__row--highlight-current {
  background-color: #e6f7ff !important;
}

::v-deep .el-table__header {
  background-color: #f0f5ff !important;
}

::v-deep .el-table th .cell {
  color: #fff !important;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 2.5rem rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
  }
}

.el-table {
  border: 0.5rem solid #d0e6f3 !important;
  border-radius: 1rem;
  animation: glow 6s infinite alternate;
}

::v-deep .el-switch__core {
  border-color: #409eff !important;
}

::v-deep .el-switch__core--active {
  background-color: #409eff !important;
}

::v-deep .el-table tr {
  background-color: #1b2232;
  color: #fff;
}
::v-deep .el-table tr:hover {
  color: #111010 !important;
}

::v-deep .el-table th.el-table__cell {
  background-color: #44515c;
}
::v-deep .el-table__empty-block {
  background-color: #1b2232;
  height: 57rem !important;
}
::v-deep .el-table .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topfirst0103 {
  width: 8rem !important;
  height: 16.35vh !important;
  margin-right: 14.2rem !important;
  z-index: 60;
}
.gz01 {
  width: 100%;
  height: 1.5rem;
  background-color: #222e3c;
}
.img00 {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}
.leftt01 .vv01 {
  transform: translateX(-5px);
}
#el01 {
  transform: translateY(3rem);
}
.content {
  margin-left: 1rem;
}

::v-deep .date-picker-container {
  position: relative;
}

::v-deep .el-date-picker-dropdown {
  position: absolute;
  z-index: 1000;
}

.content {
  padding: 20px;
  // background-color: #f5f5f5;
}

.el-button {
  margin-top: 10px;
}

::v-deep .el-popover {
  background-color: transparent;
  border: none;
  position: absolute;
  position: static;
  display: inline-block;
}

::v-deep .el-radio-button__inner {
  border: none;
}
// #ttt {
//   font-size: 0.6rem !important;
// }
</style>