import axios from 'axios';
import Cookie from 'js-cookie'
  export function   getSwitchValueFromCookie(vm) {
     // 从Cookie中获取switchValue的值并赋给switchValue属性
     const cookies = document.cookie.split(';');
     for (let i = 0; i < cookies.length; i++) {
       const cookie = cookies[i].trim();
       if (cookie.startsWith('switchValue=')) {
         const cookieValue = cookie.substring('switchValue='.length);
         vm.switchValue = JSON.parse(cookieValue);
         break;
       }
     }
  } 
  export function getRoleFromCookie(vm) {
    // 从Cookie中获取role的值并赋给role属性
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('role=')) {
        const cookieValue = cookie.substring('role='.length);
        vm.role = cookieValue;
        break;
      }
    }
  }  
  export function  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
 } 

//天气
  export function  sendLocation(latitude, longitude) {
    axios.get('http://120.224.50.45:3333/weatherdata', {
        params: {
          latitude: latitude,
          longitude: longitude
        }
      })
        .then(response => {
          // 在此处处理天气数据
          const weatherData = response.data;
          const weatherDiv = document.getElementById('weatherData');
          const maxTemperaturedata = weatherData.result.daily.temperature_08h_20h[0].max;
          const humiditydata = weatherData.result.daily.humidity[0].avg*100;
          const minTemperaturedata = weatherData.result.daily.temperature_08h_20h[0].min;
          const avgTemperaturedata = weatherData.result.daily.temperature_08h_20h[0].avg;
          const skycondata = weatherData.result.daily.skycon[0].value;
          const comfortdata = weatherData.result.daily.life_index.comfort[0].desc;
          const coldriskdata = weatherData.result.daily.life_index.coldRisk[0].desc;
          const ultravioletdata = weatherData.result.daily.life_index.ultraviolet[0].desc;
          var tianqi = " ";
          if (skycondata === "CLEAR_DAY") {
            tianqi = "晴天";
          } else if (skycondata === "MODERATE_RAIN") {
            tianqi = "中雨";
          } else if (skycondata === "PARTLY_CLOUDY_DAY") {
            tianqi = "多云";
          } else if (skycondata === "CLOUDY") {
            tianqi = "阴";
          } else if (skycondata === "LIGHT_RAIN") {
            tianqi = "小雨";
          } else if (skycondata === "HEAVY_RAIN") {
            tianqi = "大雨";
          } else if (skycondata === "LIGHT_SNOW") {
            tianqi = "小雪";
          } else if (skycondata === "MODERATE_SNOW") {
            tianqi = "中雪";
          } else if (skycondata === "HEAVY_SNOW") {
            tianqi = "大雪";
          } else if (skycondata === "STORM_SNOW") {
            tianqi = "暴雪";
          } else if (skycondata === "STORM_RAIN") {
            tianqi = "暴雨";
          } else if (skycondata === "DUST") {
            tianqi = "浮尘";
          } else if (skycondata === "SAND") {
            tianqi = "沙尘";
          } else if (skycondata === "WIND") {
            tianqi = "大风";
          } else if (skycondata === "LIGHT_HAZE") {
            tianqi = "轻度雾霾";
          } else if (skycondata === "MODERATE_HAZE") {
            tianqi = "中度雾霾";
          }else if (skycondata === "HEAVY_HAZE") {
            tianqi = "重度雾霾";
          }
          weatherDiv.innerHTML = `
                 <tr>
                  <td>天气</td>
                  <td>${tianqi}</td>
                </tr>
                <tr>
                  <td>最高温度</td>
                  <td>${maxTemperaturedata}℃</td>
                </tr>
                <tr>
                  <td>最低温度</td>
                  <td>${minTemperaturedata}℃</td>
                </tr>
                <tr>
                  <td>湿度</td>
                  <td>${humiditydata}%</td>
                </tr>
                <tr>
                  <td>舒适度</td>
                  <td>${comfortdata}</td>
                </tr>
                <tr>
                  <td>紫外线强度</td>
                  <td>${ultravioletdata}</td>
                </tr>
                <tr>
                  <td>感冒易发程度</td>
                  <td>${coldriskdata}</td>
                </tr>
      `;
        })
        .catch(error => {
          console.error('获取天气数据出错:', error);
        });
  } 