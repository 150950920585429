
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

//创建vuex的实例
export default new Vuex.Store({
  state: {
    tabsList: [],//面包屑的数据
    isCollapse: false,//用于控制菜单的展开还是收起
    asideShow: false,
    username: '',
    menu: [],
    parentmenu: '/shouye',
    LargeScreendata: true,
    mapdata: []
  },
  mutations: {
    ChangeLargeScreen(state) {
      state.LargeScreendata = !state.LargeScreendata
    },
    BackLargeScreen(state) {
      state.LargeScreendata = true
    },
    selectparentmenu(state, val) {
      state.parentmenu = val
    },
    //修改菜单展开收起的方法
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse
      state.asideShow = !state.asideShow
    },
    //更新面包屑数据
    selectMenu(state, val) {
      //判断添加的数据是否为首页
      if (val.name !== 'home') {
        const index = state.tabsList.findIndex(item => item.name === val.name)
        //如果不存在
        if (index === -1) {
          //向tabsList中加入数据
          state.tabsList.push(val)
        }
      }
    },
    //清空面包屑
    deletMenu(state) {
      state.tabsList = []
    },
    //删除指定的tag数据
    closeTag(state, item) {
      const index = state.tabsList.findIndex(val => val.name === item.name)
      state.tabsList.splice(index, 1)
    },
    updateMenu(state, username) {
      if (username !== '') {
        state.menu = [
          // {
          //   path: '/access-denied',
          //   name: 'denied',
          //   label: "权限错误",
          //   icon: "el-icon-s-home",
          //   url: "denied.vue",
          //   showdata: "0",
          // },
          {
            path: '/login',
            name: 'Login',
            label: "登录",
            icon: "el-icon-s-home",
            url: "Login.vue",
            showdata: "0",
          },
          {
            path: '/screen',
            name: 'largescreen',
            label: "数据可视化大屏",
            icon: "el-icon-s-home",
            url: "largescreen.vue",
            showdata: "0",
          }, {
            path: "/shouye",
            name: "shouye",
            label: "首页",
            icon: "el-icon-s-home",
            url: "shouye.vue",
            showdata: "1",
            children: [
              {
                path: "/shouye/home",
                name: "Home",
                label: "首页",
                icon: "el-icon-s-home",
                url: "Home.vue",
                showdata: "0",
              }
            ]
          },
          {
            path: '/tongji',
            name: 'tongji',
            label: '配电室用电量统计',
            icon: 'el-icon-s-data',
            url: 'tongji.vue',
            showdata: "1",
            // children: [
            //   {
            //     path: '/tongji/electricalenergy',
            //     name: 'electricalEnergy',
            //     label: '电能',
            //     icon: 'el-icon-stopwatch',
            //     url: 'electricalEnergy.vue',
            //     showdata: "1",
            //   },
            // ]
          },
          {
            path: "/shebei",
            name: "shebei",
            label: "设备",
            icon: "el-icon-s-opportunity",
            url: "shebei.vue",
            showdata: "1",
            // children: [
            //   {
            //     path: "/shebei/shebeishow",
            //     name: "shebei",
            //     label: "设备信息",
            //     icon: "el-icon-mobile-phone",
            //     url: "shebeishow.vue",
            //     showdata: "1",
            //   },
            //   {
            //     path: "/shebei/boxing",
            //     name: "boxing",
            //     label: "设备组箱",
            //     icon: "el-icon-share",
            //     url: "设备.vue",
            //     showdata: "1",
            //   },
            //   {
            //     path: "/shebei/guanli",
            //     name: "guanli",
            //     label: "设备管理",
            //     icon: "el-icon-mobile-phone",
            //     url: "shebeiguanli.vue",
            //     showdata: "1",
            //   },
            //   {
            //     path: "/shebei/toushitu",
            //     name: "toushitu",
            //     label: "透视图",
            //     icon: "el-icon-s-operation",
            //     url: "toushitu.vue",
            //     showdata: "1",
            //   },
            //   {
            //     path: "/shebei/dangan",
            //     name: "dangan",
            //     label: "设备档案",
            //     icon: "el-icon-s-operation",
            //     url: "dangan.vue",
            //     showdata: "1",
            //   }
            // ]
          },
          // {
          //   path: '/emission',
          //   name: 'emission',
          //   label: '碳排放',
          //   icon: 'el-icon-data-line',
          //   url: 'emission.vue',
          //   showdata: "1",
          //   children: [
          //     {
          //       path: '/emission/emissionYOY',
          //       name: 'emissionYOY',
          //       label: '设备碳排-同比',
          //       icon: 'el-icon-sort',
          //       url: 'emissionYOY.vue',
          //       showdata: "1",
          //     }, {
          //       path: '/emission/emissionQOQ',
          //       name: 'emissionQOQ',
          //       label: '设备碳排-环比',
          //       icon: 'el-icon-refresh',
          //       url: 'emissionQOQ.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/emission/carbonemissions',
          //       name: 'carbonEmissions',
          //       label: '碳排数据',
          //       icon: 'el-icon-partly-cloudy',
          //       url: 'carbonEmissions.vue',
          //       showdata: "1",
          //     }, {
          //       path: '/emission/carbonreport',
          //       name: 'carbonreport',
          //       label: '碳排报表',
          //       icon: 'el-icon-date',
          //       url: 'CarbonReport.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/emission/tanliutu',
          //       name: 'tanliutu',
          //       label: '碳硫图',
          //       icon: 'el-icon-partly-cloudy',
          //       url: 'tanliutu.vue',
          //       showdata: "1",
          //     }, {
          //       path: '/emission/tanpaifang',
          //       name: 'tanpaifang',
          //       label: '碳排放数据',
          //       icon: 'el-icon-date',
          //       url: 'tanpaifang.vue',
          //       showdata: "1",
          //     },
          //   ]
          // },
          // {
          //   path:'/ceshi',
          //   name:'ceshi',
          //   label:'测试',
          //   icon: 'el-icon-data-analysis',
          //   url: 'ceshi.vue',
          //   showdata: "1",
          //   children:[
          //     {
          //       path: '/ceshi/yihao',
          //       name: 'yihao',
          //       label: '测试',
          //       icon: 'el-icon-sort',
          //       url: 'child.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/ceshi/jiade',
          //       name: 'jiade',
          //       label: '假的',
          //       icon: 'el-icon-sort',
          //       url: 'jiade.vue',
          //       showdata: "1",
          //     }
          //   ]

          // },//TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT
          // {
          //   path: '/dataanalysis',
          //   name: 'dataanalysis',
          //   label: '数据分析',
          //   icon: 'el-icon-data-analysis',
          //   url: 'dataanalysis.vue',
          //   showdata: "1",
          //   children: [
          //     {
          //       path: '/dataanalysis/YOY',
          //       name: 'YOY',
          //       label: '设备详细数据-同比',
          //       icon: 'el-icon-sort',
          //       url: 'YOY.vue',
          //       showdata: "1",
          //     }, {
          //       path: '/dataanalysis/QOQ',
          //       name: 'QOQ',
          //       label: '设备详细数据-环比',
          //       icon: 'el-icon-refresh',
          //       url: 'QOQ.vue',
          //       showdata: "1",
          //     }, {
          //       path: '/dataanalysis/baobiao',
          //       name: 'baobiao',
          //       label: '月度报表',
          //       icon: 'el-icon-refresh',
          //       url: 'baobiao.vue',
          //       showdata: "1",
          //     }
          //   ]
          // },
          {
            path: '/xunjian',
            name: 'xunjian',
            label: '巡检页',
            icon: 'el-icon-camera',
            url: 'xunjian.vue',
            showdata: "1",
            children: [
              {
                path: '/xunjian/pingmian',
                name: 'pingmian',
                label: '平面巡检图',
                icon: 'el-icon-school',
                url: 'pingmian.vue',
                showdata: "1",
              }, {
                path: '/xunjian/liti',
                name: 'liti',
                label: '立体巡检图',
                icon: 'el-icon-office-building',
                url: 'liti.vue',
                showdata: "1",
              }
            ]
          },
          // {
          //   path: '/excel',
          //   name: 'excel',
          //   label: '表单',
          //   icon: 'el-icon-setting',
          //   url: 'excel.vue',
          //   showdata: "1",
          //   children: [
          //     {
          //       path: '/excel/createxcel',
          //       name: 'createxcel',
          //       label: '填写表单',
          //       icon: 'el-icon-edit-outline',
          //       url: 'createxcel.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/excel/export',
          //       name: 'Export',
          //       label: '导出表单',
          //       icon: 'el-icon-notebook-2',
          //       url: 'export-excel.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/excel/upload',
          //       name: 'Upload',
          //       label: '导入表单',
          //       icon: 'el-icon-notebook-1',
          //       url: 'upload-excel.vue',
          //       showdata: "1",
          //     }
          //   ]
          // },
          //展会
          // {
          //   path: '/duanluqi',
          //   name: 'duanluqi',
          //   label: '断路器',
          //   icon: 'el-icon-set-up',
          //   url: 'duanluqi.vue',
          //   showdata: "1",
          //   children: [
          //     {
          //       path: '/duanluqi/haiwei',
          //       name: 'haiwei',
          //       label: '海为-断路器',
          //       icon: 'el-icon-stopwatch',
          //       url: 'haiwei.vue',
          //       showdata: "1",
          //     },
          //     {
          //       path: '/duanluqi/dgxb',
          //       name: 'dgxb',
          //       label: '串口-断路器',
          //       icon: 'el-icon-discover',
          //       url: 'dgxb.vue',
          //       showdata: "1",
          //     }
          //   ]
          // },
          // {
          //   path: '/ping',
          //   name: 'ping',
          //   label: '系统架构诊断',
          //   icon: 'el-icon-warning-outline',
          //   url: 'zhenduan.vue',
          //   showdata: "1",

          // },
          {
            path: '/houtai',
            name: 'houtai',
            label: '后台设置',
            icon: 'el-icon-s-tools',
            url: 'houtai.vue',
            showdata: "1",
            children:[
              {
                path: '/houtai/setting',
                name: 'setting',
                label: '设置层级',
                icon: 'el-icon-warning-outline',
                url: 'setting.vue',
                showdata: "1",
              },
              {
                path: '/houtai/permissions',
                name: 'permissions',
                label: '角色权限',
                icon: 'el-icon-warning-outline',
                url: 'permissions.vue',
                showdata: "1",
              },
            ]
          },
          {
            path: "/monitor",
            name: "Home",
            label: "监测设备",
            icon: "el-icon-s-home",
            url: "monitor.vue",
            showdata: "1",
          }
        ];
      }


    }
  },
  updataMapdata(state, mapdata) {
    // 使用 push 方法将新数据添加到 mapdata 数组中
    state.mapdata.push(...mapdata);
  },
  downdataMapdata(state) {
    // 使用 push 方法将新数据添加到 mapdata 数组中
    state.mapdata = [];
  },
  plugins: [createPersistedState()],
})