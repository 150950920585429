<template>
  <div id="map1"></div>
</template>
<script>
import * as echarts from "echarts";
import china from "echarts/map/json/china.json";
echarts.registerMap("china", china);
export default {
  name: "MapTable",
  data() {
    return {};
  },
  methods: {
    map() {
      var chartDom = document.getElementById("map1");
      var myChart = echarts.init(chartDom);
      var geoCoordMap = {};
      var GZData = [];
      this.chartData = [
        {
          sf: "山东",
          jwd: " 117.1582, 36.8701",
          sl: 360570.0,
        },
        {
          sf: "安徽",
          jwd: " 117.29, 32.0581",
          sl: 358122.0,
        },
        {
          sf: "湖北",
          jwd: " 114.3896, 30.6628",
          sl: 132600.0,
        },
        {
          sf: "浙江",
          jwd: " 119.5313, 29.8773",
          sl: 48000.0,
        },
        {
          sf: "广东",
          jwd: " 113.12244, 23.009505",
          sl: 31110.0,
        },
        {
          sf: "辽宁",
          jwd: " 123.1238, 42.1216",
          sl: 30500.0,
        },
        {
          sf: "四川",
          jwd: " 103.9526, 30.7617",
          sl: 24684.0,
        },
        {
          sf: "福建",
          jwd: " 119.4543, 25.9222",
          sl: 21063.0,
        },
        {
          sf: "黑龙江",
          jwd: "127.9688, 45.368",
          sl: 17500.0,
        },
        {
          sf: "西藏",
          jwd: " 91.117212,29.646922",
          sl: 6120.0,
        },
        {
          sf: "江西",
          jwd: " 116.0046, 28.6633",
          sl: 5100.0,
        },
        {
          sf: "广西",
          jwd: " 108.479, 23.1152",
          sl: 5100.0,
        },
        {
          sf: "河南",
          jwd: " 113.4668, 34.6234",
          sl: 5000.0,
        },
        {
          sf: "青海",
          jwd: " 101.4038, 36.8207",
          sl: 1020.0,
        },
      ];
      this.chartData.forEach((item) => {
        let arr = item.jwd.split(",");
        geoCoordMap[item.sf] = [Number(arr[0]), Number(arr[1])];
        GZData.push([{ name: "江苏" }, { name: item.sf, value: 90 }]);
      });
      geoCoordMap["江苏"] = [119.932446, 32.460919];

      var convertData = function (data) {
        //用于转换成需要的特定格式数据
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = geoCoordMap[dataItem[0].name];
          var toCoord = geoCoordMap[dataItem[1].name];
          if (fromCoord && toCoord) {
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              coords: [fromCoord, toCoord],
            });
          }
        }
        return res;
      };

      var series = [];
      [["江苏", GZData]].forEach(function (item) {
        series.push(
          {
            name: item[0] + " Top10",
            type: "lines",
            zlevel: 1,
            symbol: ["circle", "circle"],
            symbolSize: 10,
            effect: {
              show: true,
              period: 3, // 特效动画的时间
              trailLength: 0.3, // 特效尾迹的长度。取从 0 到 1 的值，默认为 0.2，数值越大尾迹越长
              // color: '#fff',
              // symbol: planePath, // 特效图形的标记
              //   symbolSize: 3,
              symbol: "arrow", //箭头图标
              symbolSize: 5, //图标大小
            },
            lineStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    "#F64347",
                    "#04CF68",
                    "#04B4F6",
                    "#E9CC08",
                    "#FB9E19",
                  ];
                  return colorList[params.dataIndex % 5];
                },
                width: 2, //尾迹线条宽度
                opacity: 0.7, //尾迹线条透明度
                curveness: -0.2, //尾迹线条曲直度
              },
            },
            data: convertData(item[1]),
          },
          {
            name: item[0] + " Top10",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 1,
            rippleEffect: {
              brushType: "stroke",
              period: 4, //动画时间，值越小速度越快
              scale: 4, //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: false,
                position: "right",
                formatter: "{b}",
                textStyle: {
                  fontSize: 14,
                  color: "#fff",
                },
              },
            },
            symbol: "none",
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[1].name,
                value: geoCoordMap[dataItem[1].name].concat([
                  dataItem[1].value,
                ]),
              };
            }),
          }
        );
      });
      var option01;
      option01 = {
        geo: {
          map: "china",
          aspectScale: 0.88,
          layoutCenter: ["35%", "50%"], //地图位置
          layoutSize: "100%",
          zoom: 1, //当前视角的缩放比例
          // roam: true, //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1,
            max: 2,
          },
          label: {
            show: true,
            color: "#C6F5FE",
            fontSize: 12,
          },
          itemStyle: {
            normal: {
              areaColor: "RGBA(17, 75, 94, 1)",
              borderColor: "rgba(152, 234, 255, 1)",
              borderWidth: 0.5,
              shadowColor: "rgba(59,202,251,0.5)",
              shadowOffsetX: 6, // 折线的X偏移
              shadowOffsetY: 12, // 折线的Y偏移
              shadowBlur: 6, // 折线模糊
            },
            emphasis: {
              areaColor: "RGBA(7, 62, 86, 1)",
              label: {
                color: "#fff",
              },
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                areaColor: "rgba(0, 10, 52, 1)",
                borderColor: "rgba(0, 10, 52, 1)",
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                    color: "#009cc9",
                  },
                },
              },
              label: {
                show: false, // 隐藏文字
              },
            },
          ],
        },
        series: series,
      };
      option01 && myChart.setOption(option01);
      console.log(chartDom, "99999999999");
      // 监听窗口大小变化，调整地图大小和比例
      window.addEventListener('resize', () => {
        myChart.resize();
      });

    },
  },
  mounted() {
    this.map();
  },
};
</script>
<style lang="scss" scoped>
#map1 {
  width: 51rem;
  height: 100%;
}
</style>