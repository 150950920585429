<template>
  <div
    class="big"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      style="
        height: 6vh;
        display: flex;
        align-items: center;
        margin-left: 2vw;
        width: 20vw;
      "
    >
      <!-- <el-button type="primary" plain style="margin-right: 2vw"
        >添加权限</el-button
      > -->
      <!-- <el-input placeholder="请输入权限名称" v-model="input2">
        <template v-slot:append>
          <i class="el-icon-search" @click="handleSuffixClick"></i>
        </template>
      </el-input> -->
    </div>
  <div style="
        display: flex;
        justify-content: center; /* Horizontally center content */
        align-items: center;
      ">
    <div style="width: 70rem;" class="table">
      <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" width=""></el-table-column> -->
      <el-table-column label="权限名称" width="" prop="name"></el-table-column>
      <el-table-column prop="show" label="权限说明" width=""> </el-table-column>
      <!-- <el-table-column prop="address" label="权限状态" show-overflow-tooltip>
        <template slot-scope="scope"
          >{{ scope.row.value ? "启用" : "禁用" }}:
          <el-switch
            style="margin-left: 1vw"
            v-model="scope.row.value"
            active-color="#409eff"
            inactive-color="gray"
          >
          </el-switch
        ></template>
      </el-table-column> -->
      <el-table-column label="操作" width="300">
        <template v-slot="{ row }">
          <span
            style="color: #409eff; cursor: pointer"
            @click="onRole(row.name,row.id)"
            >权限成员</span
          >
          <!-- <span
            style="margin-right: 2vw; color: #13ce66; cursor: pointer"
            @click="onPermissions(row.id)"
            >角色权限</span
          > -->
          <!-- <span style="color: #ff4949; cursor: pointer" @click="Delete(row.id)"
            >删除</span
          > -->
        </template>
      </el-table-column>
    </el-table>
    </div>
  </div>
    <!-- <div class="footer">
      <el-button type="primary" @click="allOpen">批量启用</el-button>
      <el-button type="warning" @click="allSwitch">批量禁用</el-button>
      <el-button type="danger" @click="allDelete">批量删除</el-button>
    </div> -->
    <el-dialog
      title="权限成员管理"
      :visible.sync="dialogVisible"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <el-alert
          title="用户加入到权限后，将拥有该权限的所有权限。"
          type="info"
          show-icon
        >
        </el-alert>
        <span>权限名称:</span>
        <span>{{ name }}</span>
        <!-- <p>选择用户</p>
        <el-input
          placeholder="请输入用户账号或名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
        <div style="height: 70vh;overflow-y: auto;">
        <el-table
          ref="multipleTable"
          :data="userData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          style="width: 20rem;"
        >
          <!-- <el-table-column type="selection" width=""></el-table-column> -->
          <el-table-column
            label="用户名称"
            width=""
            prop="username"
          ></el-table-column>
          <!-- <el-table-column prop="name" label="用户名称" width="">
          </el-table-column> -->
        </el-table>
        </div>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <!-- <div style="margin: 2vw">
          <el-button type="primary">提交</el-button>
          <el-button>取消</el-button>
        </div> -->
      </div>
    </el-dialog>
    <el-dialog
      title="权限权限管理"
      :visible.sync="dialogShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <el-alert
          title="用户加入到权限后，将拥有该权限的所有权限。"
          type="info"
          show-icon
        ></el-alert>
        <p>权限名称:</p>
        <p>{{ name }}</p>
        <p>选择用户</p>
        <el-input
          placeholder="请输入用户账号或名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input>
        <el-table
          ref="multipleTable"
          :data="userData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width=""></el-table-column>
          <el-table-column
            label="用户账号"
            width=""
            prop="zhanghao"
          ></el-table-column>
          <el-table-column prop="name" label="用户名称" width="">
          </el-table-column>
        </el-table>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary">提交</el-button>
          <el-button>取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import instance from '@/request/request';
export default {
  data() {
    return {
      loading:true,
      dialogShow: false,
      input: "",
      name: "",
      dialogVisible: false,
      input2: "",
      userData: [
      ],
      permissions: [],
      tableData: [
        {
          id: 1,
          name: "权限1",
          show: "权限说明1",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 2,
          show: "权限说明2",
          name: "权限2",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 3,
          show: "权限说明3",
          name: "权限3",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 4,
          show: "权限说明4",
          name: "权限4",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 5,
          show: "权限说明5",
          name: "权限5",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 6,
          show: "权限说明6",
          name: "权限6",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 7,
          show: "权限说明7",
          name: "权限7",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
      ],
      data: [
        {
          id: 1,
          name: "权限1",
          show: "权限说明1",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 2,
          show: "权限说明2",
          name: "权限2",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 3,
          show: "权限说明3",
          name: "权限3",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 4,
          show: "权限说明4",
          name: "权限4",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 5,
          show: "权限说明5",
          name: "权限5",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 6,
          show: "权限说明6",
          name: "权限6",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
        {
          id: 7,
          show: "权限说明7",
          name: "权限7",
          address: "上海市普陀区金沙江路 1518 弄",
          value: true,
        },
      ],
      multipleSelection: [],
    };
  },
  methods: {
    async getPermissions(){
       const res = await instance({
        url:'/ammeter/getPermissions',
       })
       this.loading = false
       this.tableData = res.data.content

       console.log(res.data.content);
    },
    onPermissions() {
      this.dialogShow = true;
    },
    async onRole(e,i) {
      console.log(e,66666666666);
      this.dialogVisible = true;
      this.name = e;
      console.log(this.name);
      const res = await instance({
        url:'/user/getUserListByPerId',
        params:{
          perId:i
        }
      })
      this.userData = res.data.content
    },
    Delete(e) {
      this.tableData = this.tableData.filter((item) => item.id != e);
    },
    allOpen() {
      this.multipleSelection.forEach((selectedItem) => {
        // 在 tableData 中找到对应的数据并取反 value 属性
        const foundItem = this.tableData.find(
          (item) => item.id === selectedItem.id
        );
        if (foundItem) {
          foundItem.value = true;
        }
      });
    },
    allSwitch() {
      this.multipleSelection.forEach((selectedItem) => {
        // 在 tableData 中找到对应的数据并取反 value 属性
        const foundItem = this.tableData.find(
          (item) => item.id === selectedItem.id
        );
        if (foundItem) {
          foundItem.value = false;
        }
      });
    },
    allDelete() {
      this.tableData = this.tableData.filter(
        (item) => !this.multipleSelection.includes(item)
      );
    },
    handleSuffixClick() {
      this.tableData = this.data;
      this.tableData = this.tableData.filter((x) =>
        x.name.includes(this.input2)
      );
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted(){
    this.getPermissions()

  }
};
</script>
<style lang="scss" scoped>
.big {
  background-color: #fff;
}
.el-table {
  margin-left: 2vw;
  width: 65%; /* 在这里设置宽度 */
}
// .el-table__header-wrapper {
//   display: flex;
//   justify-content: center;
// }

.el-table__body-wrapper {
  display: flex;
  justify-content: center;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
.footer {
  margin-top: 2vh;
  margin-left: 2vw;
}
::v-deep .el-dialog {
  position: fixed;
  right: 0;
  height: 100vh;
}
::v-deep .el-table th.el-table__cell>.cell {
  text-align: center;
}

::v-deep .el-table td.el-table__cell div {
  text-align: center;
}

.footer {
  margin-top: 2vh;
  margin-left: 2vw;
}

::v-deep .el-dialog {
  position: fixed;
  right: 0;
  height: 100vh;
}

::v-deep .el-button {
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

::v-deep .el-input-group>.el-input__inner {
  width: 12rem;
  font-size: 0.8rem;
}

::v-deep .el-input-group__append,
.el-input-group__prepend {

  border: 0.06rem solid #DCDFE6;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  width: 0.06rem;
  white-space: nowrap;
}

::v-deep .el-table {
  width: 60rem;
  font-size: 0.8rem;
  color: #606266;
}

::v-deep .el-checkbox__inner {
  border: 0.06rem solid #DCDFE6;
  border-radius: 0.12rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #FFF;
  z-index: 1;

}

// ::v-deep .el-button--danger {
//   width: 6rem;
//   font-size: 0.8rem;
// }

::v-deep .el-button {
  padding: 0.6rem 1rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

::v-deep .el-table .el-table__cell {
  padding: 0.7rem 0;
}
.table{
  ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:none !important;
}
}
</style>