<template>
    <div >
      <iframe src="https://db-gpt.hualaizhike.com" frameborder="0" style="width: 100%;height: 94vh;overflow-y: auto;"></iframe>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        inputText: "",
        outputText: "",
        out: "",
      };
    },
    methods: {
      handleEnter() {
        // 处理回车键按下的逻辑，例如触发搜索操作等
        console.log("Enter key pressed");
        // 这里可以添加处理回车键的具体逻辑
        console.log(this.inputText, "99999999");
        this.out = this.inputText;
        this.inputText = ""; //这里可能会出bug
      },
    },
    mounted() {},
    watch: {
      inputText(newValue) {
        // 在这里可以添加与输入文本相关的逻辑处理
        // 这里简单地将输入的文本赋值给显示框
        this.outputText = newValue;
      },
    },
  };
  </script>
  
<style scoped>

  </style>
  