// instance.js
import axios from 'axios';
// let loadingInstance = Loading.service(options);
import Cookie from "js-cookie";
const instance = axios.create({
    // baseURL: 'http://mqtt-1.hualaizhike.com:9999', // 设置基础URL
    // 120.224.50.45:8001/api
    // http://192.168.3.13:8000
    // https://mqtt.hualaizhike.com
    baseURL:'https://mqtt.hualaizhike.com',
    headers: {
        'Content-Type': 'application/json',
        hualai: Cookie.get("token01"),
        // 其他全局的请求头设置
    },
});

// 在请求发送前执行的拦截器
// instance.interceptors.request.use(
//     (config) => {
//         // 在请求头中添加身份验证 token
//         const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE3MDQ3Njg3NDIsImV4cCI6MTcwNDg1NTE0MiwiaWF0IjoxNzA0NzY4NzQyLCJ1c2VyIjp7ImlkIjoxLCJhY2NvdW50IjoiMTIzIiwibmFtZSI6IjEyMyIsInN0YXR1cyI6dHJ1ZSwicm9sZUlkIjoiMTIzIiwicGVybWlzc2lvbnMiOiIxMjMifX0.EpXf68FibEtt7oupm2G0amluVmt0ZkOloZ72ciQqImM';
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

instance.interceptors.request.use(
    (config) => {
        // console.log(Cookie.get("token01"),6666666666666);
        return config;
    },
    (error) => {
        // console.log(Cookie.get("token01"),77777777);

        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    async (response) => {
        // console.log(Cookie.get("token01"),8888888);

        return response;
        
    },
    (error) => {
        // console.log(Cookie.get("token01"),9999);

        return Promise.reject(error);
    }
);

export default instance;
