<template>
  <div class="home">
    <vue-particles  class="login-bg" color='#39AFFD' :particleOpacity="0.7" :particlesNumber="100" shapeType="circle"
      :particleSize="4" linesColor="#8DD1FE" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
      :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
    </vue-particles>
    <Register style="position: absolute;"/>
  </div>
</template>
<script>
import Register from "@/components/RegisterAside.vue";
export default{
  name:'RegisterView',
  data(){
    return{

    }
  },
  components:{
    Register,
  }
}

</script>

<style lang="less" scoped>
.home{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .login-bg {
    width: 100%;
    height: 100%;
    background: #3E3E3E;
}
}
</style>