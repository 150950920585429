<template>
  <div class="body" >
    <div
      style="
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: 50rem;
        margin-bottom: 3.1rem;
      "
    >
      <!--     
      <div class="block" style="margin-top: 50px">
        <span class="demonstration" style="color: aliceblue"
          >选择配电室或电表</span>
        <el-cascader
          :options="options"
          :props="props"
          clearable
          v-model="selectedItems"
          style="margin-left: 10px"
        ></el-cascader>
      </div> -->
      <!-- <div class="block" style="margin-left: 60px; margin-top: 50px">
        <span class="demonstration" style="color: aliceblue">选择日期</span>
        <el-date-picker v-model="value1" @change="onChange" type="daterange" range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期" style="margin-left: 10px">
        </el-date-picker>
      </div> -->
    </div>
    <div class="container">

        <div
          class="block"
          style="
            margin-top: 3.125rem;
            margin-bottom: 1.2rem;
            margin-right: 4rem;
            display: flex;
            justify-content: center;
          "
        >
          <span
            class="demonstration"
            style="color: aliceblue; font-size: 0.8rem;line-height: 40px;margin-right: 1rem;"
            >选择日期</span
          >
          <el-date-picker
            v-model="value1"
            @change="onChange"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style=""
          >
          </el-date-picker>
        </div>
        <div style="width: 100rem; display: flex; flex-direction: row">
          <div class="body01" id="body01"></div>
          <div class="body02" id="main02"></div>
        </div>

      <div class="right">
        <div
          style="
            margin-top: 0.5rem;
            width: 100%;
            margin-left: 1.875rem;
            /* margin-right: auto; */
          "
        ></div>
      </div>
      <DataTable :date="value1" ref="DataTable"></DataTable>
      <!-- <el-button @click="onBack" style="margin-top: 50px;" >点击返回</el-button> -->
    </div>
    <el-button @click="onBack" style="margin-top: 3.125rem; margin-left: 3rem"
      >点击返回</el-button
    >
    <!-- <div
      style="
        width: 80px;
        height: 80px;
        background-color: rgba(255, 255, 255, 0.7);
        position: fixed;
        bottom: 30%;
        right: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      "
      
    >
      <i
        class="el-icon-top"
        style="font-size: 26px; display: inline-block; margin-top: 10px"
      ></i>
      <p>返回顶部</p>
    </div> -->
  </div>
</template>
<script>
import * as echarts from "echarts";
import DataTable from "./components/DataTable.vue";
import instance from "@/request/request";
import formatDates from "@/util/index";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      loading: true,
      value1: ["2024-06-12", "2024-06-19"],
      context: null,
      focus: null,
      selectedRange: null,
      dataArray: [],
      props: { multiple: true },
      options: [
        {
          value: 1,
          label: "一号配电室",
          children: [
            {
              value: 1,
              label: "一号电表",
            },
            {
              value: 2,
              label: "二号电表",
            },
            {
              value: 3,
              label: "三号电表",
            },
          ],
        },
        {
          value: 2,
          label: "二号配电室",
          children: [
            {
              value: 1,
              label: "四号电表",
            },
            {
              value: 2,
              label: "五号电表",
            },
          ],
        },
      ],
      selectedItems: "",
      data1: [120, 200, 150, 80, 70, 110, 130],
      data2: [0, 46, 64, 40, 20, 60, 78],
      data3: [140, 232, 101, 264, 90, 340, 250],
      dateArray: [],
      totalArray: [],
      dataArray: [],
    };
  },
  watch: {
    value1(newValue, oldValue) {
      // 当 value1 发生变化时触发
      // 将新值传递给子组件
      // 可以直接调用子组件的方法，或者通过事件传递数据
      console.log(newValue,77777777777);
      this.value1 = formatDates(newValue);
      this.$refs.DataTable.$emit("date-change", this.value1);
      this.getData();
    },
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("body01");
      var myChart = echarts.init(chartDom);
      var option;
      //   var series = [
      //     {
      //       data: [120, 200, 150, 80, 70, 110, 130],
      //       type: "bar",
      //       stack: "a",
      //       stackType:'stack',
      //       name: "a",
      //       itemStyle: {
      //         // Gradient color for the first series (a)
      //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //           { offset: 0, color: "#87CEFA" }, // Start color
      //           { offset: 1, color: "#4682B4" }, // End color
      //         ]),
      //       },
      //     },
      //     {
      //       data: [30, 46, 64, "-", 0, "-", 0],
      //       type: "bar",
      //       stack: "a",
      //       stackType:'stack',

      //       name: "b",
      //       itemStyle: {
      //         // Gradient color for the second series (b)
      //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //           { offset: 0, color: "#165091" }, // Start color
      //           { offset: 1, color: "#3bc0db" }, // End color
      //         ]),
      //       },
      //     },
      //   ];
      //   const stackInfo = {};
      //   for (let i = 0; i < series[0].data.length; ++i) {
      //     for (let j = 0; j < series.length; ++j) {
      //       const stackName = series[j].stack;
      //       if (!stackName) {
      //         continue;
      //       }
      //       if (!stackInfo[stackName]) {
      //         stackInfo[stackName] = {
      //           stackStart: [],
      //           stackEnd: [],
      //         };
      //       }
      //       const info = stackInfo[stackName];
      //       const data = series[j].data[i];
      //       if (data && data !== "-") {
      //         if (info.stackStart[i] == null) {
      //           info.stackStart[i] = j;
      //         }
      //         info.stackEnd[i] = j;
      //       }
      //     }
      //   }
      //   for (let i = 0; i < series.length; ++i) {
      //     const data = series[i].data;
      //     const info = stackInfo[series[i].stack];
      //     for (let j = 0; j < series[i].data.length; ++j) {
      //       // const isStart = info.stackStart[j] === i;
      //       const isEnd = info.stackEnd[j] === i;
      //       const topBorder = isEnd ? 20 : 0;
      //       const bottomBorder = 0;
      //       data[j] = {
      //         value: data[j],
      //         itemStyle: {
      //           borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder],
      //         },
      //       };
      //     }
      //   }
      //   option = {
      //     xAxis: {
      //       type: "category",
      //       data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      //       axisLabel: {
      //         // Set x-axis label text color to white
      //         color: "white",
      //       },
      //       axisLine: {
      //         // Set x-axis line color to white
      //         lineStyle: {
      //           color: "white",
      //         },
      //       },
      //     },
      //     yAxis: {
      //       type: "value",
      //       axisLabel: {
      //         // Set x-axis label text color to white
      //         color: "white",
      //       },
      //       axisLine: {
      //         // Set x-axis line color to white
      //         lineStyle: {
      //           color: "white",
      //         },
      //       },
      //     },
      //     series: series,
      //   };
      option = {
        xAxis: {
          type: "category",
          data: this.dateArray,
          axisLabel: {
            color: "white",
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "white",
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        series: [
          {
            name: "总电量",
            type: "bar",
            data: this.totalArray,
            itemStyle: {
              // Gradient color for the first series (a)
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#165091" }, // Start color
                { offset: 1, color: "#3bc0db" },
              ]),
            },
          },
          {
            name: "配电室用电量",
            type: "bar",
            data: this.dataArray,
            itemStyle: {
              // Gradient color for the second series (b)
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#87CEFA" }, // Start color
                { offset: 1, color: "#4682B4" }, // End color
              ]),
            },
            barGap: "-100%", // 设置负值，使柱子之间重叠
          },
        ],
        legend: {
          data: ["总电量", "配电室用电量"],
          textStyle: {
            color: "white",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "4%",
          containLabel: true,
        },
      };
      option && myChart.setOption(option);
    },
    tu02() {
      var chartDom = document.getElementById("main02");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#80FFA5"],
        title: {
          //   text: "Gradient Stacked Area Chart",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["配电室用电量"],
          textStyle: {
            color: "white",
          },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.dateArray,
            axisLabel: {
              // Set x-axis label text color to white
              color: "white",
            },
            axisLine: {
              // Set x-axis line color to white
              lineStyle: {
                color: "white",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              // Set x-axis label text color to white
              color: "white",
            },
            axisLine: {
              // Set x-axis line color to white
              lineStyle: {
                color: "white",
              },
            },
          },
        ],
        series: [
          {
            name: "配电室用电量",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)",
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.dataArray,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    onBack() {
      this.$router.go(-1);
    },
    generateRandomArrays(length) {
      const arrays = [[], [], []];

      for (let i = 0; i < length; i++) {
        const randomNumber = Math.floor(Math.random() * (200 - 90 + 1)) + 90;
        arrays[0].push(randomNumber);

        const randomNumber2 = Math.floor(Math.random() * (200 - 90 + 1)) + 90;
        arrays[1].push(randomNumber2);

        const randomNumber3 = Math.floor(Math.random() * (200 - 90 + 1)) + 90;
        arrays[2].push(randomNumber3);
      }

      return arrays;
    },
    onChange() {
      // console.log(666);
      // const randomArrays = this.generateRandomArrays(10);
      // this.data1 = randomArrays[0];
      // this.data2 = randomArrays[1];
      // this.data3 = randomArrays[2];
      // this.tu();
      // this.tu02();
      // console.log("Array 1:", randomArrays[0]);
      // console.log("Array 2:", randomArrays[1]);
      // console.log("Array 3:", randomArrays[2]);
    },
    async getData() {
      const res = await instance({
        url: "/ammeter/getElectricData",
        params: {
          time: this.value1.join(";"),
          stationId: this.$route.query.stationId,
          type:1
        },
      });
      this.dateArray = res.data.content.totalData
        .map((item) => Object.keys(item)[0])
        .map((x) => x.substring(0, 10));

      // 提取值组成数组
      this.totalArray = res.data.content.totalData.map(
        (item) => Object.values(item)[0]
      );
      this.dataArray = res.data.content.data.map(
        (item) => Object.values(item)[0]
      );
      this.tu();
      this.tu02();
      // console.log(res.data.content);
      console.log(this.dateArray, this.totalArray);
    },
    async fetchData() {
      try {
        const response = await instance({
          url: "/ammeter/getPowerData",
          params: {
            stationId: this.$route.query.stationId,
          },
        });
        this.data = response.data.content;
        console.log(response.data.content);
        this.loading = false
        // this.data = response.data.filter((x) => !x.name.includes("閰嶇數绠�")); // 直接将后端返回的数据赋给组件的 data 属性
      } catch (error) {
        console.error("获取数据时发生错误:", error);
      }
    },
    getTodayAndSevenDaysAgo() {
      // 获取当天日期
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      // 获取七天前的日期
      let sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
      let sevenDaysAgo_dd = String(sevenDaysAgo.getDate()).padStart(2, "0");
      let sevenDaysAgo_mm = String(sevenDaysAgo.getMonth() + 1).padStart(
        2,
        "0"
      ); // January is 0!
      let sevenDaysAgo_yyyy = sevenDaysAgo.getFullYear();
      sevenDaysAgo =
        sevenDaysAgo_yyyy + "-" + sevenDaysAgo_mm + "-" + sevenDaysAgo_dd;

      // 组成数组
      return [sevenDaysAgo, today];
    },

    // 测试函数
  },
  mounted() {
    this.tu();
    this.tu02();
    // this.value1 = this.getTodayAndSevenDaysAgo();
    // console.log(this.value1);
    this.getData();
    // console.log(this.$route.query.stationId);

    // setTimeout(() => {
    //   this.loading = false;
    // }, 500);
  },
};
</script>
<style lang="scss" scoped>
.body {
  width: 100vw;
  height: 200vh !important;
  background-color: #1b2232;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.left {
  // margin-left: 120px;
  width: 50%;
}

.right {
  transform: translateY(40px);
}

.body01 {
  width: 45rem;
  height: 50vh;
  margin: 0 auto;
  border: 1px solid #2a3a4a;
  border-radius: 50px;
  margin-bottom: 3.125rem;
}

.body02 {
  width: 45rem !important;
  height: 50vh !important;
  margin: 0 auto;
  border: 1px solid #2a3a4a;
  border-radius: 50px;
  // padding-bottom: 1rem;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 13.7rem;
}

::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  margin-left: -0.3rem;
  line-height: 2rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}

::v-deep .el-button {
  padding: 0.7rem 1.2rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

::v-deep .ant-table-pagination.ant-pagination {
  float: center;
  margin: 1rem 0;
}
</style>