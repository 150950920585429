<template>
  <div>
    <!-- <input
        type="range"
        id="size-slider"
        min="50"
        max="200"
        value="100"
        @input="onInput"
      /> -->
    <div style="display: flex; justify-content: space-between">
      <p style="font-size: 16px; margin-left: 10px; padding-top: 10px">
        设备数据:
      </p>
      <div class="slider">
        <!-- 显示选择的数值容器 -->
        <span class="selected"> 50 </span>
        <!-- 滑动选择器 -->
        <input
          type="range"
          id="slider-input"
          min="0"
          max="100"
          value="50"
          @input="onInput"
        />
      </div>
    </div>
    <Table
      :row-class-name="rowClassName"
      :columns="columns1"
      :data="data1"
      id="table"
    ></Table>
    <!-- <p>Custom column styles:</p>
      <Table :columns="columns2" :data="data1"></Table>
      <p>Custom arbitrary cell styles:</p>
      <Table :columns="columns1" :data="data2"></Table> -->
  </div>
</template>
  <script>
export default {
  data() {
    return {
      columns1: [
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Age",
          key: "age",
        },
        {
          title: "Address",
          key: "address",
        },
      ],
      columns2: [
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Age",
          key: "age",
          className: "demo-table-info-column",
        },
        {
          title: "Address",
          key: "address",
        },
      ],
      data1: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
          date: "2016-10-03",
        },
        {
          name: "Jim Green",
          age: 24,
          address: "London No. 1 Lake Park",
          date: "2016-10-01",
        },
        {
          name: "Joe Black",
          age: 30,
          address: "Sydney No. 1 Lake Park",
          date: "2016-10-02",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          date: "2016-10-04",
        },
      ],
      data2: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jim Green",
          age: 25,
          address: "London No. 1 Lake Park",
          cellClassName: {
            age: "demo-table-info-cell-age",
            address: "demo-table-info-cell-address",
          },
        },
        {
          name: "Joe Black",
          age: 30,
          address: "Sydney No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
          cellClassName: {
            name: "demo-table-info-cell-name",
          },
        },
      ],
    };
  },
  methods: {
    rowClassName(row, index) {
      if (index === 1) {
        return "demo-table-info-row";
      } else if (index === 3) {
        return "demo-table-error-row";
      }
      return "";
    },
    onInput() {
      const sliderEl = document.querySelector("#slider-input");
      // 获取数值显示容器实例
      const selectedEl = document.querySelector(".selected");
      // 监听滑动事件
      selectedEl.innerHTML = sliderEl.value;
      // const sizeSlider = document.getElementById("size-slider");
      const resizableBox = document.querySelector("tr");
      const resizableBox2 = document.querySelectorAll("td");
      // console.log( typeof resizableBox);
      const newSize = sliderEl.value / 5 + 6 + "px";
      // console.log(newSize);
      resizableBox2.forEach((x) => (x.style.fontSize = newSize));
      //   console.log(newSize);
      resizableBox.style.fontSize = newSize;
      //   resizableBox2.style.fontSize = newSize;
    },
  },
  mounted() {
    const resizableBox = document.querySelector("tr");
    const resizableBox2 = document.querySelectorAll("td");
    resizableBox2.forEach((x) => (x.style.fontSize = "16px"));
    //   console.log(newSize);
    resizableBox.style.fontSize = "16px";
  },
};
</script>
  <style scoped lang="less">
@import "iview/dist/styles/iview.css";

.ivu-table .demo-table-info-row td {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-error-row td {
  /* background-color: #ff6600; */
  color: #fff;
}
.ivu-table td.demo-table-info-column {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-name {
  /* background-color: #2db7f5; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-age {
  /* background-color: #ff6600; */
  color: #fff;
}
.ivu-table .demo-table-info-cell-address {
  /* background-color: #187; */
  color: #fff;
}
/* #size-slider {
    width: 200px;
    margin-bottom: 20px;
    -webkit-appearance: none;
   
  
  
  } */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, "PingFang SC", "Microsoft Yahei", sans-serif;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1e272e;
}

/* 选择器容器 */
.slider {
  width: 200px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-right: 50px;
  /* margin-left: 50px; */
}

input[type="range"] {
  /* 禁用浏览器默认外观 */
  -webkit-appearance: none;
  background: linear-gradient(75deg, #24496f 0%, #0797eb 100%);
  border-radius: 4px;
  width: 100%;
  height: 12px;
  outline: none;
  box-shadow: 0 0 6px rgb(28, 32, 148);
}

/* 滑动选择器上的滑动按钮 */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333f4d;
  transition: 0.3s;
}

/* 当滑动选择器上的滑动按钮滑动时 */
input[type="range"]:active::-webkit-slider-thumb {
  background-color: #333f4d;
  /* box-shadow: 0 0 0 6px rgba(155, 73, 146, 0.4); */
}

/* 显示数值的容器 */
.selected {
  margin-right: 16px;
  background-color: #333f4d;
  width: 80px;
  line-height: 40px;
  text-align: center;
  color: white;
  border-radius: 4px;
  position: relative;
}
.selected::after {
  content: "";
  display: block;
  border-top: 8px solid transparent;
  border-left: 8px solid #333f4d;
  border-bottom: 8px solid transparent;
  position: absolute;
  top: calc(50% - 8px);
  right: -6px;
}
</style>