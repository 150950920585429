<template>
    <div class="home">
      <el-container>
          <el-main>
            <!-- 子路由出口 -->
            <router-view style="height: 100%;"></router-view>
          </el-main>
      </el-container>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { getTheme, setTheme } from '../../store/utils/theme'
  let Theme = parseInt(getTheme()) || 0
  let themeColor = [
    {
      url: require('../../assets/images/8063091afaf4b3ae33a28c36411f61b.png'),
    },
    {
      url: require('../../assets/images/0d3c88008f7f1cc3d6ece7859e41bef.png'),
    },
    {
      url: require('../../assets/images/432c6d7e2c1085c231fdc49ea02904d.jpg'),
    },
    {
      url: require('../../assets/images/039bf0f36e01b8454face05f2a1ab36.jpg'),
    },
    {
      url: require('../../assets/images/4be99c6583d7063b2524f3a056bde8a.jpg'),
    },
    {
      url: require('../../assets/images/700fab26c3c0a56e8a82ae4da2dc9cf.png'),
    },
    {
      url: require('../../assets/images/7036151f453547a502f32f13ee85f6a.jpg'),
    },
    {
      url: require('../../assets/images/f262cbfec56adc5842e64e6f19f6f31.jpg'),
    },
  ]
  export default {
    // name: 'Main',
    data() {
      return {
        themeIndex: Theme,   //当前配色方案
        themeColor: themeColor  //配色方案
      }
    },
    methods: {
      // 配色方案 子元素自定义事件
      targetThemeIndex(index) {
        this.themeIndex = index
        setTheme(index)
      },
    }
  }
  </script>
  <style lang="less" scoped>
  .home {
    width: 100%;
    padding:  0;
  }
  
  .el-container {
    height: 100%;
    width: 100%;
    background-size: 100% 100% !important;
  }
  
  .el-header {
    padding: 0;
    background: rgba(4, 10, 24, 0);
    border-bottom: .0625rem solid rgba(255, 255, 255, 0.300);
  }
  
  .el-main {
    // padding: 0px;
    padding-top: 1%;
    // background:url(../assets/images/0d3c88008f7f1cc3d6ece7859e41bef.png) no-repeat;
    // background-size: 100% 100%;
    background-color: rgba(0, 0, 0, 0.600);
    -ms-overflow-style: none; /* 隐藏IE和Edge的滚动条 */
    scrollbar-width: none; /* 隐藏Firefox的滚动条 */
    overflow: -moz-scrollbars-none; /* 隐藏Firefox的滚动条 */
  }
  .el-main::-webkit-scrollbar {
    display: none; /* 隐藏Chrome和Safari的滚动条 */
  }
  </style>