<template>
    
      <div class="ta01">
      
        
          <div class="ta02">
            <div id="container" ref="container"></div>
          </div>
       
        <!-- <a-tab-pane key="2" tab="今日" size="small">
          Content of Tab Pane 2
        </a-tab-pane>
        <a-tab-pane key="3" tab="本月" size="small">
          Content of Tab Pane 3
        </a-tab-pane>
        <a-tab-pane key="3" tab="自定义" size="small">
          Content of Tab Pane 3
        </a-tab-pane> -->
      <!-- </a-tabs> -->
      <!-- <p style="position: absolute; top: 5px; left: 15%">
        <span style="color: #fff">数据刷新时间：{{ refreshTime }}</span>
        &nbsp;<button
          @click="updateTime"
          style="background-color: #3c4c5c; color: #fff"
        >
          刷新
        </button>
      </p> -->
    </div>

  </template>
  <script>
  import G6 from "@antv/g6";
  
  let graph;
  
  const ERROR_COLOR = "#F5222D";
  const getNodeConfig = (node) => {
    if (node.nodeError) {
      return {
        basicColor: ERROR_COLOR,
        fontColor: "#FFF",
        borderColor: ERROR_COLOR,
        bgColor: "#E66A6C",
      };
    }
    let config = {
      basicColor: "#566097",
      fontColor: "white",
      borderColor: "#3c4c5c",
      bgColor: "#3c4c5c",
    };
    switch (node.type) {
      case "root": {
        config = {
          basicColor: "#E3E6E8",
          fontColor: "rgba(0,0,0,0.85)",
          borderColor: "#E3E6E8",
          bgColor: "#5b8ff9",
        };
        break;
      }
      default:
        break;
    }
    return config;
  };
  
  const COLLAPSE_ICON = function COLLAPSE_ICON(x, y, r) {
    return [
      ["M", x - r, y],
      ["a", r, r, 0, 1, 0, r * 2, 0],
      ["a", r, r, 0, 1, 0, -r * 2, 0],
      ["M", x - r + 4, y],
      ["L", x - r + 2 * r - 4, y],
    ];
  };
  const EXPAND_ICON = function EXPAND_ICON(x, y, r) {
    return [
      ["M", x - r, y],
      ["a", r, r, 0, 1, 0, r * 2, 0],
      ["a", r, r, 0, 1, 0, -r * 2, 0],
      ["M", x - r + 4, y],
      ["L", x - r + 2 * r - 4, y],
      ["M", x - r + r, y - r + 4],
      ["L", x, y + r - 4],
    ];
  };
  
  const nodeBasicMethod = {
    createNodeBox: (group, config, w, h, isRoot) => {
      /* 最外面的大矩形 */
      const container = group.addShape("rect", {
        attrs: {
          x: 0,
          y: 0,
          width: w,
          height: h,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "big-rect-shape",
      });
      if (!isRoot) {
        /* 左边的小圆点 */
        group.addShape("circle", {
          attrs: {
            x: 3,
            y: h / 2,
            r: 6,
            fill: config.basicColor,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "left-dot-shape",
        });
      }
      /* 矩形 */
      group.addShape("rect", {
        attrs: {
          x: 3,
          y: 0,
          width: w + 210,
          height: h,
          fill: config.bgColor,
          stroke: config.borderColor,
          radius: 2,
          cursor: "pointer",
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "rect-shape",
      });
  
      /* 左边的粗线 */
      group.addShape("rect", {
        attrs: {
          x: 3,
          y: 0,
          width: 3,
          height: h,
          fill: config.basicColor,
          radius: 1.5,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "left-border-shape",
      });
      return container;
    },
    /* 生成树上的 marker */
    createNodeMarker: (group, collapsed, x, y) => {
      group.addShape("circle", {
        attrs: {
          x,
          y,
          r: 13,
          fill: "rgba(47, 84, 235, 0.05)",
          opacity: 0,
          zIndex: -2,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "collapse-icon-bg",
      });
      group.addShape("marker", {
        attrs: {
          x,
          y,
          r: 7,
          symbol: collapsed ? EXPAND_ICON : COLLAPSE_ICON,
          stroke: "rgba(0,0,0,0.25)",
          fill: "rgba(0,0,0,0)",
          lineWidth: 1,
          cursor: "pointer",
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "collapse-icon",
      });
    },
    afterDraw: (cfg, group) => {
      /* 操作 marker 的背景色显示隐藏 */
      const icon = group.find(
        (element) => element.get("name") === "collapse-icon"
      );
      if (icon) {
        const bg = group.find(
          (element) => element.get("name") === "collapse-icon-bg"
        );
        icon.on("mouseenter", () => {
          bg.attr("opacity", 1);
          graph.get("canvas").draw();
        });
        icon.on("mouseleave", () => {
          bg.attr("opacity", 0);
          graph.get("canvas").draw();
        });
      }
      /* ip 显示 */
      const ipBox = group.find((element) => element.get("name") === "ip-box");
      if (ipBox) {
        /* ip 复制的几个元素 */
        const ipLine = group.find(
          (element) => element.get("name") === "ip-cp-line"
        );
        const ipBG = group.find((element) => element.get("name") === "ip-cp-bg");
        const ipIcon = group.find(
          (element) => element.get("name") === "ip-cp-icon"
        );
        const ipCPBox = group.find(
          (element) => element.get("name") === "ip-cp-box"
        );
  
        const onMouseEnter = () => {
          ipLine.attr("opacity", 1);
          ipBG.attr("opacity", 1);
          ipIcon.attr("opacity", 1);
          graph.get("canvas").draw();
        };
        const onMouseLeave = () => {
          ipLine.attr("opacity", 0);
          ipBG.attr("opacity", 0);
          ipIcon.attr("opacity", 0);
          graph.get("canvas").draw();
        };
        ipBox.on("mouseenter", () => {
          onMouseEnter();
        });
        ipBox.on("mouseleave", () => {
          onMouseLeave();
        });
        ipCPBox.on("mouseenter", () => {
          onMouseEnter();
        });
        ipCPBox.on("mouseleave", () => {
          onMouseLeave();
        });
        ipCPBox.on("click", () => {});
      }
    },
    setState: (name, value, item) => {
      const hasOpacityClass = [
        "ip-cp-line",
        "ip-cp-bg",
        "ip-cp-icon",
        "ip-cp-box",
        "ip-box",
        "collapse-icon-bg",
      ];
      const group = item.getContainer();
      const childrens = group.get("children");
      graph.setAutoPaint(false);
      if (name === "emptiness") {
        if (value) {
          childrens.forEach((shape) => {
            if (hasOpacityClass.indexOf(shape.get("name")) > -1) {
              return;
            }
            shape.attr("opacity", 0.4);
          });
        } else {
          childrens.forEach((shape) => {
            if (hasOpacityClass.indexOf(shape.get("name")) > -1) {
              return;
            }
            shape.attr("opacity", 1);
          });
        }
      }
      graph.setAutoPaint(true);
    },
  };
  
  G6.registerNode("card-node", {
    draw: (cfg, group) => {
      const config = getNodeConfig(cfg);
      const isRoot = cfg.dataType === "root";
      const nodeError = cfg.nodeError;
      /* the biggest rect */
      const container = nodeBasicMethod.createNodeBox(
        group,
        config,
        243,
        64,
        isRoot
      );
  
      if (cfg.dataType !== "root") {
        /* the type text */
        group.addShape("text", {
          attrs: {
            text: cfg.dataType,
            x: 3,
            y: -10,
            fontSize: 12,
            textAlign: "left",
            textBaseline: "middle",
            fill: "rgba(0,0,0,0.65)",
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "type-text-shape",
        });
      }
  
      if (cfg.ip) {
        /* ip start */
        /* ipBox */
        const ipRect = group.addShape("rect", {
          attrs: {
            fill: nodeError ? null : "#FFF",
            stroke: nodeError ? "rgba(255,255,255,0.65)" : null,
            radius: 2,
            cursor: "pointer",
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-container-shape",
        });
  
        /* ip */
        const ipText = group.addShape("text", {
          attrs: {
            text: cfg.ip,
            x: 10,
            y: 19,
            fontSize: 12,
            textAlign: "left",
            textBaseline: "middle",
            fill: nodeError ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,0.65)",
            cursor: "pointer",
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-text-shape",
        });
  
        const ipBBox = ipText.getBBox();
        /* the distance from the IP to the right is 12px */
        ipText.attr({
          x: 380 + 12 - ipBBox.width,
          fontSize: 15,
          fill: nodeError ? "rgba(255,255,255,0.85)" : "yellow",
        });
        /* ipBox */
        ipRect.attr({
          x: 380 + 12 - ipBBox.width - 4,
          y: ipBBox.minY - 5,
          width: ipBBox.width + 8,
          height: ipBBox.height + 10,
          fill: "#3c4c5c",
        });
  
        /* a transparent shape on the IP for click listener */
        group.addShape("rect", {
          attrs: {
            stroke: "",
            cursor: "pointer",
            x: 224 - 12 - ipBBox.width - 4,
            y: ipBBox.minY - 5,
            width: ipBBox.width + 8,
            height: ipBBox.height + 10,
            fill: "#fff",
            opacity: 0,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-box",
        });
  
        /* copyIpLine */
        group.addShape("rect", {
          attrs: {
            x: 194,
            y: 7,
            width: 1,
            height: 24,
            fill: "#E3E6E8",
            opacity: 0,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-cp-line",
        });
        /* copyIpBG */
        group.addShape("rect", {
          attrs: {
            x: 195,
            y: 8,
            width: 22,
            height: 22,
            fill: "#FFF",
            cursor: "pointer",
            opacity: 0,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-cp-bg",
        });
        /* copyIpIcon */
        group.addShape("image", {
          attrs: {
            x: 200,
            y: 13,
            height: 12,
            width: 10,
            img: "https://os.alipayobjects.com/rmsportal/DFhnQEhHyPjSGYW.png",
            cursor: "pointer",
            opacity: 0,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-cp-icon",
        });
        /* a transparent rect on the icon area for click listener */
        group.addShape("rect", {
          attrs: {
            x: 195,
            y: 8,
            width: 22,
            height: 22,
            fill: "#FFF",
            cursor: "pointer",
            opacity: 0,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "ip-cp-box",
          tooltip: "Copy the IP",
        });
  
        /* ip end */
      }
  
      /* name */
      group.addShape("text", {
        attrs: {
          text: cfg.name,
          x: 19,
          y: 19,
          fontSize: 14,
          fontWeight: 700,
          textAlign: "left",
          textBaseline: "middle",
          fill: config.fontColor,
          cursor: "pointer",
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "name-text-shape",
      });
  
      /* the description text */
      group.addShape("text", {
        attrs: {
          text: cfg.keyInfo,
          x: 19,
          y: 45,
          fontSize: 14,
          textAlign: "left",
          textBaseline: "middle",
          fill: config.fontColor,
          cursor: "pointer",
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: "bottom-text-shape",
      });
  
      if (nodeError) {
        group.addShape("text", {
          attrs: {
            x: 191,
            y: 62,
            text: "⚠️",
            fill: "#000",
            fontSize: 18,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: "error-text-shape",
        });
      }
  
      const hasChildren = cfg.children && cfg.children.length > 0;
      if (hasChildren) {
        nodeBasicMethod.createNodeMarker(group, cfg.collapsed, 236, 32);
      }
      return container;
    },
    afterDraw: nodeBasicMethod.afterDraw,
    setState: nodeBasicMethod.setState,
  });
  export default {
    data() {
      return {
        refreshTime: this.getCurrentTime(),
        data01: "2023-11-29 15:16:56",
        // G6 图表实例
        graph: null,
        // 图表数据
        data: {
          nodes: [
            {
              x: 150,
              y: 150,
              description: "ant_type_name_...",
              label: "Type / ReferType",
              color: "#2196f3",
              meta: {
                creatorName: "a_creator",
              },
              id: "test",
              type: "rect-xml",
            },
          ],
        },
      };
    },
    methods: {
      getCurrentTime() {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        const seconds = now.getSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      },
      updateTime() {
        this.refreshTime = this.getCurrentTime(); // 更新时间
      },
      callback(key) {
        console.log(key);
      },
      render() {
        const container = document.getElementById("container");
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;
        graph = new G6.Graph({
          container: "container",
          width,
          height,
          // translate the graph to align the canvas's center, support by v3.5.1
          fitCenter: true,
          modes: {
            default: ["drag-node"],
          },
          defaultNode: {
            type: "card-node",
          },
        });
  
        const data = {
          nodes: [
            {
              name: "6#NBQ",
              ip: "659991.60kwh",
              nodeError: false,
              id: "node4",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 250,
            },
            {
              name: "1并网柜",
              ip: "1423405.10kwh",
              id: "node1",
              nodeError: false,
              // keyInfo: "发电功率 " + "28.10kw",
              x: -1200,
              y: 50,
            },
            {
              name: "示范项目",
              ip: "190545.20kwh",
              nodeError: false,
              id: "node2",
              // keyInfo: "装机容量" + "300.00kwp",
              x: -1850,
              y: 150,
            },
            {
              name: "2#并网柜",
              ip: "763413.94kwh",
              nodeError: false,
              id: "node3",
              // keyInfo: "发电功率 " + "28.10kw",
              x: -1200,
              y: 250,
            },
  
            {
              name: "5#NBQ",
              ip: "198799.90kwh",
              nodeError: false,
              id: "node5",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 180,
            },
            {
              name: "7#NBQ",
              ip: "191463.12kwh",
              nodeError: false,
              id: "node6",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 320,
            },
            {
              name: "8#NBQ",
              ip: "190701.12kwh",
              nodeError: false,
              id: "node7",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 390,
            },
            {
              name: "1#NBQ",
              ip: "199078.23kwh",
              nodeError: false,
              id: "node8",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: -100,
            },
            {
              name: "2#NBQ",
              ip: "145234.20kwh",
              nodeError: false,
              id: "node9",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: -30,
            },
            {
              name: "3#NBQ",
              ip: "155279.30kwh",
              nodeError: false,
              id: "node10",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 40,
            },
            {
              name: "4#NBQ",
              ip: "190550.23kwh",
              nodeError: false,
              id: "node11",
              // keyInfo:
              //   "发电功率 " +
              //   "4.44kw" +
              //   "  组串电流离散率A  " +
              //   "22.80%" +
              //   "   装机容量  " +
              //   "37.50" +
              //   "kwp",
              x: -600,
              y: 110,
            },
          ],
          edges: [
            { source: "node1", target: "node2" },
            { source: "node2", target: "node3" },
            { source: "node1", target: "node8" },
            { source: "node1", target: "node9" },
            { source: "node1", target: "node11" },
            { source: "node1", target: "node10" },
            { source: "node3", target: "node7" },
            { source: "node3", target: "node4" },
            { source: "node3", target: "node5" },
            { source: "node3", target: "node6" },
          ],
        };
  
        graph.data(data);
        graph.render();
  
        if (typeof window !== "undefined")
          window.onresize = () => {
            if (!graph || graph.get("destroyed")) return;
            if (!container || !container.scrollWidth || !container.scrollHeight)
              return;
            graph.changeSize(container.scrollWidth, container.scrollHeight);
          };
      },
    },
    mounted() {
      // 当组件挂载后，渲染图表并监听窗口大小变化
      // this.renderGraph();
      this.render();
      this.getCurrentTime();
    },
  };
  </script>
  import G6 from '@antv/g6';
  <style lang="scss" scoped>
  .ta01 {
    text-align: none !important;
    background-color: #1b2232 !important;
  }
  .ta02 {
    background-color: #1b2232;
  }
  .ta02 .el-tabs ::v-deep .el-tabs__content {
    height: 830px;
    background-color: #1b2232;
  }
  .ta02 .el-tabs ::v-deep .el-tabs__nav-scroll {
    background-color: #1b2232;
  }
  #container {
    width: 100%;
    height: 830px;
  }
  .ta01 .a-tabs {
    background-color: #1b2232 !important;
  }
  </style>