<template>
  <div
    class="about"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="decoration"></div>
    <div
      id="top"
      class='top' 
    >
      <div class="top02">
        <div
          class='box01' 
          @click="onBtn(index)"
          v-for="(item, index) in data"
          :key="index"
        >
          <div>{{ item.line }}</div>
          <PhotoTable :data="item.data"></PhotoTable>
        </div>

      </div>

    </div>
  </div>
</template>

  <script>
import PhotoTable from "./components/zhexian01.vue";

import { Chart } from "@antv/g2";
import axios from "axios";
import instance from '@/request/request.js'
export default {
  components: {
    PhotoTable,
  },
  data() {
    return {
      loading: false,
      za: [],
      cgx: "",
      isTrue: false,
      data: [],
    };
  },

  methods: {
    moveLastBoxRight() {
      // 获取所有类名为 'box01' 的元素
      const boxes = document.querySelectorAll(".box01");
      // 获取最后一个类名为 'box01' 的元素
      const lastBox = boxes[boxes.length - 1];
      // 将其向右移动200px
      // lastBox.style.transform = "translateX(106%)";
      // 计算最后一个盒子的宽度
      const lastBoxWidth = lastBox.offsetWidth;
      // 计算容器的宽度
      const containerWidth = lastBox.parentElement.offsetWidth;
      // 计算需要移动的距离
      const translateX = (containerWidth - lastBoxWidth) / 2 - 70;

      // 将其设置到页面水平中间
      lastBox.style.transform = `translateX(${translateX}px)`;

      // 如果您希望具有动画效果，您可以添加过渡类，然后在下一个事件循环中添加类名
      // 示例：
      // setTimeout(() => {
      //   lastBox.classList.add('move-animation');
      // }, 0);
      // 这里 'move-animation' 是你定义的具有移动动画效果的 CSS 类
    },

    onBtn(index) {
      this.$router.push(`tongjishow?stationId=${this.data[index].stationId}`);
      // 在路由中传递查询参数
      // this.$router.push({ path: "/tongji/tongjishow", query: { stationId: this.data[index].stationId } });
    },
    // tubiao(za) {
    //   console.log(za, "kkkkkk");
    //   const chart = new Chart({
    //     container: "container",
    //     autoFit: true,
    //   });

    //   chart
    //     .area()
    //     .data({
    //       type: "fetch",
    //       value: za,
    //     })
    //     .encode("x", "name")
    //     .encode("y", "voltageA")
    //     .encode("color", "OperatingSystem")
    //     .encode("shape", "smooth")
    //     .transform({ type: "stackEnter", groupBy: "color", duration: 5000 })
    //     .transform({ type: "stackY", orderBy: "value" })
    //     .animate("enter", { type: "growInX" });

    //   chart.render();
    // },
    qie() {
      // var cgx = document.getElementsByClassName("top");
      // console.log(cgx.className, "111111111111111111111111");
      // if (cgx.className == "top") {
      //   cgx.className = "topp";
      // } else {
      //   cgx.className = "top";
      // }
      this.isTrue = !this.isTrue;
      console.log(this.isTrue);
    },
    async web01() {
      const res = await instance({
        url: "/ammeter/getEquipment",
      });
      // console.log(res.data[2], "eeeeeeeeeeeeee");
      if(!res.data.success){
        this.$message.error(res.data.message);
        // this.$router.push('/shouye/home')

      }else{
        this.data = res.data.content.filter((x) => x.pid == null);
      console.log(res.data.content.filter((x) => x.pid == null));
      }
      
      // this.za = res.data;
      // console.log(this.za, "nnnnnnnnnnn");
      // // this.tubiao(this.za);

      // console.log(this.za, "kkkkkk");
      // const chart = new Chart({
      //   container: "container",
      //   autoFit: true,
      // });

      // chart
      //   .area()
      //   .data(this.za)
      //   .encode("x", "name")
      //   .encode("y", "voltageA")
      //   .encode("color", "id")
      //   .encode("shape", "smooth")
      //   .transform({ type: "stackEnter", groupBy: "color", duration: 20000 })
      //   .transform({ type: "stackY", orderBy: this.za })
      //   .animate("enter", { type: "growInX" });
      // chart.render();
    },
  },
  mounted() {
    // this.tubiao();
    this.web01();
    this.qie();
      this.loading = false;

  },
};
</script>
  
  <style lang="scss" scoped>
.about {
  // background-image: url(../assets/cgx1123-01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.top {
  width: 100%;
  height: 105vh;
  background-color: #222e3c;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.topp {
  width: 1460px;
  height: 105vh;
  margin: 0 auto;
  background-color: #222e3c;
}
// .top01 {
//   width: 100%;
//   height: 40px;
//   background-color: pink;
//   color: #fff;

//   display: flex;
//   flex: 4;
//   justify-content: space-around;
// }
// .top01 > div {
//   padding-top: 10px;
// }
.top02 {
  width: 100%;
  height: 29rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // background-color: #fff;
}
.top02 > div {
  display: inline-block;
}
#container {
  height: 100%;
}
// .decoration {
//   position: absolute;
//   background-color: #66bffb;
//   width: 100%;
//   height: 8px;
//   animation: decorationAnimation 6s linear infinite alternate;
// }

// /* 动画效果 */
// @keyframes decorationAnimation {
//   to {
//     width: 50%;
//     transform: translateY(50%);
//     background-color: #f066c2;
//   }
// }
// @keyframes borderAnimation {
//   0% {
//     border-width: 1px;
//   }

//   50% {
//     border-width: 10px;
//     box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
//   }

//   100% {
//     border-width: 1px;
//     box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
//   }
// }
.box01 {
  border: 1px solid #3498db;
  // border-image: linear-gradient(90deg, #3498db, #e74c3c, #3498db);
  // border-image-slice: 1;

  width: 29.33%;
  margin: 20px;
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
  // animation: borderAnimation 5s ease-in-out infinite;
  border-radius: 15px !important;
  // 分割线++++++++++++++++++++++++++++++++++++++++++
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg);
  box-shadow: 0 20px 40px rgba(204, 200, 200, 0.3);
  background: linear-gradient(45deg, #0261a0, grey);
  border-radius: 20px;
  overflow: hidden;
}
.box01:hover {
  transform: rotateX(5deg) rotateY(5deg) scale(1.05);
  box-shadow: 0 30px 60px rgba(249, 244, 244, 0.5);
  transition: transform 0.5s, box-shadow 0.5s;
}
.box010 {
  border: 1px solid #ffffff;
  border-radius: 10px !important;
  width: 46.33%;
  height: 280px;
  color: #fff;
  margin: 5px;
  // animation: borderAnimation 5s ease-in-out infinite;
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
}
.box01 > div {
  text-align: center;
  color: #fff;
  margin-top: 10px;
  border-radius: 10px !important;
}
.top02 > div {
  border-radius: 30px !important;
}
.anniu {
  width: 150px;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 80px;
  right: 20px;
  padding: 10px;
  border-color: rgb(60, 255, 0);
}
.about {
  background-color: #222e3c;
}
</style>
