// stringParser.js

const stringParser = {
    stringToObject(input) {
      // 去除字符串中的花括号，并按逗号分割成数组
      var keyValuePairs = input.substring(1, input.length - 1).split(", ");
  
      // 创建一个空对象
      var obj = {};
  
      // 遍历键值对数组
      keyValuePairs.forEach(function(pair) {
        // 按等号分割键值对
        var parts = pair.split("=");
        // 移除引号并设置对象属性
        obj[parts[0]] = parts[1].replace(/'/g, "");
      });
  
      return obj;
    },
    parseStringToObjectArray(inputString) {
      // 定义正则表达式模式，匹配每个对象的字符串
      // var pattern = /\{.*?\}/g;
      var pattern = /\(.*?\)/g;
  
      // 使用正则表达式找到所有匹配项
      var matches = inputString.match(pattern);
  
      // 定义结果数组
      var resultArray = [];
  
      // 遍历匹配到的字符串数组
      for (var i = 0; i < matches.length; i++) {
        // 将字符串解析为对象
        var obj = this.stringToObject(matches[i]);
        // 将解析得到的对象添加到结果数组中
        resultArray.push(obj);
      }
  
      // 返回结果数组
      return resultArray;
    }
  };
  
  export default stringParser;
  