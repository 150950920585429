<template>
  <div
    class="big"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      style="
        height: 6vh;
        display: flex;
        align-items: center;
        margin-left: 2vw;
        width: 20vw;
      "
    >
      <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        type="primary"
        plain
        style="margin-right: 2vw"
        @click="onAdd"
        >添加角色</el-button
      >
      <!-- <el-input placeholder="请输入角色名称" v-model="input2">
        <template v-slot:append>
          <i class="el-icon-search" @click="handleSuffixClick"></i>
        </template>
      </el-input> -->
    </div>

      <div class="table">
        <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 88rem;"
      >
        <el-table-column type="selection" width=""></el-table-column>
        <el-table-column
          label="角色名称"
          width=""
          prop="name"
        ></el-table-column>
        <el-table-column prop="description" label="角色说明" width="">
        </el-table-column>
        <!-- <el-table-column label="角色状态" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.status ? "启用" : "禁用" }}:
          <el-switch style="margin-left: 1vw" v-model="scope.row.status" active-color="#409eff" inactive-color="gray"
            @change="onChange(scope.row.id, scope.row.status)">
          </el-switch></template>
      </el-table-column> -->
        <el-table-column label="操作" width="">
          <template v-slot="{ row }">
            <span
              style="margin-right: 2vw; color: #409eff; cursor: pointer"
              @click="onRole(row.id)"
              >角色成员</span
            >
            <span
              style="margin-right: 2vw; color: #409eff; cursor: pointer"
              @click="onPermissions(row.username, row.id, row.roleId)"
              >角色权限</span
            >
            <span
              style="color: #ff4949; cursor: pointer"
              @click="Delete(row.id)"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      </div>

    <div class="footer">
      <!-- <el-button type="primary" @click="allOpen">批量启用</el-button>
      <el-button type="warning" @click="allSwitch">批量禁用</el-button> -->
      <el-button type="danger" @click="allDelete">批量删除</el-button>
    </div>
    <el-dialog
      title="角色成员管理"
      :visible.sync="dialogVisible"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <el-alert
          title="用户加入到角色后，将拥有该角色的所有权限。"
          type="info"
          show-icon
        >
        </el-alert>
        <p>角色名称:</p>
        <p>{{ name }}</p>
        <!-- <p>选择用户</p> -->
        <!-- <el-input placeholder="请输入用户账号或名称" v-model="input" style="width: 23vw">
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
        <div style="height: 44rem;width: 100%;overflow-y: auto;">
          <el-table
          ref="multipleTable"
          :data="userData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          
        >
          <el-table-column type="selection" width=""></el-table-column>
          <el-table-column
            label="用户账号"
            width=""
            prop="username"
          ></el-table-column>
          <el-table-column prop="realName" label="用户名称" width="">
          </el-table-column>
        </el-table>
        </div>
        
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <!-- <div style="margin: 2vw">
          <el-button type="primary">提交</el-button>
          <el-button>取消</el-button>
        </div> -->
      </div>
    </el-dialog>
    <!-- <el-dialog title="用户权限管理" :visible.sync="dialogShow" top="0" width="25%" :right="0">
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <el-alert title="用户加入到用户后，将拥有该用户的所有权限。" type="info" show-icon></el-alert>
        <p>用户名称:</p>
        <p>{{ name }}</p>
        <p>选择用户</p>
        <el-input placeholder="请输入角色名称" v-model="input" style="width: 23vw">
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input>
        <el-table ref="multipleTable" :data="permissions" tooltip-effect="dark"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width=""></el-table-column>
          <el-table-column label="角色名称" width="" prop="name"></el-table-column>
          <el-table-column prop="show" label="角色说明" width="">
          </el-table-column>
        </el-table>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary">提交</el-button>
          <el-button>取消</el-button>
        </div>
      </div>
    </el-dialog> -->
    <el-dialog
      title="用户权限管理"
      :visible.sync="dialogShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <!-- <el-alert
          title="用户加入到用户后，将拥有该用户的所有权限。"
          type="info"
          show-icon
        ></el-alert> -->
        <p>用户名称:</p>
        <p>{{ name }}</p>
        <!-- <p>选择用户</p>
        <el-input
          placeholder="请输入角色名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
       <div style="height: 45rem;overflow-y: auto;">
        <el-table
          ref="permissionTable"
          :data="permissions"
          tooltip-effect="dark"
          @selection-change="handChange"
        >
          <el-table-column type="selection" width=""> </el-table-column>
          <el-table-column
            label="权限名称"
            width=""
            prop="name"
          ></el-table-column>
          <el-table-column prop="show" label="权限说明" width="">
          </el-table-column>
        </el-table>
       </div>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="editPermissions">提交</el-button>
          <el-button @click="dialogShow = false">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="用户角色权限管理"
      :visible.sync="dialogShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <!-- <el-alert
          title="用户加入到用户后，将拥有该用户的所有权限。"
          type="info"
          show-icon
        ></el-alert> -->
        <p>角色名称:</p>
        <p>{{ name }}</p>
        <!-- <p>选择用户</p>
        <el-input
          placeholder="请输入角色名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
       <div style="height: 70vh;overflow-y: auto;">
        <el-table
          ref="permissionTable"
          :data="permissions"
          tooltip-effect="dark"
          @selection-change="handChange"
        >
          <el-table-column type="selection" width=""> </el-table-column>
          <el-table-column
            label="权限名称"
            width=""
            prop="name"
          ></el-table-column>
          <el-table-column prop="show" label="权限说明" width="">
          </el-table-column>
        </el-table>
       </div>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="editPermissions">提交</el-button>
          <el-button @click="dialogShow = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="添加角色"
      :visible.sync="addShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <p>角色名称：</p>
        <el-input
          placeholder="请输入角色名称"
          v-model="input"
          style="width: 23vw"
        >
        </el-input>
        <p style="margin-top: 2vh">角色说明：</p>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入角色说明"
          v-model="textarea"
          style="width: 23vw"
        >
        </el-input>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="submit">提交</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import instance from "../../request/request.js";
export default {
  data() {
    return {
      rolePermissionDialogVisible: false,
      loading: true,
      fullscreenLoading: false,
      loading: true,
      textarea: "",
      addShow: false,
      dialogShow: false,
      input: "",
      name: "",
      dialogVisible: false,
      input2: "",
      userData: [
        {
          id: 666,
          zhanghao: 11111111,
          name: "张三",
        },
        {
          id: 66,
          zhanghao: 13333333,
          name: "李四",
        },
      ],
      permissionData: [],
      permissions: [{ selected: true }],
      tableData: [],
      data: [],
      multipleSelection: [],
    };
  },

  methods: {
    async Delete(e) {
      console.log("需要删除的用户ID:", e);
      try {
        const res = await instance({
          url: `/ammeter/deleteRole`,
          method: "DELETE",
          data: [e],
        });
        console.log("点击了删除:", res.data);
        setTimeout(() => {
          this.getData();
        }, 1000); // 1000 milliseconds = 1 second
      } catch (error) {
        console.error("错误提示:", error);
        // Handle error appropriately, e.g., show error message to user
      }
    },

    async editPermissions() {
      this.loading = true
      const res = await instance({
        method: "post",
        url: "/ammeter/setRolePermissions",
        data: {
          roleId: this.userId,
          perIds: this.permissionsId,
        },
      });
      this.dialogShow = false;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      console.log(res);
    },

    openFullScreen1() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
    async onChange(e, x) {
      const res = await axios({
        url: `http://mqtt-1.hualaizhike.com:9999/role/updateStatus`,
        method: "put",
        data: { ids: [e], status: x },
      });
      // console.log(res);
    },
    async submit() {
      const a = { name: this.input };
      const res = await instance({
        url: `/ammeter/saveRole`,
        method: "post",
        data: a,
      });
      this.getData();
      this.addShow = false;
      this.input = "";
      this.textarea = "";
    },
    async onAdd() {
      this.addShow = true;
    },

    async onPermissions(e, x, i) {
      this.dialogShow = true;
      this.name = e;
      this.getPermissions();
      const res = await instance({
        url: "/user/getPermissions",
        params: {
          roleId: i,
        },
      });
      this.userId = x;
      this.roleId = i;
      console.log(res.data.content, 66666666);
      this.permissions
        .filter((item1) =>
          res.data.content.some((item2) => item1.id === item2.id)
        )
        .forEach((row) => {
          this.$refs.permissionTable.toggleRowSelection(row);
        });
    },
    async getPermissions() {
      const res = await instance({
        url: "/ammeter/getPermissions",
      });
      this.loading = false;
      this.permissions = res.data.content.map((x) => {
        return { ...x, selected: true };
      });
      console.log(this.permissions);
    },
    async onRole(roleId) {
      try {
        const response = await instance({
          url: "/user/getUserListByRoleId",
          method: "get",
          params: {
            roleId: roleId,
          },
        });
        console.log("这是用户角色信息", response.data);
        console.log("response.data.content:", response.data.content);

        if (response.data.content !== null) {
          // 将content数组中的username和realName提取出来，并赋值给userData
          this.userData = response.data.content.map((item) => ({
            username: item.username,
            realName: item.realName,
          }));
        } else {
          this.userData = []; // 如果content为null，则将userData设置为空数组
        }

        this.name = response.data.name; // 将获取到的角色名称赋值给 name 变量
        this.dialogVisible = true; // 设置对话框可见
      } catch (error) {
        console.error("Error fetching role content:", error);
      }
    },
    handChange(val) {
      this.permissionsId = val.map((x) => x.id);
      console.log(this.permissionsId);
    },

    // async Delete(e) {
    //   const res = await axios({
    //     url: "http://mqtt-1.hualaizhike.com:9999/role/delete",
    //     method: "delete",
    //     data: { ids: [e] },
    //   });
    //   this.getData();
    // },
    // async allOpen() {
    //   const ids = this.multipleSelection.map((x) => x.id);
    //   // console.log(ids);
    //   const res = await axios({
    //     url: `http://mqtt-1.hualaizhike.com:9999/role/updateStatus`,
    //     method: "put",
    //     data: { ids, status: true },
    //   });
    //   this.getData();
    //   // this.multipleSelection.forEach((selectedItem) => {
    //   //   // 在 tableData 中找到对应的数据并取反 value 属性
    //   //   const foundItem = this.tableData.find(
    //   //     (item) => item.id === selectedItem.id
    //   //   );
    //   //   if (foundItem) {
    //   //     foundItem.status = true;
    //   //   }
    //   // });
    // },
    // async allSwitch() {
    //   const ids = this.multipleSelection.map((x) => x.id);
    //   // console.log(ids);
    //   const res = await axios({
    //     url: `http://mqtt-1.hualaizhike.com:9999/role/updateStatus`,
    //     method: "put",
    //     data: { ids, status: false },
    //   });
    //   this.getData();

    //   // this.multipleSelection.forEach((selectedItem) => {
    //   //   // 在 tableData 中找到对应的数据并取反 value 属性
    //   //   const foundItem = this.tableData.find(
    //   //     (item) => item.id === selectedItem.id
    //   //   );
    //   //   if (foundItem) {
    //   //     foundItem.status = false;
    //   //   }
    //   // });
    // },
    // async allDelete() {
    //   // this.tableData = this.tableData.filter(
    //   //   (item) => !this.multipleSelection.includes(item)
    //   // );
    //   const ids = this.multipleSelection.map((x) => x.id);
    //   // console.log(ids);
    //   const res = await axios({
    //     url: "http://mqtt-1.hualaizhike.com:9999/role/delete",
    //     method: "delete",
    //     data: { ids },
    //   });
    //   this.getData();
    // },
    async allDelete() {
      const selectedIds = this.multipleSelection.map((item) => item.id);
      console.log("选中的 IDs:", selectedIds);
      try {
        const res = await instance({
          url: "/ammeter/deleteRole",
          method: "DELETE",
          data: selectedIds, // 将选中的 IDs 作为数据发送
        });
        console.log("删除成功:", res.data);
        this.getData(); // 成功后重新获取数据
      } catch (error) {
        console.error("删除失败:", error);
        // 处理错误，例如显示错误消息给用户
      }
    },

    async handleSuffixClick() {
      const res = await axios(
        `http://mqtt-1.hualaizhike.com:9999/role/list?name=${this.input2}`
      );
      this.tableData = res.data.content;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    async getData() {
      const a = "";
      const res = await instance(`/ammeter/getRoleList`);
      this.tableData = res.data.content;
      console.log("这是获取到的角色列表", res);
    },
  },
  mounted() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.big {
  background-color: #fff;
}

.el-table {
  margin-left: 2vw;
  width: 65%;
  /* 在这里设置宽度 */
}

// .el-table__header-wrapper {
//   display: flex;
//   justify-content: center;
// }

.el-table__body-wrapper {
  display: flex;
  justify-content: center;
}

::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}

::v-deep .el-table td.el-table__cell div {
  text-align: center;
}

.footer {
  margin-top: 2vh;
  margin-left: 2vw;
}

::v-deep .el-dialog {
  position: fixed;
  right: 0;
  height: 100vh;
}


::v-deep .el-input-group > .el-input__inner {
  width: 12rem;
  font-size: 0.8rem;
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  border: 0.06rem solid #dcdfe6;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  width: 0.06rem;
  white-space: nowrap;
}

::v-deep .el-table {
  font-size: 0.8rem;
  color: #606266;
}

::v-deep .el-checkbox__inner {
  border: 0.06rem solid #dcdfe6;
  border-radius: 0.12rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fff;
  z-index: 1;
}



::v-deep .el-button {
  padding: 0.6rem 1rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

::v-deep .el-table .el-table__cell {
  padding: 0.7rem 0;
}
.table{
  margin-bottom:8rem ;
  ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:none !important;
}
}


</style>