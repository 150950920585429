<template>
  <div id="container1"></div>
</template>

<script>
import G6 from "@antv/g6";
import instance from "@/request/request";
/**
 * by Shiwu
 */

export default {
  data() {
    return {
      result: "",
    };
  },

  methods: {
    render() {
      const data = {
        nodes: this.transform,
        edges: this.transformedData,
      };

      data.edges.forEach((edge) => {
        edge.label = `${edge.size}kw`;
      });

      const colors = {
        "三相电表【2】厂房南 站号：1": "#51d058",
        "三相电表【3】航车 站号：2": "#F08BB4",
        "三相电表【4】厂房北 站号：3": "#65789B",
        "三项电表【5】电闸 站号：4": "skyblue",
        // triphasic6: "#51d058",
        // triphasic7: "#fedb01",
        // triphasic8: "#ff6c37",
        // triphasic9: "#ff370f",
        // triphasic10: "#171f3a",
        // triphasic11: "#8c58a2",
        // Single1: "#ffd76a",
        // Single2: "#ef1668",
        // Single3: "#7ace42",
        // Single4: "#439fd2",
      };

      data.nodes.forEach((node) => {
        node.donutColorMap = colors;
        node.size = 0;
        Object.keys(node.donutAttrs).forEach((key) => {
          node.size += node.donutAttrs[key];
        });
        node.size = Math.sqrt(node.size) * 5;
      });

      const legendData = {
        nodes: [
          {
            id: "triphasic2",
            label: "厂房南",
            order: 0,
            style: {
              fill: "#61DDAA",
            },
          },
          {
            id: "triphasic3",
            label: "航车",
            order: 0,
            style: {
              fill: "#F08BB4",
            },
          },
          {
            id: "triphasic4",
            label: "厂房北",
            order: 0,
            style: {
              fill: "#65789B",
            },
          },
          {
            id: "triphasic5",
            label: "电闸",
            order: 0,
            style: {
              fill: "skyblue",
            },
          },
          {
            id: "triphasic6",
            label: "激光切割机",
            order: 1,
            style: {
              fill: "#51d058",
            },
          },
          {
            id: "triphasic7",
            label: "水路系统",
            order: 1,
            style: {
              fill: "#fedb01",
            },
          },
          {
            id: "triphasic8",
            label: "折弯机",
            order: 1,
            style: {
              fill: "#ff6c37",
            },
          },
          {
            id: "Single1",
            label: "台钻、攻丝机",
            order: 1,
            style: {
              fill: "#ffd76a",
            },
          },
          {
            id: "triphasic9",
            label: "母线机",
            order: 1,
            style: {
              fill: "#ff370f",
            },
          },
          {
            id: "Single2",
            label: "锡锅",
            order: 1,
            style: {
              fill: "#ef1668",
            },
          },
          {
            id: "Single3",
            label: "打圈机",
            order: 1,
            style: {
              fill: "#7ace42",
            },
          },
          {
            id: "triphasic10",
            label: "焊机",
            order: 1,
            style: {
              fill: "#171f3a",
            },
          },
          {
            id: "triphasic11",
            label: "办公楼",
            order: 1,
            style: {
              fill: "#8c58a2",
            },
          },
          {
            id: "Single4",
            label: "厂房办公室",
            order: 1,
            style: {
              fill: "#439fd2",
            },
          },
        ],
      };

      const legend = new G6.Legend({
        // data: legendData,
        align: "left", // 将 align 属性设置为 "left"
        layout: "vertical", // 将 layout 属性设置为 "vertical"
        // align: "center",
        // layout: "horizontal", // vertical
        position: "center-left",
        vertiSep: 12,
        horiSep: 24,
        offsetX: 30,
        offsetY: -24,
        padding: [4, 16, 8, 16],
        // style: {
        //   title: {
        //     fontSize: 16, // 设置标题的字体大小
        //   },
        //   label: {
        //     fontSize: 14, // 设置图例项文字的字体大小
        //   },
        //   marker: {
        //     symbol: "circle", // 图例项的标记类型，可以根据需要调整
        //     radius: 8, // 图例项的标记大小
        //   },
        // },
        // itemStyle: {
        //   label: {
        //     fontSize: 14, // 设置图例项文字的字体大小
        //   },
        // },
        itemStyle: {
          label: {
            fontSize: 14,
            fill: "#fff", // 设置图例项文字的颜色为白色
          },
        },
        containerStyle: {
          fill: "rgba(204, 204, 204, 0.6)",
          lineWidth: 0,
        },
        title: " ",
        titleConfig: {
          offsetY: -8,
        },
      });

      // const width = document.getElementById("container1").scrollWidth;
      const height = document.getElementById("container1").scrollHeight;
      const graph = new G6.Graph({
        container: "container1",
        width: 1200,
        height: 750,
        // translate the graph to align the canvas's center, support by v3.5.1
        fitCenter: true,
        plugins: [legend],
        modes: {
          default: ["drag-canvas", "drag-node"],
        },
        layout: {
          type: "radial",
          focusNode: "li",
          linkDistance: 200,
          unitRadius: 200,
        },
        defaultEdge: {
          style: {
            endArrow: true,
          },
          labelCfg: {
            autoRotate: true,
            style: {
              stroke: "",
              lineWidth: 5,
            },
          },
        },
        defaultNode: {
          type: "donut",
          style: {
            lineWidth: 0,
          },
          labelCfg: {
            position: "bottom",
            style: {
              // fill: "#fff", // 将字体颜色设置为白色
            },
          },
        },
        // background: {
        //   fill: "#000",
        // },
      });

      graph.data(data);
      graph.render();

      // graph.on("afterpaint", () => {
      //   // console.log(123);
      //   const edges = graph.getEdges();
      //   console.log(edges);
      //   edges.forEach((edge) => {
      //     const label = edge.get("label");

      //     if (label) {
      //       label.attr("fill", "#fff");
      //     }
      //   });
      // });
      // graph.on("node:mouseenter", (evt) => {
      //   const { item } = evt;
      //   graph.setItemState(item, "active", true);
      // });

      // graph.on("node:mouseleave", (evt) => {
      //   const { item } = evt;
      //   graph.setItemState(item, "active", false);
      // });

      // graph.on("node:click", (evt) => {
      //   const { item } = evt;
      //   graph.setItemState(item, "selected", true);
      // });
      graph.on("canvas:click", (evt) => {
        graph.getNodes().forEach((node) => {
          graph.clearItemStates(node);
        });
      });
    },
    async getData() {
      const res = await instance({
        url: "/ammeter/getPowerData",
        params: {
          time: this.timeRange,
        },
      });
      const transformedData = [];
      res.data.content.forEach(function (item) {
        // 如果pid为null，表示是主进线后的数据
        if (item.pid === null) {
          transformedData.push({
            source: "主进线",
            target: item.name,
            size: parseFloat(item.dian) / 100,
          });
        } else {
          // 在原始数据中查找与当前数据的pid匹配的数据
          var parentItem = res.data.content.find(function (parent) {
            return parent.stationId === item.pid;
          });
          // 如果找到了匹配的数据，则确定source和target关系
          if (parentItem) {
            transformedData.push({
              source: parentItem.name,
              target: item.name,
              size: parseFloat(item.dian),
            });
          }
        }
      });
      this.transformedData = transformedData;

      // 输出转换后的数据
      // console.log(transformedData);

      // Create object with stationId as key and dian as value
      const result = {};
      transformedData
        .filter((item) => item.source == "主进线")
        .forEach((item) => {
          result[item.target] = item.size;
        });
      this.result = result;

      const transform = [];

      res.data.content.forEach((item) => {
        // 提取id和label
        const nameParts = item.name;
        const id = nameParts;
        const label = nameParts;

        // 构建子属性对象
        const donutAttrs = {};

        res.data.content.forEach((subItem) => {
          // 如果子属性的pid等于当前项的stationId，则为其子属性
          if (subItem.pid === item.stationId) {
            const subName = subItem.name;
            donutAttrs[subName] = parseFloat(subItem.dian);
          }
        });

        // 如果没有子属性，则生成一个只包含当前项dian的对象
        if (Object.keys(donutAttrs).length === 0) {
          donutAttrs[item.name] = parseFloat(item.dian);
          if (Object.keys(donutAttrs).length === 1) {
          // 如果 donutAttrs 长度为 1，则额外添加一条数据
          // 这里示例添加一个名称为 "extraData" 的数据，值为 0
          donutAttrs["extraData"] = 0;
        }
        }  

        // 构建新对象并添加到数组中
        transform.push({
          id,
          label,
          donutAttrs,
        });
      });
      const node = [
        {
          id: "主进线",
          label: "主进线",
          // the attributes for drawing donut
          donutAttrs: this.result,
        },
      ];
      this.transform = node.concat(transform);
      console.log(this.transform);
      this.render();

      // console.log(res.data.content);
    },
  },
  mounted() {
    var today = new Date();

    // 获取今天的年、月、日
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var day = today.getDate();
    day = day < 10 ? "0" + day : day;

    // 获取昨天的日期
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    var yYear = yesterday.getFullYear();
    var yMonth = yesterday.getMonth() + 1;
    yMonth = yMonth < 10 ? "0" + yMonth : yMonth;
    var yDay = yesterday.getDate();
    yDay = yDay < 10 ? "0" + yDay : yDay;

    // 将日期格式化成字符串，并构建时间范围字符串
    var yesterdayString = yYear + "-" + yMonth + "-" + yDay;
    var todayString = year + "-" + month + "-" + day;
    this.timeRange = yesterdayString + ";" + todayString;

    // this.render();
    this.getData();
  },
};
</script>

<style>
#container1 {
  width: 80vw;
  height: 85vh;
  margin: auto;
  border: 1px solid #ccc;
  background-color: rgba(224, 224, 224, 0.6);
  /* color: #fff; */
}
</style>
