<template>
  <div id="rip"></div>
</template>
<script>
import * as echarts from "echarts";
import instance from '@/request/request';
export default {
  name: "RightTable",
  data() {
    return {
    }
  },
  props:['value','dateData'],
  watch: {
    value: {
      handler() {
        this.tu();
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    tu() {
      var chartDom = document.getElementById("rip");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        backgroundColor: "#1c2431", //地图背景色
        color: ["#fcc550", "#4fd7fd"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          // data: ["本月", "上月"],
          textStyle: {
            fontSize: "12",
            color: "#fff",
          },
          top:10
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateData,
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: "12",
              color: "#fff",
            },
          },
        },
        // 因结构不同 vue版echarts请查看https://blog.csdn.net/qq_42221334/article/details/96315748
        series: [
          {
            name: "平均电流(A)",
            smooth: true,
            data: this.value,
            type: "line",
            areaStyle: {},
          },
        ],
      };
      option && myChart.setOption(option);
      // 监听窗口大小变化，调整图表大小
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
  },
  mounted() {
     this.tu()
  },
};
</script>
<style lang="scss" scoped>
#rip {
  width: 80rem;
  height: 370px;
}
</style>