<template>
  <div class="pei01" style="border-radius: 10px !important">
    <div
      ref="container"
      style="width: 100%; height: 300px; border-radius: 10px !important"
    ></div>
  </div>
</template>
<script>
// import { Chart } from "@antv/g2";
import TableLeft from "@/views/shezhi/components/TableLeft.vue";
import * as echarts from "echarts";
// import axios from "axios";
export default {
  // name: "PhotoTable",
  props:['data'],
  data() {
    return {
      tableData:[]
    };
  },
  methods: {
    tu() {
      var chartDom = this.$refs.container
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#80FFA5"],
        title: {
          text: "KWh",
          textStyle: {
            color: "#fff",
            fontSize: 12,
            fontWeight: 400,
          },
          top: "20",
          left: "8",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["每小时用电量"],
          top: "10",
          textStyle: {
            color: "#fff", // 设置图例文本颜色为白色
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#fff", // 修改x轴线的颜色为白色
              },
            },
            axisLabel: {
              color: "#fff", // Set x-axis label text color to white
            },
            boundaryGap: false,
            data: [
              "0点",
              "1点",
              "2点",
              "3点",
              "4点",
              "5点",
              "6点",
              "7点",
              "8点",
              "9点",
              "10点",
              "11点",
              "12点",
              "13点",
              "14点",
              "15点",
              "16点",
              "17点",
              "18点",
              "19点",
              "20点",
              "21点",
              "22点",
              "23点",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff", // Set y-axis label text color to white
              // formatter: "{value} kW",
            },
            axisLine: {
              lineStyle: {
                color: "#fff", // 修改x轴线的颜色为红色
              },
            },
          },
        ],
        series: [
          {
            animationDuration: 2500,
            name: "每小时用电量",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0,
              color: "#fff",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)",
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.tableData,
            animation: {
              duration: 50000, // Set the animation duration in milliseconds
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.tableData = this.data.map(item => Object.values(item)[0]);
    this.tu();
    console.log(this.data,6666666667);
  },
};
</script>
<style scoped>
.box01 > div {
  border-radius: 10px !important;
}
#container ::v-deep div{
  width: 100%;
}
.pei01 {
  border-radius: 10px !important;
  width: 100%;
}
#container::v-deep canvas {
  width: 100%!important;
  height: 295px !important;
}
#container::v-deep div{
  width: 100%!important;
}
</style>