<template>
  <div
    class="big"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      style="
        height: 6vh;
        display: flex;
        align-items: center;
        margin-left: 2vw;
        width: 20vw;
      "
    >
      <el-button type="primary" plain style="margin-right: 2vw" @click="onAdd"
        >添加用户</el-button
      >
      <el-input placeholder="请输入用户名称" v-model="input2">
        <template v-slot:append>
          <i class="el-icon-search" @click="handleSuffixClick"></i>
        </template>
      </el-input>
    </div>
    <div  class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          style="width: 86rem"
        >
          <el-table-column type="selection" width=""></el-table-column>
          <el-table-column
            label="用户名称"
            width=""
            prop="username"
          ></el-table-column>
          <el-table-column prop="registerTime" label="注册时间" width="">
          </el-table-column>
          <el-table-column prop="lastLoginIp" label="登录地址" width="">
          </el-table-column>
          <el-table-column prop="roleId" label="用户角色" width="">
          </el-table-column>
          <el-table-column prop="status" label="用户状态" show-overflow-tooltip>
            <template slot-scope="scope"
              >{{ scope.row.status ? "启用" : "禁用" }}:
              <el-switch
                style="margin-left: 1vw"
                v-model="scope.row.status"
                active-color="#409eff"
                inactive-color="gray"
                @change="onSwitch(scope.row.id, scope.row.status)"
              >
              </el-switch
            ></template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template v-slot="{ row }">
              <span
                style="margin-right: 2vw; color: #409eff; cursor: pointer"
                @click="onRole(row.username, row.id, row.roleId)"
                >修改角色</span
              >
              <span
                style="margin-right: 2vw; color: #409eff; cursor: pointer"
                @click="onPermissions(row.username, row.id, row.roleId)"
                >用户权限</span
              >
              <!-- <span
            style="margin-right: 2vw; color: #13ce66; cursor: pointer"
            @click="onPermissions(row.id)"
            >用户权限</span
          > -->
              <span
                style="color: #ff4949; cursor: pointer"
                @click="Delete(row.id)"
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    <div class="footer">
      <el-button type="primary" @click="allOpen">批量启用</el-button>
      <el-button type="warning" @click="allSwitch">批量禁用</el-button>
      <el-button type="danger" @click="allDelete">批量删除</el-button>
    </div>
    <el-dialog
      title="用户角色管理"
      :visible.sync="dialogVisible"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <el-alert
          title="用户加入到角色后，将拥有该角色的所有权限。"
          type="info"
          show-icon
        >
        </el-alert>
        <div style="height: 3rem; line-height: 3rem">
          <span>用户名称:</span>
          <h3 style="display: inline">{{ name }}</h3>
        </div>
        <p>选择用户</p>
        <!-- <el-input
          placeholder="请输入角色名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
        <el-table
          ref="table"
          :data="userData"
          tooltip-effect="dark"
          @selection-change="seChange"
        >
          <el-table-column type="selection" width=""></el-table-column>
          <el-table-column
            label="角色名称"
            width=""
            prop="name"
          ></el-table-column>
          <el-table-column prop="description" label="角色说明" width="">
          </el-table-column>
        </el-table>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="用户权限管理"
      :visible.sync="dialogShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <!-- <el-alert
          title="用户加入到用户后，将拥有该用户的所有权限。"
          type="info"
          show-icon
        ></el-alert> -->
        <p>用户名称:</p>
        <p>{{ name }}</p>
        <!-- <p>选择用户</p>
        <el-input
          placeholder="请输入角色名称"
          v-model="input"
          style="width: 23vw"
        >
          <template v-slot:append>
            <i class="el-icon-search" @click="handleSuffixClick"></i>
          </template>
        </el-input> -->
        <div style="height: 70vh;overflow-y: auto;">
        <el-table
          ref="permissionTable"
          :data="permissions"
          tooltip-effect="dark"
          @selection-change="handChange"
        >
          <el-table-column type="selection" width=""> </el-table-column>
          <el-table-column
            label="权限名称"
            width=""
            prop="name"
          ></el-table-column>
          <el-table-column prop="show" label="权限说明" width="">
          </el-table-column>
        </el-table>
        </div>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="editPermissions">提交</el-button>
          <el-button @click="dialogShow = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="添加用户"
      :visible.sync="addShow"
      top="0"
      width="25%"
      :right="0"
    >
      <hr style="width: 23vw; margin-bottom: 1vh" />
      <div>
        <!-- <p>用户名：</p>
        <el-input
          placeholder="请输入用户名"
          v-model="input"
          style="width: 23vw"
        >
        </el-input>
        <p>姓名：</p>
        <el-input
          placeholder="请输入姓名"
          v-model="input1"
          style="width: 23vw"
        >
        </el-input>
        <p style="margin-top: 2vh">密码：</p>
        <el-input
          placeholder="请输入密码"
          v-model="password"
          style="width: 23vw"
        >
        </el-input>
        <p style="margin-top: 2vh">确认密码：</p>
        <el-input
          placeholder="请确认密码"
          v-model="password1"
          style="width: 23vw"
        >
        </el-input> -->
        <el-form
          class="register-form"
          :model="form"
          :rules="rules"
          ref="registerForm"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username" style="width: 23vw"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="relaname">
            <el-input v-model="form.relaname" style="width: 23vw"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              v-model="form.password"
              style="width: 23vw"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </el-form-item> -->

          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input
              type="password"
              v-model="form.confirmPassword"
              style="width: 23vw"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="职务" prop="zhiwu">
                <el-input v-model="form.zhiwu"></el-input>
            </el-form-item> -->

          <!-- <el-form-item label="工作区域" prop="quyu">
                <el-input v-model="form.quyu"></el-input>
            </el-form-item> -->
        </el-form>
      </div>
      <div style="position: fixed; bottom: 0; right: 0">
        <hr style="width: 23vw; margin: 1vw" />
        <div style="margin: 2vw">
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="addShow = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import axios from "axios";
import instance from "../../request/request.js";
import { Page } from "iview";
import { size } from "@antv/util";
import { Notification } from "element-ui";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export default {
  data() {
    return {
      userId: "",
      permissionsId: [],
      password1: "",
      password: "",
      loading: true,
      dialogShow: false,
      input: "",
      name: "",
      dialogVisible: false,
      addShow: false,
      input1: "",
      input2: "",
      userData: [],
      permissions: [{ selected: true }],
      tableData: [],
      // data: [],
      multipleSelection: [],
      userId: null,
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        relaname: "",
        phone: "",
        zhiwu: "",
        quyu: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "用户名只能包含字母和数字",
            trigger: "blur",
          },
        ],
        relaname: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5]{2,4}$/,
            message: "请输入正确的真实姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^\d{11}$/, message: "手机号格式错误", trigger: "blur" },
        ],
        zhiwu: [
          { required: true, message: "请输入职务", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa50-9]+$/,
            message: "请输入正确的真实职务",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
            message: "密码必须包含字母和数字，并且长度不能少于六位数",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: this.validateConfirmPassword, trigger: "blur" },
        ],
      },
      ids: [],
      status: "",
      roleId: null,
      roleData: [],
    };
  },
  methods: {
    async editPermissions() {
      this.loading = true
      const res = await instance({
        method: "post",
        url: "/ammeter/setUserPermissions",
        data: {
          userId: this.userId,
          perIds: this.permissionsId,
        },
      });
      this.dialogShow = false;
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 1000);
      console.log(res);
    },
    handChange(val) {
      this.permissionsId = val.map((x) => x.id);
      console.log(this.permissionsId);
    },
    async getPermissions() {
      const res = await instance({
        url: "/ammeter/getPermissions",
      });
      // this.loading = false;
      this.permissions = res.data.content.map((x) => {
        return { ...x, selected: true };
      });
      console.log(this.permissions);
    },
    seChange(val) {
      this.roleId = val[0].id.toString();
      if (val.length > 1) {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleRowSelection(val.pop());
      }
    },
    async onEdit() {
      this.loading = true;
      const res = await instance({
        url: "/user/update",
        method: "post",
        data: {
          ids: this.ids,
          id: null,
          username: null,
          password: null,
          realName: null,
          roleId: this.roleId,
          status: this.status,
        },
      });
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 1000);
    },
    onSwitch(e, i) {
      console.log(i);
      this.loading = true
      const a = [];
      a.push(e);
      this.ids = a;
      if (i) {
        this.status = "1";
      } else if (!i) {
        this.status = "2";
      }
      this.onEdit();
      setTimeout(() => {
        this.getUserData();
        this.loading = false;
      }, 500);
    },
    submitForm() {
      this.loading = true
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          // 表单验证通过，可以在这里提交注册逻辑

          instance
            .post("/user/register", {
              username: this.form.username,
              password: this.form.confirmPassword,
              realName: this.form.relaname,
              // phone: this.form.phone,
              // zhiwu: this.form.zhiwu,
              // quyu: this.form.quyu
            })
            .then((response) => {
              if (response.data.success) {
                this.$message.success("添加成功");
                this.addShow = false;
                this.getUserData();
              } else {
                this.showNotification();
              }
            })
            .catch((error) => {
              console.log(666);
            });
        } else {
          this.showNotification();
          return false;
        }
      });
      this.loading = false
    },
    showNotification() {
      Notification({
        title: "提示",
        message: "提交信息失败",
        type: "error",
        duration: 3000, // 显示时间，单位为毫秒
        position: "top-right",
      });
    },
    toggleSelection(rows) {
      // console.log(6655);
      if (rows) {
        rows.forEach((row) => {
          this.$nextTick(() => {
            this.$refs.table.toggleRowSelection(row);
          });
        });
      } else {
        this.$nextTick(() => {
          this.$refs.table.clearSelection();
        });
        // console.log(667);
      }
    },
    onSubmit() {
      this.onEdit();
      this.dialogVisible = false;
      this.loading = true
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 1000);
    },
    async onAdd() {
      this.addShow = true;
    },
    async onPermissions(e, x, i) {
      this.dialogShow = true;
      this.name = e;
      this.getPermissions();
      const res = await instance({
        url: "/user/getPermissions",
        params: {
          userId: x,
        },
      });
      this.userId = x;
      this.roleId = i;
      console.log(res.data.content, 66666666);
      this.permissions
        .filter((item1) =>
          res.data.content.some((item2) => item1.id === item2.id)
        )
        .forEach((row) => {
          this.$refs.permissionTable.toggleRowSelection(row);
        });
    },
    async onRole(e, x, i) {
      this.dialogVisible = true;
      this.name = e;
      const a = [];
      a.push(x);
      this.ids = a;
    },
    async Delete(e) {
      this.loading = true
      const arr = [];
      arr.push(e);
      const res = await instance({
        url: `/user/deleteUser`,
        method: "DELETE",
        data: arr,
      });
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 1000);
    },
    allOpen() {
      this.loading = true
      this.ids = this.multipleSelection.map((x) => x.id);
      this.status = "1";
      this.onEdit();
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 500);
    },
    allSwitch() {
      this.loading = true
      this.ids = this.multipleSelection.map((x) => x.id);
      this.status = "2";
      this.onEdit();
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 500);
    },
    async allDelete() {
      this.loading = true
      const arr = this.multipleSelection.map((x) => x.id);
      const res = await instance({
        url: `/user/deleteUser`,
        method: "DELETE",
        data: arr,
      });
      setTimeout(() => {
        this.loading = false;
        this.getUserData();
      }, 500);
    },
    handleSuffixClick() {
      this.getUserData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getUserData() {
      // const a = "";
      const res = await instance({
        url: "/user/getUserList",
        params: {
          page: 1,
          size: 20,
          name: this.input2,
        },
      });
      const data = await instance({
        url: "/ammeter/getRoleList",
      });
      // console.log(data.data.content);
      this.userData = data.data.content;
      // console.log(this.userData,6666666666666);
      this.tableData = res.data.content.list;
      this.tableData.forEach((item) => {
        if (item.status === 1) {
          item.status = true;
        } else if (item.status === 2) {
          item.status = false;
        }
        item.registerTime = this.formatDates(item.registerTime);
      });
      // console.log(res.data.content.list);
      let roleMap = new Map();
      data.data.content.forEach((role) => {
        roleMap.set(role.id, role.name);
      });

      // Update roleId with role names from roles array
      this.tableData.forEach((user) => {
        if (roleMap.has(user.roleId)) {
          user.roleId = roleMap.get(user.roleId);
        }
      });
      this.loading = false;
      console.log(this.tableData);
    },
    formatDates(dateString) {
      // Parse the original date string
      let date = new Date(dateString);

      // Format the date components
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");

      // Construct the formatted date string
      let formattedString = `${year}-${month}-${day} ${hours}:${minutes}`;

      return formattedString;
    },
  },
  mounted() {
    this.getUserData();

    // setTimeout(() => {
    //   this.loading = false;
    // }, 500);
  },
};
</script>
  <style lang="scss" scoped>
.big {
  background-color: #fff;
}
.el-table {
  margin-left: 2vw;
  width: 65%; /* 在这里设置宽度 */
}
// .el-table__header-wrapper {
//   display: flex;
//   justify-content: center;
// }

.el-table__body-wrapper {
  display: flex;
  justify-content: center;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
.footer {
  margin-top: 2vh;
  margin-left: 2vw;
}
::v-deep .el-dialog {
  position: fixed;
  right: 0;
  height: 100vh;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}

::v-deep .el-table td.el-table__cell div {
  text-align: center;
}

.footer {
  margin-top: 2vh;
  margin-left: 2vw;
}

::v-deep .el-dialog {
  position: fixed;
  right: 0;
  height: 100vh;
}

// ::v-deep .el-button {
//   padding: 12px 20px;
//   font-size: 0.8rem;
//   border-radius: 0.2rem;
// }

::v-deep .el-input-group > .el-input__inner {
  width: 12rem;
  font-size: 0.8rem;
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  border: 0.06rem solid #dcdfe6;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  width: 0.06rem;
  white-space: nowrap;
}

::v-deep .el-table {
  width: 60rem;
  font-size: 0.8rem;
  color: #606266;
}

::v-deep .el-checkbox__inner {
  border: 0.06rem solid #dcdfe6;
  border-radius: 0.12rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fff;
  z-index: 1;
}

::v-deep .el-button--danger {
  padding: 0.6rem 1rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

::v-deep .el-button {
  padding: 0.6rem 1rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

::v-deep .el-table .el-table__cell {
  padding: 0.7rem 0;
}
.table{
  margin-bottom:8rem ;
  ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:none !important;
}
}
</style>